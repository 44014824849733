@use 'sass:map';

/*
 * For supported breakpoints, see config/breakpoint-config.scss
 * Usage:
 *
 * @include breakpoint(desktop) {
 *     .cmp-button {
 *         text-decoration: none;
 *     }
 * }
 *
 * .cmp-button {
 *     @include breakpoint(desktop) {
 *         text-decoration: none;
 *     }
 * }
 *
 * For custom breakpoints (exceptional cases), usage:
 *
 * @include breakpoint(min-width, 999px) {
 *     .cmp-button {
 *         text-decoration: none;
 *     }
 * }
 */
@mixin breakpoint($point, $value: 0) {
    @if $point == tablet-small {
        @media screen and (min-width: map.get($breakpoints, tablet-small)) {
            @content;
        }
    } @else if $point == tablet {
        @media screen and (min-width: map.get($breakpoints, tablet)) {
            @content;
        }
    } @else if $point == desktop {
        @media screen and (min-width: map.get($breakpoints, desktop)) {
            @content;
        }
    } @else if $point == desktop-large {
        @media screen and (min-width: map.get($breakpoints, desktop-large)) {
            @content;
        }
    } @else {
        @media screen and ($point: $value) {
            @content;
        }
    }
}
