@use 'sass:map';

.cmp-watch-and-learn-carousel {
    color: var(--color-neutral-200);
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-72);
    }

    @include breakpoint(desktop) {
        padding-bottom: map.get($spacing, s-96);
        padding-top: map.get($spacing, s-96);
    }

    .swiper-slide {
        align-self: stretch;
        height: unset;
        width: rem(270);

        @include breakpoint(tablet) {
            width: rem(275);
        }

        @include breakpoint(desktop) {
            width: rem(320);
        }
    }

    .carousel-card {
        &:hover {
            .carousel-card__visual {
                &::after {
                    transform: translate(-50%, -50%) scale(1.125);
                }
            }
        }

        &__visual {
            // Overlay for play icon here
            aspect-ratio: map.get($aspect-ratios, '3-4');
            margin-bottom: map.get($spacing, s-24);
            position: relative;

            @include breakpoint(tablet) {
                margin-bottom: map.get($spacing, s-16);
            }

            @include breakpoint(desktop) {
                margin-bottom: map.get($spacing, s-24);
            }

            &::after {
                background: var(--color-neutral-200);
                content: '';
                display: block;
                height: rem(144);
                left: 50%;
                mask-image: var(--svg-play-cutout);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                opacity: 0.6;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: transform 0.3s ease-in-out;
                vertical-align: middle;
                width: rem(144);
                z-index: 10;

                @media (forced-colors: active) {
                    color: ButtonText;
                    forced-color-adjust: none;
                }
            }
        }

        &__title {
            color: var(--color-neutral-200);
            font-size: rem(20);

            @include breakpoint(tablet) {
                font-size: rem(16);
            }

            @include breakpoint(desktop) {
                font-size: rem(20);
            }

            font-weight: map.get($font-weights, normal);
            line-height: 1.4;
        }
    }
}
