@use 'sass:map';

.cmp-rich-text {
    color: var(--color-neutral-500);
    margin-bottom: map.get($spacing, s-48);
    margin-top: map.get($spacing, s-48);

    @include font-size-per-breakpoint(body-l);
    @include table-styling;
    @include list-styled;

    @include breakpoint(tablet) {
        margin-bottom: map.get($spacing, s-72);
        margin-top: map.get($spacing, s-72);
    }

    @include breakpoint(desktop) {
        margin-bottom: map.get($spacing, s-96);
        margin-top: map.get($spacing, s-96);
    }

    &__content {
        max-width: var(--max-reading-width);
    }

    h2 {
        color: var(--color-warm-600);
        font-weight: map.get($font-weights, normal);
        margin-bottom: map.get($spacing, s-24);
        margin-top: map.get($spacing, s-72);

        @include font-size-per-breakpoint(h3);
    }

    h3 {
        color: var(--color-warm-600);
        font-weight: map.get($font-weights, normal);
        margin-bottom: map.get($spacing, s-16);
        margin-top: map.get($spacing, s-48);

        @include font-size-per-breakpoint(h4);
    }

    p {
        margin: 0 0 map.get($spacing, s-16);
    }

    .text-intro {
        color: var(--color-warm-600);

        @include font-size-per-breakpoint(body-xl);
    }

    .text-footnote {
        @include font-size-per-breakpoint(body-s);
    }

    & + .cmp-button-standalone,
    & + .cmp-duo-banner {
        margin-top: -#{map.get($spacing, s-48)};

        @include breakpoint(tablet) {
            margin-top: -#{map.get($spacing, s-72)};
        }

        @include breakpoint(desktop) {
            margin-top: -#{map.get($spacing, s-96)};
        }
    }
}

.cmp-button-standalone + .cmp-rich-text {
    margin-top: 0;
}
