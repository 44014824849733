@use 'sass:map';

.watch-and-learn-highlight {
    --accent-color: var(--color-cold-500);
    --active-pagination-color: var(--color-neutral-200);
    --ui-bg-color: var(--color-cold-600);
    --ui-color: var(--color-neutral-200);
    --visual-bg-color: var(--color-cold-100);
    --watch-and-learn-bg: var(--color-cold-600);
    --watch-and-learn-visual-aspect-ratio: #{map.get($aspect-ratios, '3-4')};
    --cta-bg: var(--color-cold-500);
    --cta-bg-hover: var(--color-cold-300);
    --cta-text: var(--color-neutral-200);
    --cta-text-hover: var(--cta-text);

    background-color: var(--watch-and-learn-bg);
    color: var(--color-neutral-200);
    counter-reset: watchAndLearnCounter;

    &--warm {
        --accent-color: var(--color-warm-500);
        --ui-bg-color: var(--color-warm-600);
        --watch-and-learn-bg: var(--color-warm-600);
        --visual-bg-color: var(--color-warm-100);
        --cta-bg: var(--color-warm-500);
        --cta-bg-hover: var(--color-warm-300);
        --cta-text: var(--color-neutral-200);
        --cta-text-hover: var(--cta-text);
    }

    .modal & {
        width: 100%;
    }
}

.cmp-watch-and-learn-highlight {
    // Overwrite generic swiper styles.
    .swiper {
        overflow: visible;

        &-wrapper {
            padding: 0;
        }
    }

    // Canvas places intro and carousel on top of each.
    &__canvas {
        display: grid;
        grid-template: 'container';
        place-items: center;
    }

    // Structural shared styles between intro and slides.
    &__intro,
    &__slideshow {
        background-color: var(--watch-and-learn-bg);
        flex-shrink: 1;
        grid-area: container;
        max-width: rem(400);
        min-width: 0;
        place-self: start center;
        position: relative;
        transition: opacity 0.5s ease-out;
        width: 100%;
        will-change: opacity;
        z-index: 2;

        @include breakpoint('min-width', '640px') {
            max-width: 100%;
        }
    }

    &__intro,
    &__slide {
        @include breakpoint('min-width', '640px') {
            display: grid;
            gap: 0;
            grid-template-columns: repeat(12, 1fr);
            padding: map.get($spacing, s-72) 0;
        }

        @include breakpoint(tablet) {
            gap: rem(24);
        }

        &-visual {
            background-color: var(--visual-bg-color);
            margin: 0 calc(#{map.get($spacing, s-24)} * -1);
            position: relative;

            @include breakpoint('min-width', '640px') {
                grid-column: 2 / 6;
                margin: 0;
            }

            @include breakpoint(tablet) {
                grid-column-end: 7;
            }

            .cmp-image {
                --aspect-ratio: var(--watch-and-learn-visual-aspect-ratio);

                display: block;
                margin: 0;
                width: 100%;
            }

            img {
                aspect-ratio: var(--watch-and-learn-visual-aspect-ratio);
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }

        &-title {
            @include font-size-per-breakpoint(h2);
        }

        &-content {
            @include breakpoint('min-width', '640px') {
                grid-column: 7 / 13;
            }

            @include breakpoint(tablet) {
                grid-column: 8 / 13;
            }

            @include breakpoint(desktop) {
                grid-column: 8 / 12;
            }
        }

        &-txt {
            margin-top: map.get($spacing, s-24);
        }

        &-cta {
            margin-top: map.get($spacing, s-32);
        }
    }

    // Intro block.
    &__intro {
        @include breakpoint('min-width', '640px') {
            align-items: center;
        }

        &-visual {
            &-cta {
                appearance: none;
                background-color: transparent;
                border: 1px solid transparent;
                cursor: pointer;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                transition: background-image 0.3s ease-in-out;
                width: 100%;
                will-change: background-image;

                &:hover {
                    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0%) 0%, rgba(0, 0, 0, 25%) 100%);

                    &::after {
                        transform: translate(-50%, -50%) scale(1.125);
                    }
                }

                &::after {
                    background: var(--color-neutral-200);
                    content: '';
                    display: block;
                    height: rem(64);
                    left: 50%;
                    mask-image: var(--svg-play);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: transform 0.3s ease-in-out;
                    width: rem(64);
                    z-index: 10;

                    @media (forced-colors: active) {
                        color: ButtonText;
                        forced-color-adjust: none;
                    }
                }
            }
        }

        &-cta {
            .cta-button {
                &::before {
                    background: var(--color-neutral-200);
                    content: '';
                    display: inline-block;
                    height: rem(24);
                    margin-right: map.get($spacing, s-8);
                    mask-image: var(--svg-play);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    vertical-align: top;
                    width: rem(24);
                }
            }
        }

        &-content {
            padding: map.get($spacing, s-48) 0 map.get($spacing, s-32);

            @include breakpoint('min-width', '640px') {
                align-self: center;
            }
        }

        .cta-button {
            --color-bg: var(--cta-bg);
            --color-bg-hover: var(--cta-bg-hover);
            --color-text: var(--color-cta-text);
            --color-text-hover: var(--color-cta-text);
        }
    }

    &__slideshow {
        display: none;
        place-self: start center;
    }

    // Watch and learn slide.
    &__slide {
        max-width: 100%;
        min-width: 100%;

        @include breakpoint('min-width', '640px') {
            height: max-content;
        }

        @include breakpoint(desktop) {
            grid-column-end: 12;
        }

        &-title {
            position: relative;

            &::before {
                color: var(--accent-color);
                content: '0' counter(watchAndLearnCounter);
                counter-increment: watchAndLearnCounter;
                display: block;
                font-size: rem(48);
                font-weight: map.get($font-weights, semibold);
                margin-bottom: map.get($spacing, s-32);

                @include breakpoint('min-width', '640px') {
                    font-size: rem(60);
                    margin-bottom: map.get($spacing, s-48);
                }
            }
        }

        &-content {
            padding: map.get($spacing, s-16) 0 map.get($spacing, s-32);

            @include breakpoint('min-width', '640px') {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: flex-end;
            }

            @include breakpoint(tablet) {
                padding: map.get($spacing, s-72) 0 0;
            }
        }

        &-txt {
            margin-top: map.get($spacing, s-24);
        }

        &-cta {
            margin-top: map.get($spacing, s-32);
        }

        &.swiper-slide-active {
            z-index: 1;
        }

        &-video {
            margin: map.get($spacing, s-72) calc(#{map.get($spacing, s-24)} * -1) 0;

            @include breakpoint('min-width', '640px') {
                grid-column: 2 / 12;
                margin: map.get($spacing, s-32) 0 0;
            }
        }
    }

    // Navigation
    // This div is needed to position the navigation in line with the other content.
    &__ui-holder {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 3;

        &::after {
            background-color: var(--color-warm-600);
            content: '';
            display: block;
            height: 100%;
            left: 0;
            margin: 0 calc(#{map.get($spacing, s-24)} * -1);
            opacity: 0.1;
            position: absolute;
            top: 0;
            width: calc(100% + (#{map.get($spacing, s-24)} * 2));
            z-index: -1;

            @include breakpoint(tablet) {
                background-color: transparent;
            }
        }

        @include breakpoint('min-width', '640px') {
            top: rem(16);
        }

        @include breakpoint(tablet) {
            display: grid;
            gap: rem(24);
            grid-template-columns: repeat(12, 1fr);
            top: rem(66);
        }
    }

    &__nav {
        align-items: center;
        display: flex;
        height: rem(75);
        justify-content: flex-end;

        @include breakpoint('min-width', '640px') {
            height: rem(48);
        }

        @include breakpoint(tablet) {
            grid-column: 8 / 13;
            justify-content: flex-end;
        }

        @include breakpoint(desktop) {
            grid-column-end: 13;
        }
    }

    &__pause-btn,
    &__close-btn,
    &__rewind-btn {
        appearance: none;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 50%;
        color: var(--ui-color);
        cursor: pointer;
        flex: 0 0 auto;
        position: relative;

        &::before {
            background-color: currentcolor;
            content: '';
            display: inline-block;
            left: 50%;
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: inherit;
            vertical-align: middle;

            @media (forced-colors: active) {
                color: ButtonText;
                forced-color-adjust: none;
            }
        }
    }

    &__pause-btn,
    &__rewind-btn {
        height: rem(32);
        width: rem(32);

        &::before {
            height: map.get($spacing, s-24);
            width: map.get($spacing, s-24);
        }
    }

    &__pause-btn {
        &::before {
            mask-image: var(--svg-pause);
        }
    }

    &__rewind-btn {
        display: none;

        &::before {
            mask-image: var(--svg-redo);
        }
    }

    &__close-btn {
        --color-focus-outline: var(--ui-color);

        background-color: var(--ui-bg-color);
        color: var(--accent-color);
        flex: 0 0 auto;
        height: rem(40);
        margin-left: map.get($spacing, s-16);
        width: rem(40);

        @include breakpoint('min-width', '640px') {
            background: rgba(0, 0, 0, 30%);
            height: rem(40);
            margin-left: map.get($spacing, s-24);
            width: rem(40);
        }

        @include breakpoint(tablet) {
            height: rem(48);
            width: rem(48);
        }

        @include breakpoint(desktop) {
            height: rem(90);
            width: rem(90);
        }

        @include focus {
            outline: 2px solid var(--color-focus-outline);
            outline-offset: 6px;
        }

        &::before {
            height: map.get($spacing, s-24);
            mask-image: var(--svg-close);
            width: map.get($spacing, s-24);
        }
    }

    &__pagination {
        display: flex;
        flex: 1 0 auto;
        gap: rem(8);
        justify-content: flex-end;
        margin: 0 map.get($spacing, s-16);

        @include breakpoint('min-width', '640px') {
            flex: 0 0 auto;
        }

        @include breakpoint(tablet) {
            flex: 1 0 auto;
        }
    }

    &__control {
        align-items: center;
        appearance: none;
        background: none;
        border: 1px solid transparent;
        cursor: pointer;
        display: none;
        padding: 0;

        @include breakpoint('min-width', '640px') {
            display: block;
        }

        &:hover {
            color: var(--color-cold-300);
        }

        @include focus {
            outline: 2px solid var(--color-cold-500);
            outline-offset: 6px;
        }

        &[aria-disabled='true'] {
            cursor: not-allowed;
            opacity: 0.5;
        }

        &__icon {
            color: var(--ui-color);
            display: block;
            position: relative;

            &::before {
                background-color: currentcolor;
                content: '';
                display: inline-block;
                height: rem(24);
                mask-image: var(--svg-arrow);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: relative;
                transition: inherit;
                vertical-align: middle;
                width: rem(24);

                @media (forced-colors: active) {
                    color: ButtonText;
                    forced-color-adjust: none;
                }
            }
        }

        &--prev {
            .cmp-watch-and-learn-highlight__control__icon {
                transform: rotate(180deg);
            }
        }
    }

    &__pagination-indicator {
        background-color: var(--accent-color);
        cursor: pointer;
        flex: 1 0 auto;
        height: 3px;
        min-width: rem(24);
        position: relative;
        z-index: 5;

        // Enlarge clickable area.
        &::before {
            content: '';
            height: rem(32);
            left: 0;
            position: absolute;
            transform: translateY(-44%);
            width: 100%;
        }

        &--active {
            background-color: var(--active-pagination-color);
            position: relative;

            & ~ .cmp-watch-and-learn-highlight__pagination-indicator {
                background-color: var(--active-pagination-color);
            }

            &::after {
                animation-direction: alternate;
                animation-duration: var(--watch-and-learn-slide-duration, 2s);
                animation-fill-mode: forwards;
                animation-iteration-count: 1;
                animation-name: countingBar;
                animation-play-state: running;
                animation-timing-function: ease-in;
                background: var(--accent-color);
                content: '';
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }

    &--video {
        .cmp-watch-and-learn-highlight {
            &__ui-holder {
                &::after {
                    display: none;
                }
            }

            &__close-btn {
                background-color: rgba(0, 0, 0, 30%);
            }
        }
    }
}

// STATES

.js-slide-show-started,
.js-video-started {
    .cmp-watch-and-learn-highlight__intro {
        display: none;
        opacity: 0;
        z-index: 1;
    }

    .cmp-watch-and-learn-highlight__slideshow {
        display: block;
        opacity: 1;
        z-index: 2;
    }
}

.is-paused {
    .cmp-watch-and-learn-highlight__pause-btn {
        &::before {
            mask-image: var(--svg-play);
        }
    }

    &.is-ended {
        .cmp-watch-and-learn-highlight__pause-btn {
            display: none;
        }

        .cmp-watch-and-learn-highlight__rewind-btn {
            display: block;
        }
    }

    .cmp-watch-and-learn-highlight__slide-visual {
        position: relative;

        &::after {
            background: var(--color-neutral-200);
            content: '';
            display: inline-block;
            height: rem(64);
            left: 50%;
            mask-image: var(--svg-pause);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            top: 50%;
            transform: translate(-50%);
            transition: inherit;
            vertical-align: middle;
            width: rem(64);
            z-index: 10;

            @media (forced-colors: active) {
                color: ButtonText;
                forced-color-adjust: none;
            }
        }

        &::before {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0%) 0%, #000 100%);
            bottom: 0;
            content: '';
            height: 100%;
            left: 0;
            opacity: 0.5;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;
        }
    }

    .cmp-watch-and-learn-highlight__ui-holder,
    & ~ .cmp-watch-and-learn-highlight__ui-holder {
        .cmp-watch-and-learn-highlight__pagination-indicator--active {
            &::after {
                animation-play-state: paused;
            }
        }
    }
}

@keyframes countingBar {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
