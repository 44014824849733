@use 'sass:map';

@mixin list-styled {
    ol,
    ul {
        --list-styled-item-margin: #{map.get($spacing, s-16)};
        --list-styled-margin: #{map.get($spacing, s-16)};
        --list-styled-padding: #{map.get($spacing, s-24)};

        list-style: initial;
        margin: var(--list-styled-margin) 0;
        padding: 0 0 0 var(--list-styled-padding);

        @include breakpoint(tablet) {
            --list-styled-item-margin: #{map.get($spacing, s-24)};
            --list-styled-margin: #{map.get($spacing, s-24)};
        }

        @include breakpoint(desktop) {
            --list-styled-item-margin: #{map.get($spacing, s-32)};
            --list-styled-margin: #{map.get($spacing, s-32)};
        }

        li {
            margin-bottom: var(--list-styled-item-margin);
        }
    }

    ol {
        --list-styled-padding: #{rem(28)};

        list-style-type: decimal;
    }
}

@mixin list-unstyled {
    ol,
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
