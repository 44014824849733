@use 'sass:map';

.cmp-content-carousel {
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-72);
    }

    @include breakpoint(desktop) {
        padding-bottom: map.get($spacing, s-96);
        padding-top: map.get($spacing, s-96);
    }

    @at-root :not([class*='background--color-']) > & {
        @include background-color(#{var(--color-neutral-300)});

        .carousel {
            --color-carousel-fade: var(--color-neutral-300);
            --color-carousel-focus-outline: var(--color-cold-600);
            --color-carousel-control-bg: var(--color-neutral-200);
            --color-carousel-control-bg-hover: var(--color-neutral-200);
            --color-carousel-control-focus-outline: var(--color-cold-600);
        }
    }

    .swiper-slide {
        align-self: stretch;
        height: unset;
        width: rem(270);

        @include breakpoint(tablet) {
            width: rem(275);
        }

        @include breakpoint(desktop) {
            width: rem(320);
        }
    }

    .carousel-card {
        &__visual {
            @include breakpoint(desktop) {
                margin-bottom: map.get($spacing, s-24);
            }
        }

        &__title {
            font-weight: map.get($font-weights, normal);
            margin-bottom: map.get($spacing, s-24);

            @include font-size-per-breakpoint(h5);
        }
    }
}
