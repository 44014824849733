@use 'sass:map';
@use 'sass:math';

.cookie-notice--modal {
    bottom: 0;
    left: 0;
    max-height: 100%;
    max-width: rem(500);
    outline: 0;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: map.get($spacing, s-32) map.get($spacing, s-24) map.get($spacing, s-48);
    position: fixed;
    right: 0;
    z-index: var(--zindex-modal);

    @include breakpoint(tablet) {
        bottom: map.get($spacing, s-24);
        left: auto;
        padding-left: map.get($spacing, s-32);
        padding-right: map.get($spacing, s-32);
        right: map.get($spacing, s-32);
    }

    @include breakpoint(desktop) {
        max-width: rem(670);
        padding: map.get($spacing, s-48);
        right: map.get($spacing, s-24);
    }

    .cookie-notice__item {
        @include list-styled;

        ol,
        ul {
            --list-styled-padding: #{map.get($spacing, s-16)};

            @include breakpoint(tablet) {
                --list-styled-padding: #{map.get($spacing, s-24)};
            }

            @include breakpoint(desktop) {
                --list-styled-padding: #{map.get($spacing, s-32)};
            }
        }
    }
}
