@use 'sass:map';

.dropdown {
    &,
    &-container {
        position: relative;
    }

    &__button {
        --color-text: var(--color-neutral-600);
        --color-text-hover: var(--color-cold-500);
        --color-icon: var(--color-text);
        --color-icon-hover: var(--color-text-hover);

        align-items: center;
        background-color: transparent;
        border: none;
        color: var(--color-text);
        display: inline-flex;
        font-size: 0;
        font-weight: map.get($font-weights, normal);
        line-height: 1;
        padding: map.get($spacing, s-12) map.get($spacing, s-16);
        position: relative;
        text-align: left;
        transition: color 0.15s ease-out, background-color 0.15s ease-out;
        vertical-align: top;

        &__text,
        &__arrow {
            display: inline-block;
            vertical-align: top;
        }

        &__text {
            @include font-size-per-breakpoint(body-m);
        }

        &::after {
            background-color: var(--color-icon);
            content: '';
            display: inline-block;
            height: rem(24);
            mask-image: var(--svg-chevron);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            transform: rotate(90deg);
            transition: transform 0.15s ease-out;
            vertical-align: top;
            width: rem(24);
        }

        &:hover,
        &:focus {
            color: var(--color-text-hover);

            &::before {
                background-color: var(--color-icon-hover);
            }
        }

        @include focus {
            border-radius: 2px;
            outline: solid 2px var(--color-neutral-200);
        }

        &[aria-expanded='false'] {
            + .dropdown__content {
                display: none;
            }

            &.hidden--from {
                &-tablet-small {
                    @include breakpoint(tablet-small) {
                        + .dropdown__content {
                            display: block;
                        }
                    }
                }

                &-tablet {
                    @include breakpoint(tablet) {
                        + .dropdown__content {
                            display: block;
                        }
                    }
                }

                &-desktop {
                    @include breakpoint(desktop) {
                        + .dropdown__content {
                            display: block;
                        }
                    }
                }
            }
        }

        &[aria-expanded='true'] {
            &::after {
                transform: rotate(-90deg);
            }

            + .dropdown__content {
                animation: fadeIn 0.15s linear;
            }
        }
    }
}
