@use 'sass:map';

a {
    --text-color: var(--color-link);
    --text-color-hover: var(--color-link-hover);
    --outline-color: var(--color-link-outline);

    color: var(--text-color);
    font-weight: map.get($font-weights, semibold);
    text-decoration: underline;
    transition: color 0.15s linear;

    &:hover {
        color: var(--text-color-hover);
        text-decoration: none;
    }

    @include focus {
        border-radius: 2px;
        outline: 2px solid var(--outline-color);
        text-decoration: none;
    }
}
