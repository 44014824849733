@mixin visually-hidden {
    clip: rect(1px, 1px, 1px, 1px);
    height: 0;
    overflow: hidden;
    position: absolute;
    width: 0;
    word-wrap: normal;
}

@mixin position-absolute(
    $width: 100%,
    $height: 100%,
    $xProperty: left,
    $xValue: 0,
    $yProperty: top,
    $yValue: 0
) {
    #{$xProperty}: $xValue;
    #{$yProperty}: $yValue;
    height: $height;
    position: absolute;
    width: $width;
}
