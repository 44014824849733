@use 'sass:map';

.share-sticky-container {
    position: relative;
}

.cmp-share {
    @include breakpoint(desktop) {
        position: absolute;
        right: calc(#{map.get($spacing, s-24)} * -1);
        top: 0;

        @supports (position: sticky) or (position: -webkit-sticky) {
            height: 100%;
        }
    }

    @include breakpoint(desktop-large) {
        right: calc(50% - (#{rem(1440)} / 2) + #{map.get($spacing, s-48)});
    }

    &__container {
        align-items: center;
        display: flex;
        flex-direction: row;
        padding-bottom: map.get($spacing, s-48);

        @include breakpoint(tablet) {
            padding-bottom: map.get($spacing, s-72);
        }

        @include breakpoint(desktop) {
            flex-direction: column;
            padding-bottom: 0;

            @supports (position: sticky) or (position: -webkit-sticky) {
                padding-bottom: map.get($spacing, s-16);
                position: sticky;
                top: calc(var(--main-navigation-height) + #{map.get($spacing, s-32)});
            }
        }
    }

    &__title {
        @include font-size-per-breakpoint(h6);

        font-weight: map.get($font-weights, semibold);
        margin-right: map.get($spacing, s-16);

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-8);
            margin-right: 0;
        }
    }

    &__icons {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: map.get($spacing, s-16);

        @include breakpoint(desktop) {
            flex-direction: column;
        }
    }

    &__icon {
        align-items: center;
        background-color: var(--color-neutral-300);
        border-radius: 50%;
        border-style: none;
        display: flex;
        justify-content: center;
        padding: map.get($spacing, s-12);

        &:hover {
            cursor: pointer;
        }

        &::before {
            background-color: var(--color-cold-500);
            content: '';
            display: inline-block;
            height: rem(24);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            vertical-align: middle;
            width: rem(24);
        }

        @include focus {
            outline: 2px solid var(--color-cold-600);
            outline-offset: 6px;
        }
    }

    .facebook {
        &::before {
            mask-image: var(--svg-facebook);
        }
    }

    .twitter {
        &::before {
            mask-image: var(--svg-twitter);
        }
    }

    .pinterest {
        &::before {
            mask-image: var(--svg-pinterest);
        }
    }

    .js-share-trigger {
        &::before {
            mask-image: var(--svg-external-link);
        }
    }
}
