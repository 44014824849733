@use 'sass:map';

.footer {
    @include background-color(var(--color-warm-600));

    padding-bottom: map.get($spacing, s-48);
    position: relative;

    // container-max on desktop only
    @include breakpoint(desktop) {
        --container-max-width: #{rem(1440)};
        --container-padding: #{map.get($spacing, s-48)};

        padding-bottom: map.get($spacing, s-32);
    }
}

.footer-block {
    &__trustpilot {
        width: 100%;
        margin-bottom: 30px;

        @include breakpoint(desktop) {
            margin-bottom: 0;
        }

        &:not(:has(.trustpilot-widget)) {
            display: none;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint(desktop) {
            flex-direction: row;
        }
    }

    &__social-links {
        @include breakpoint(desktop) {
            margin-left: auto;
        }
    }

    &__social-links-list {
        display: flex;
        flex-wrap: wrap;
    }

    &__social-link {
        display: inline-block;
        margin-right: map.get($spacing, s-16);

        &:last-child {
            margin-right: 0;
        }
    }
}

body:has(.cmp-standard-header__sticky-nav, .cmp-product-series-header__sticky-nav) footer {
	padding-bottom: calc(3rem + var(--sticky-nav-height));
	--sticky-nav-height: 76px;
	@include breakpoint(desktop) {
		--sticky-nav-height: 104px;
	}
}