@use 'sass:map';

.product-series-color-swatch {
    --product-series-color-swatch-label-size:#{rem(24)}; // used mobile version = 24px
    @include breakpoint(tablet) {
        --product-series-color-swatch-label-size: #{rem(32)};
    }

    border: none;
    padding: 0;

    legend {
        @include visually-hidden;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: map.get($spacing, s-16);

        @include breakpoint(desktop) {
            flex-direction: row;
        }
    }

    &__swatches {
        display: flex;
        gap: map.get($spacing, s-16);

        @include breakpoint(desktop) {
            margin-left: auto;
        }
    }
    &__swatchesNoLeft {
        display: flex;
        gap: map.get($spacing, s-16);
    }

    &__swatch-input {
        @include visually-hidden;

        opacity: 0;

        &:focus + label,
        &:checked + label {
            outline: 2px solid var(--color-swatch-indicator-color, var(--color-cold-500));
        }

        &:focus-visible + label,
        &:focus-within + label {
            outline: 4px solid var(--color-swatch-indicator-color, var(--color-cold-500));
        }
    }

    &__swatch-label {
        border: 1px solid var(--color-neutral-400);
        border-radius: 100%;
        display: block;
        height: var(--product-series-color-swatch-label-size);
        overflow: hidden;
        width: var(--product-series-color-swatch-label-size);

        @at-root label#{&} {
            cursor: pointer;
            outline: 2px solid transparent;
            outline-offset: 2px;
            transition: 0.3s outline-color linear;

            &:hover {
                outline: 2px solid var(--color-swatch-indicator-color, var(--color-cold-500));
            }
        }
    }

    &__indicator {
        color: var(--color-cold-600);
    }

    &--small {
        .product-series-color-swatch {
            --product-series-color-swatch-label-size: #{rem(24)};

            @include breakpoint(tablet) {
                --product-series-color-swatch-label-size: #{rem(32)};
            }
        }
    }
}
