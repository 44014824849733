@use 'sass:map';

.cmp-product-series-carousel {
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-72);
    }

    @include breakpoint(desktop) {
        padding-bottom: map.get($spacing, s-96);
        padding-top: map.get($spacing, s-96);
    }

    .swiper-slide {
        align-self: stretch;
        height: unset;
        width: rem(270);

        @include breakpoint(tablet) {
            width: rem(310);
        }

        @include breakpoint(desktop) {
            width: rem(430);
        }
    }

    .carousel-card {
        &__visual {
            @include breakpoint(desktop) {
                margin-bottom: map.get($spacing, s-32);
            }
        }

        &__title {
            & > div {
                display: inline-block;
            }
        }
    }

    &__asterisk {
        color: var(--color-carousel-control-text);
        font-weight: map.get($font-weights, light);
    }

    &__product-out-of-date {
        @include font-size-per-breakpoint(h3);
    }

    &__footnote-message {
        border-top: 2px solid var(--color-neutral-400);
        color: var(--color-neutral-500);
        margin-top: map.get($spacing, s-72);
        max-width: rem(545);
        padding-top: map.get($spacing, s-16);

        @include font-size-per-breakpoint(body-m);

        @include breakpoint(desktop) {
            border-width: 1px;
        }
    }
}
