@use 'sass:map';

.cookie-notice {
    $cookie-notice: &;

    padding: map.get($spacing, s-48) 0;

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-96);
    }

    @include breakpoint(desktop) {
        max-width: rem(773);
        padding-top: map.get($spacing, s-72);
    }

    &__toggle__input {
        height: 0;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        visibility: hidden;
        width: 0;

        & + label {
            appearance: none;
            background: transparent;
            border: 1px solid var(--color-neutral-200);
            border-radius: 9px;
            cursor: pointer;
            display: inline-block;
            height: rem(20);
            margin: map.get($spacing, s-4) 0;
            outline: none;
            position: relative;
            width: rem(40);

            @include breakpoint(tablet) {
                height: rem(18);
                width: rem(36);
            }

            &::after {
                background-color: transparent;
                border: 1px solid var(--color-neutral-200);
                border-radius: 50%;
                content: '';
                height: rem(20);
                left: -2px;
                position: absolute;
                top: -1px;
                transition: background-color 0.15s ease-in-out, left 0.15s ease-in-out, transform 0.15s ease-in-out;
                width: rem(20);

                @include breakpoint(tablet) {
                    height: rem(18);
                    width: rem(18);
                }
            }
        }

        &:checked + label::after {
            background-color: var(--color-neutral-200);
            left: calc(100% - 20px + 1px);
            transform: rotate(0deg);

            @include breakpoint(tablet) {
                left: calc(100% - 18px + 1px);
            }
        }
    }

    &__content {
        @include font-size-per-breakpoint(body-m);

        a {
            color: var(--color-cold-500);
            text-decoration: underline;
            white-space: nowrap;

            &::before {
                content: none;
            }
        }
    }

    &__title {
        &,
        & > p {
            line-height: map.get($spacing, s-32);
            margin-bottom: map.get($spacing, s-8);

            @include font-size-per-breakpoint(h4);
        }
    }

    &__subtitle {
        &,
        & > p {
            font-weight: map.get($font-weights, bold);
            line-height: map.get($spacing, s-24);
            margin-bottom: map.get($spacing, s-4);

            @include font-size-per-breakpoint(h5);
        }
    }

    &__description {
        margin-bottom: map.get($spacing, s-8);
    }

    &__settings {
        margin: map.get($spacing, s-24) 0;

        #{$cookie-notice}__toggle {
            margin-right: map.get($spacing, s-24);

            [dir='rtl'] & {
                margin-left: map.get($spacing, s-24);
                margin-right: 0;
            }
        }
    }

    &__learn-more-text {
        display: grid;
        gap: calc(var(--font-size) * var(--line-height));

        @include font-size-per-breakpoint(body-m);
        @include list-styled;

        ol,
        ul {
            --list-styled-padding: #{map.get($spacing, s-16)};

            @include breakpoint(tablet) {
                --list-styled-padding: #{map.get($spacing, s-24)};
            }

            @include breakpoint(desktop) {
                --list-styled-padding: #{map.get($spacing, s-32)};
            }
        }
    }

    &__item {
        margin-top: map.get($spacing, s-16);

        @include breakpoint(tablet) {
            margin-top: map.get($spacing, s-12);
        }

        @include breakpoint(desktop) {
            margin-top: map.get($spacing, s-24);
        }

        &__content {
            &--dropdown {
                @include breakpoint(tablet) {
                    display: none;
                }
            }

            &--inline {
                display: none;

                @include breakpoint(tablet) {
                    display: block;
                }
            }
        }

        &__description {
            display: list-item;
            list-style-type: disc;

            &__title {
                &::after {
                    content: ':';
                }
            }
        }
    }

    &__input {
        @include breakpoint(desktop) {
            padding-top: rem(3);
        }
    }

    &__controls {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        margin-top: map.get($spacing, s-16);
        text-align: center;

        @include font-size-per-breakpoint(body-m);

        @include breakpoint(tablet-small) {
            margin-top: map.get($spacing, s-24);
        }

        @include breakpoint(desktop) {
            margin-top: map.get($spacing, s-48);
        }

        .cta-button {
            width: 100%;
        }
    }

    .dropdown {
        &__button {
            cursor: pointer;
            padding: 0;
            text-transform: none;
            width: 100%;

            &::after {
                content: none;
            }

            &[aria-expanded='true'] {
                background-color: transparent;

                .dropdown__button__icon {
                    transform: none;
                }
            }

            &__text {
                display: list-item;
                font-weight: map.get($font-weights, normal);
                justify-content: space-between;
                list-style-position: outside;
                list-style-type: disc;
                word-break: break-word;
            }

            &__icon {
                right: 0;
                top: auto;

                .fa {
                    color: var(--color-cold-500);
                    font-weight: map.get($font-weights, semibold);
                    margin-left: map.get($spacing, s-12);

                    @include font-size-per-breakpoint(body-l);
                }
            }
        }

        &__content {
            padding: map.get($spacing, s-4) 0 map.get($spacing, s-12) 0;
        }
    }

    &--darkgrey {
        background-color: var(--color-cold-600);
        color: var(--color-neutral-200);

        .dropdown__button {
            color: var(--color-neutral-200);
        }

        a {
            --outline-color: var(--color-neutral-200);
        }

        .cta-button {
            --color-focus-outline: var(--color-neutral-200);
        }

        .has-button-link {
            color: var(--color-neutral-200);
        }
    }

    &--grey {
        background-color: var(--color-neutral-300);
        color: var(--color-neutral-600);

        #{$cookie-notice}__pagetitle {
            color: var(--color-neutral-700);
        }

        .dropdown__button {
            color: var(--color-neutral-600);
        }

        .has-button-link {
            color: var(--color-neutral-600);
        }
    }
}
