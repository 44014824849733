@use 'sass:map';

.card-list {
    // Default: MEDIUM tile
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: map.get($spacing, s-16);
    padding-top: map.get($spacing, s-16);
    gap: map.get($spacing, s-16);

    @include breakpoint(tablet-small) {
        gap: map.get($spacing, s-16);
        grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(tablet) {
        gap: map.get($spacing, s-24);
        padding-bottom: map.get($spacing, s-24);
        padding-top: map.get($spacing, s-24);
    }

    &__item {
        @include card-clickable-link-containment-block;
    }

    &__image {
        margin-bottom: map.get($spacing, s-16);

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-32);
        }
    }

    .cmp-image {
        --aspect-ratio: #{map.get($aspect-ratios, '16-9')};

        margin-bottom: 0;

        @include breakpoint(tablet-small) {
            --aspect-ratio: #{map.get($aspect-ratios, '1-1')};
        }
    }



    .cmp-title__text {
        @include font-size-per-breakpoint(h4);

        font-weight: map.get($font-weights, normal);
        margin-bottom: map.get($spacing, s-8);

        @include breakpoint(tablet-small) {
            margin-bottom: map.get($spacing, s-16);
        }

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-24);
        }
    }

    &__description {
        color: var(--color-neutral-500);
        //margin-bottom: map.get($spacing, s-8);

        @include font-size-per-breakpoint(body-m);
        
        @include breakpoint(tablet-small) {
            //margin-bottom: map.get($spacing, s-24);
        }
    }

    &__button-link {
        .cta-button {
            margin-left: -#{map.get($spacing, s-12)};

            @include card-clickable-link-pseudo-element;
            @include font-size-per-breakpoint(body-s);

            --font-size: #{rem(16)}; /* stylelint-disable-line order/order */
        }
    }

    &__title-link {
        .cmp-title__text {
            @include font-size-per-breakpoint(h3);

            --font-size: #{rem(16)};
        }

        .cta-button {
            --color-focus-outline: var(--color-cold-600);
            --color-text: var(--color-cold-600);
            --color-text-hover: var(--color-cold-500);
            --color-icon: var(--color-cold-500);

            color: var(--color-text);
            font-weight: map.get($font-weights, normal);
            text-decoration: none;

            @include card-clickable-link-pseudo-element(false);

            &::after {
                background-color: var(--color-icon);
                content: '';
                display: inline-block;
                height: var(--font-size);
                margin-left: map.get($spacing, s-12);
                mask-image: var(--svg-arrow);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                vertical-align: middle;
                width: var(--font-size);
            }

            &:hover {
                color: var(--color-text-hover);
            }

            @include focus {
                border-radius: 2px;
                outline: 2px solid var(--color-focus-outline);
                padding: map.get($spacing, s-12);
            }
        }
    }

    .row-divider{
        display: none;
    }

    // LARGE tiles
    &--rendition-large {
        gap: map.get($spacing, s-16);
        grid-template-columns: repeat(2, 1fr);

        @include breakpoint(tablet) {
            gap: map.get($spacing, s-24);
        }

        .cmp-image {
            --aspect-ratio: #{map.get($aspect-ratios, '3-4')};
        }
    }

    // SMALL Product List tiles
    //--rendition-small-product-list
    &--rendition-small {
        gap: map.get($spacing, s-16);
        grid-template-columns: repeat(2, 1fr);

        @include breakpoint(tablet-small) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include breakpoint(desktop) {
            gap: map.get($spacing, s-24);
            grid-template-columns: repeat(3, 1fr);
        }

        .cmp-image {
            --aspect-ratio: #{map.get($aspect-ratios, '3-4')};
        }

        .card-list {
            &__description {
                margin-bottom: map.get($spacing, s-6);
                margin-top: map.get($spacing, s-12);
                font-size: 1rem;
            }

            &__item {
                .cmp-title__text {
                    @include font-size-per-breakpoint(body-m);
                }
            }
        }
    }

    //SMALL Manual List tiles
    &--rendition-small-manual-list{
        gap: map.get($spacing, s-16);
        grid-template-columns: repeat(2, 1fr);

        @include breakpoint(tablet-small) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include breakpoint(desktop) {
            gap: map.get($spacing, s-24);
            grid-template-columns: repeat(3, 1fr);
        }

        .cmp-image {
            --aspect-ratio: #{map.get($aspect-ratios, '3-4')};
        }

        .card-list {
            &__item {
                display: flex;
                flex-direction: column;
                height: 100%;

                .card-list__image{
                    order: 3;
                }

                .card-list__description {
                    order: 2;
                    flex: 1;
                    padding-bottom: map.get($spacing, s-16);
                    font-size: 1rem;
                }

                .card-list__title{
                    order: 1;
                    .cmp-title__text{
                        font-size: 1rem;
                        @include breakpoint(tablet-small) {
                            font-size: 1.5rem;
                        }
                        @include breakpoint(desktop) {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }

        .row-divider{
            border-bottom: 1px solid lightgray;
            display: none;
            margin: map.get($spacing, s-32) 0;

            @include breakpoint(tablet-small) {
                display: block;
                // 1 means first column left, 4 means last column right if we have a grid of three columns
                grid-column: 1 / 4;
            }
        } 

    }

    // EXTRA SMALL tiles
    &--rendition-extra-small {
        gap: map.get($spacing, s-16);
        grid-template-columns: repeat(2, 1fr);

        @include breakpoint(tablet-small) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include breakpoint(desktop) {
            gap: map.get($spacing, s-24);
            grid-template-columns: repeat(4, 1fr);
        }

        .cmp-image {
            --aspect-ratio: #{map.get($aspect-ratios, '3-4')};
        }

        .card-list {
            &__description {
                margin-bottom: map.get($spacing, s-6);
                margin-top: map.get($spacing, s-12);
            }

            &__item {
                .cmp-title__text {
                    @include font-size-per-breakpoint(body-m);
                }
            }
        }
    }

    // Default link color
    .has-button-link .cta-button {
        --color-text: var(--color-cold-500);
        --color-text-hover: var(--color-cold-300);
    }

    &--link-color-warm {
        .has-button-link .cta-button {
            --color-text: var(--color-warm-500);
            --color-text-hover: var(--color-warm-300);
        }

        .card-list__title-link .cta-button {
            --color-icon: var(--color-warm-500);
            --color-text-hover: var(--color-icon);
        }
    }
}
