@use 'sass:map';

.cmp-tabs {
    --tab-border-color: var(--color-neutral-400);
    --tab-border-color-active: var(--color-cold-500);
    --tab-border-color-hover: var(--color-cold-300);
    --tab-swipe-icon-color: var(--color-cold-500);
    --tab-swipe-icon-color-hover: var(--color-cold-300);
    --tab-swipe-background-color: var(--color-neutral-200);

    &__tablist {
        flex-wrap: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth;
        scroll-snap-type: inline mandatory;
        scrollbar-width: none;
        white-space: nowrap;

        &::-webkit-scrollbar {
            display: none;
        }

        .cmp-title__text {
            @include font-size-per-breakpoint(h5);
        }
    }

    .tablist__container {
        position: relative;
    }

    .tablist__swipe {
        &__icon {
            display: flex;

            &::after {
                background-color: var(--tab-swipe-icon-color);
                content: '';
                display: inline-block;
                height: rem(16);
                mask-image: var(--svg-chevron);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                vertical-align: middle;
                width: rem(16);
            }
        }

        &--left,
        &--right {
            appearance: none;
            border: none;
            height: rem(40);
            inset: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: rem(56);

            &:hover {
                cursor: pointer;

                .tablist__swipe__icon::after {
                    background-color: var(--tab-swipe-icon-color-hover);
                }
            }

            @include focus {
                outline: 2px solid var(--color-cold-600);
                outline-offset: 6px;
            }
        }

        &--left {
            background: linear-gradient(to right, var(--tab-swipe-background-color) 50%, rgba(255, 255, 255, 0%));
            padding-left: 0;

            .tablist__swipe__icon {
                justify-content: flex-start;

                &::after {
                    transform: rotate(180deg);
                }
            }
        }

        &--right {
            background: linear-gradient(to left, var(--tab-swipe-background-color) 50%, rgba(255, 255, 255, 0%));
            margin-left: auto;
            padding-right: 0;

            .tablist__swipe__icon {
                justify-content: flex-end;
            }
        }
    }

    &__tab {
        border-bottom: 2px solid var(--tab-border-color);
        flex-grow: 1;
        padding: map.get($spacing, s-16);
        scroll-snap-align: start;
        text-align: center;

        @include font-size-per-breakpoint(body-m);

        @include breakpoint(desktop) {
            padding: map.get($spacing, s-24);
        }

        &:hover {
            border-color: var(--tab-border-color-hover);
        }

        @include focus {
            margin-bottom: 0;
            margin-top: rem(2);
            outline: 2px solid var(--color-cold-600);
        }

        &--active {
            &,
            &:hover {
                border-color: var(--tab-border-color-active);
            }
        }
    }
}
