@use 'sass:map';

.cmp-product-series-features {
    --flex-column-gap: #{map.get($spacing, s-16)};

    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet-small) {
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-72);
    }

    &__title {
        @include font-size-per-breakpoint(h2);

        margin-bottom: map.get($spacing, s-32);

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-72);
        }
    }

    &__list {
        @include breakpoint(tablet-small) {
            column-gap: var(--flex-column-gap);
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        @include breakpoint(desktop) {
            --flex-column-gap: #{map.get($spacing, s-24)};
        }
    }

    &__item {
        border-top: 2px solid var(--color-neutral-400);
        padding-bottom: map.get($spacing, s-16);
        padding-top: map.get($spacing, s-16);

        &:last-child {
            border-bottom: 2px solid var(--color-neutral-400);
        }

        @include breakpoint(tablet-small) {
            flex: calc(50% - var(--flex-column-gap));
            padding-bottom: map.get($spacing, s-32);

            &:nth-last-child(-n + 2) {
                border-bottom: 2px solid var(--color-neutral-400);
            }
        }

        @include breakpoint(desktop) {
            padding-bottom: map.get($spacing, s-48);
            padding-top: map.get($spacing, s-24);
        }
    }

    &__label {
        @include font-size-per-breakpoint(body-m);

        margin-bottom: map.get($spacing, s-16);
    }

    &__value {
        @include font-size-per-breakpoint(h3);

        color: var(--color-neutral-600);
        font-weight: map.get($font-weights, light);
    }

    &__title,
    &__label {
        color: var(--color-cold-600);
    }
}
