@use 'sass:map';

.cmp-usp {
    padding-bottom: map.get($spacing, s-72);
    padding-top: map.get($spacing, s-48);

    &__items {
        --flex-row-gap: #{map.get($spacing, s-24)};
        --flex-column-gap: #{map.get($spacing, s-16)};
        --flex-columns: 2;

        column-gap: var(--flex-column-gap);
        display: flex;
        flex-wrap: wrap;
        row-gap: var(--flex-row-gap);

        @include breakpoint(tablet) {
            --flex-columns: 4;

            justify-content: space-between;
            margin-left: calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
            padding-left: map.get($spacing, s-24);
            padding-right: map.get($spacing, s-24);
        }

        @include breakpoint(desktop) {
            --container-padding: #{map.get($spacing, s-72)};
            --flex-column-gap: #{map.get($spacing, s-72)};

            margin-left: auto;
            margin-right: auto;
            max-width: rem(1260);
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__item {
        width: calc((100% / var(--flex-columns)) - var(--flex-column-gap) + (var(--flex-column-gap) / var(--flex-columns))); /* stylelint-disable-line max-line-length */

        .cmp-teaser {
            display: flex;
            flex-direction: column-reverse;
            height: 100%;
            justify-content: flex-end;

            &__link {
                display: flex;
                flex-direction: column-reverse;
            }

            &__title {
                font-weight: map.get($font-weights, semibold);
                line-height: 1.5;
                margin-top: map.get($spacing, s-16);
                text-align: center;

                @include font-size-per-breakpoint(h5);

                @include breakpoint(tablet) {
                    font-weight: map.get($font-weights, normal);
                    line-height: 1.2;
                }
            }

            &__image {
                margin: 0 auto;
                max-width: rem(200);

                @include breakpoint(tablet) {
                    margin-left: map.get($spacing, s-24);
                    margin-right: map.get($spacing, s-24);
                    max-width: none;
                }
            }
        }
    }
}
