@use 'sass:map';

.carousel {
    --color-carousel-fade: var(--color-neutral-200);
    --color-carousel-focus-outline: var(--color-cold-600);
    --color-carousel-control-bg: var(--color-neutral-300);
    --color-carousel-control-text: var(--color-cold-500);
    --color-carousel-control-text-disabled: var(--color-cold-200);
    --color-carousel-control-text-hover: var(--color-cold-300);
    --color-carousel-text: var(--color-cold-600);
    --color-carousel-text-light: var(--color-neutral-500);

    &__header {
        @include breakpoint(tablet-small) {
            display: grid;
            gap: map.get($spacing, s-16);
            grid-template-columns: repeat(10, 1fr);
        }
    }

    &__intro {
        color: var(--color-carousel-text-light);

        @include breakpoint(tablet-small) {
            align-self: end;
            grid-column: 1 / span 8;
        }

        @include breakpoint(tablet) {
            grid-column: 1 / span 6;
        }

        @include breakpoint(desktop) {
            grid-column: 1 / span 5;
        }

        .cmp-title {
            color: var(--color-carousel-text);

            &__text {
                display: block;
                font-weight: map.get($font-weights, light);

                @include font-size-per-breakpoint(h2);
            }
        }

        .cmp-text {
            font-size: map.get($font-sizes, body-m-mobile);
            line-height: 1.4;
            margin-top: map.get($spacing, s-16);

            @include breakpoint(tablet) {
                font-size: map.get($font-sizes, body-m-desktop);
                margin-top: map.get($spacing, s-32);
            }
        }
    }

    &__controls {
        display: none;

        @include breakpoint(tablet) {
            align-self: end;
            display: flex;
            gap: rem(16);
            grid-column: 7 / span 4;
            justify-content: flex-end;
        }
    }

    // Next and previous styles
    &__control {
        align-items: center;
        background: var(--color-carousel-control-bg);
        border: 1px solid var(--color-carousel-control-bg);
        border-radius: 100%;
        color: var(--color-carousel-control-text);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 76px;
        justify-content: center;
        width: 76px;

        &:hover {
            color: var(--color-carousel-control-text-hover);
        }

        @include focus {
            outline: 2px solid var(--color-carousel-focus-outline);
            outline-offset: 6px;
        }

        &[aria-disabled='true'] {
            --color-carousel-control-bg-hover: var(--color-carousel-control-bg);
            --color-carousel-control-text: var(--color-carousel-control-text-disabled);
            --color-carousel-control-text-hover: var(--color-carousel-control-text);

            cursor: not-allowed;
        }

        &__icon {
            position: relative;

            &::before {
                background: currentcolor;
                content: '';
                display: inline-block;
                height: 26px;
                mask-image: var(--svg-arrow);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: relative;
                transition: inherit;
                vertical-align: middle;
                width: 26px;

                @media (forced-colors: active) {
                    color: ButtonText;
                    forced-color-adjust: none;
                }
            }
        }

        &--prev {
            .carousel__control__icon {
                transform: rotate(180deg);
            }
        }
    }

    &__wrapper {
        margin-top: map.get($spacing, s-24);
        overflow: hidden;
        padding: 0;
        position: relative;

        // Fix alignment on smaller screens
        &.container-max {
            --container-padding: 0;
        }

        @include breakpoint(tablet) {
            margin-top: map.get($spacing, s-24);
        }

        @include breakpoint(desktop) {
            margin-top: map.get($spacing, s-72);
        }

        @include breakpoint(desktop-large) {
            &::after,
            &::before {
                content: '';
                height: 100%;
                position: absolute;
                width: 30px;
                z-index: 5;
            }

            &::after {
                background: linear-gradient(to right, rgba(255, 255, 255, 0%), var(--color-carousel-fade));
                right: 0;
                top: 0;
            }

            &::before {
                background: linear-gradient(to left, rgba(255, 255, 255, 0%), var(--color-carousel-fade));
                left: 0;
                top: 0;
            }
        }
    }

    .swiper {
        overflow: visible;
    }

    // Make all slides that are not completely visible transparent
    // Used a very generic selector to avoid code duplication
    .swiper-initialized {
        .swiper-slide > *:not(.js-completely-visible > *) {
            animation-delay: 0.1s; // Delay to prevent safari flicker
            animation-duration: 0.3s;
            animation-fill-mode: forwards; // Fill mode to hold animation at the end
            animation-name: carouselItemFade;
            animation-timing-function: linear;
        }
    }
}

.carousel-card {
    appearance: none;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: left;
    text-decoration: none;
    width: 100%;

    @include focus {
        outline: 2px solid var(--color-carousel-focus-outline);
        outline-offset: 2px;
    }

    &__visual {
        aspect-ratio: map.get($aspect-ratios, '3-4');
        background: rgba(0, 0, 0, 10%);
        margin-bottom: map.get($spacing, s-16);
        width: 100%;

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-24);
        }

        @include breakpoint(desktop-large) {
            margin-bottom: map.get($spacing, s-32);
        }

        .cmp-image {
            --aspect-ratio: #{map.get($aspect-ratios, '3-4')};

            display: block;
            margin-bottom: 0;
            width: 100%;
        }
    }

    &__title {
        color: var(--color-carousel-text);
        margin-bottom: map.get($spacing, s-8);

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-24);
        }

        .cmp-title__text {
            font-size: rem(20);
            font-weight: var(--font-weight-normal);
            line-height: 1.2;

            @include breakpoint(desktop) {
                font-size: rem(36);
            }
        }
    }

    &__description {
        color: var(--color-carousel-text-light);

        @include font-size-per-breakpoint(h5);
    }

    &__cta {
        --button-padding: #{map.get($spacing, s-12)};
        --color-focus-outline: var(--color-cold-600);
        --color-text: var(--color-cold-500);
        --color-icon: var(--color-text);
        --color-icon-hover: var(--color-text-hover);
        --line-height: #{map.get($spacing, s-24)};

        border-radius: rem(30);
        border-style: none;
        color: var(--color-text);
        cursor: pointer;
        display: inline-block;
        font-size: rem(16);
        font-weight: map.get($font-weights, semibold);
        line-height: var(--line-height);
        margin-top: auto;
        padding: var(--button-padding) 0;
        text-decoration: none;

        &-icon {
            &::before {
                background-color: var(--color-icon);
                content: '';
                display: inline-block;
                height: rem(24);
                margin-right: rem(10);
                mask-image: var(--svg-arrow);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                vertical-align: top;
                width: rem(24);
            }
        }
    }

    &:hover {
        .carousel-card__cta {
            --color-text: var(--color-cold-300);
        }
    }
}

a.carousel-card,
button.carousel-card {
    cursor: pointer;
    font-weight: map.get($font-weights, normal);
}

@keyframes carouselItemFade {
    // Default non-selected style of nav item
    from {
        opacity: 1;
    }

    // Highlighted style of nav item
    to {
        opacity: 0.5;
    }
}

// ------------------------------------------
// Modifiers and style system
// ------------------------------------------
.carousel--style-warm {
    .carousel {
        --color-carousel-focus-outline: var(--color-warm-600);
        --color-carousel-control-text: var(--color-warm-500);
        --color-carousel-control-text-disabled: var(--color-warm-200);
        --color-carousel-control-text-hover: var(--color-warm-300);
    }

    .carousel-card {
        &__cta {
            --color-focus-outline: var(--color-warm-600);
            --color-text: var(--color-warm-500);
        }

        &:hover {
            .carousel-card__cta {
                --color-text: var(--color-warm-300);
            }
        }
    }
}

.background--color-warm {
    .carousel {
        --color-carousel-focus-outline: var(--color-neutral-200);
        --color-carousel-control-bg: rgba(0, 0, 0, 30%);
        --color-carousel-control-bg-hover: rgba(0, 0, 0, 30%);
        --color-carousel-control-text: var(--color-warm-500);
        --color-carousel-control-text-disabled: var(--color-warm-200);
        --color-carousel-control-text-hover: var(--color-warm-300);
        --color-carousel-fade: var(--color-warm-600);
        --color-carousel-text: var(--color-neutral-200);
        --color-carousel-text-light: var(--color-neutral-200);
    }
}

.background--color-cold {
    .carousel {
        --color-carousel-focus-outline: var(--color-neutral-200);
        --color-carousel-control-bg: rgba(0, 0, 0, 30%);
        --color-carousel-control-bg-hover: rgba(0, 0, 0, 30%);
        --color-carousel-control-text: var(--color-cold-500);
        --color-carousel-control-text-disabled: var(--color-cold-200);
        --color-carousel-control-text-hover: var(--color-cold-300);
        --color-carousel-fade: var(--color-cold-600);
        --color-carousel-text: var(--color-neutral-200);
        --color-carousel-text-light: var(--color-neutral-200);
    }
}

.background--color-white {
    .carousel {
        --color-carousel-focus-outline: var(--color-warm-600);
        --color-carousel-control-bg: var(--color-neutral-300);
        --color-carousel-control-bg-hover: var(--color-neutral-300);
        --color-carousel-fade: var(--color-neutral-200);
        --color-carousel-text: var(--color-warm-600);
    }
}

.background--color-grey {
    .carousel {
        --color-carousel-focus-outline: var(--color-cold-600);
        --color-carousel-control-bg: var(--color-neutral-200);
        --color-carousel-control-bg-hover: var(--color-neutral-200);
        --color-carousel-fade: var(--color-neutral-300);
        --color-carousel-text: var(--color-cold-600);
    }
}
