.hidden {
    display: none !important;
}

.invisible {
    visibility: hidden !important;
}

// Accessible for screen reader with element removed from document flow
.visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &--focusable:active,
    &--focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

// Accessible for screen reader but element keeps its space in document flow
.visually-transparent {
    opacity: 0 !important;
}
