@use 'sass:map';

.cmp-to-parent-link {
    --color-icon: var(--color-cold-500);

    &__link {
        color: var(--color-neutral-700);
        font-size: rem(20);
        font-weight: map.get($font-weights, normal);
        text-decoration: none;

        &::before {
            background-color: var(--color-icon);
            content: '';
            display: inline-block;
            height: rem(24);
            mask-image: var(--svg-arrow);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            transform: rotate(180deg);
            vertical-align: middle;
            width: rem(24);
        }

        @include focus {
            outline: 2px solid var(--color-cold-600);
            outline-offset: 6px;
        }
    }

    &__text {
        margin-left: map.get($spacing, s-12);
    }

    &--cold {
        --color-icon: var(--color-cold-500);
        --text-color-hover: var(--color-warm-500);
    }

    &--warm {
        .cmp-to-parent-link {
            &__link {
                --color-icon: var(--color-warm-500);
                --text-color-hover: var(--color-warm-500);
            }
        }
    }
}
