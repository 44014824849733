@use 'sass:map';

.cmp-product-series-list {
    display: grid;
    // gap: map.get($spacing, s-48);
    //grid-template-columns: 1fr;
    row-gap: map.get($spacing, s-48);

    @include breakpoint(tablet-small) {
        align-items: start;
        column-gap: map.get($spacing, s-16);
        //grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(desktop) {
        row-gap: 72px
    }
}

.product-series-card {
    display: flex; 
    flex-direction: column;
    position: relative;

    &__media {
        aspect-ratio: map.get($aspect-ratios, '3-4');
        position: relative;
        z-index: 3;

        &:hover {
            .product-series-card__visual--hasContextShot {
                .product-series-card__context-shot {
                    opacity: 1;
                }
            }
        }
    }
    &__bd{
        display: grid;
        grid-template-columns: 1fr; 
        grid-template-rows: auto auto auto auto; 
        gap: 0px 0px; 
        grid-template-areas: 
            "product-series-card__header"
            "product-series-card__description"
            "product-series-card__color-switch"
            "product-series-card__compare"; 

        @include breakpoint(desktop) {
            grid-template-columns: 1fr; 
            grid-template-rows: auto auto auto auto; 
            gap: 0px 0px; 
            grid-template-areas: 
                "product-series-card__header"
                "product-series-card__description"
                "product-series-card__color-switch"
                "product-series-card__compare"; 
        }
    }

    &__visual {
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.5s ease-in-out;
        width: 100%;
        z-index: 1;

        .image {
            height: 100%;
            width: 100%;
        }

        .cmp-image {
            --aspect-ratio: #{map.get($aspect-ratios, '3-4')};

            margin: 0;
        }

        &[data-active-variant] {
            opacity: 1;
            z-index: 2;
        }
    }

    &__product-shots,
    &__context-shot {
        aspect-ratio: map.get($aspect-ratios, '3-4');
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__context-shot {
        opacity: 0;
        transition: opacity 1s ease-in-out;
        z-index: 2;
    }

    &__header {
        grid-area: product-series-card__header;

        @include breakpoint(desktop) {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: map.get($spacing, s-32);
        }

        a {
            color: inherit;
            text-decoration: inherit;
        }
    }

    &__title {
        @include font-size-per-breakpoint(h4);

        font-weight: map.get($font-weights, normal);
    }

    &__color-switch {
        grid-area: product-series-card__color-switch;
        flex: 0 0 auto;
        margin-top: map.get($spacing, s-8);
        position: relative;
        z-index: 2;

        @include breakpoint(tablet-small) {
            margin-top: map.get($spacing, s-16);
        }
        @include breakpoint(desktop) {
            margin-top:  map.get($spacing, s-16);
        }
    }

    &__description {
        grid-area: product-series-card__description;
        color: var(--color-neutral-500);
        margin-top: map.get($spacing, s-8);

        @include font-size-per-breakpoint(body-m);

        @include breakpoint(tablet-small) {
            margin-top: map.get($spacing, s-16);
        }

        .card-list__description {
            margin-bottom: 0;
        }
    }

    &__compare {
        grid-area: product-series-card__compare;
        width: fit-content;
        
        margin-top: map.get($spacing, s-8);
        @include breakpoint(tablet-small) {
            margin-top: map.get($spacing, s-16);
        }
        &-input {
            &.form-input--checkbox {
                & + label {

                    --checkbox-mark-offset-x: 0px;
                    --checkbox-mark-offset-y: 8px;
                    border-left-width: 0;
                    padding-left: 0;

                    @include breakpoint(tablet-small) {
                        --checkbox-mark-offset-x: 16px;
                        --checkbox-mark-offset-y: 8px;
                        border-left-width: 1px;
                        padding-left: 1rem;
                    }

                    @include breakpoint(desktop) {
                        --checkbox-mark-offset-y: 16px;
                    }

                    &::before {
                        margin-right: map.get($spacing, s-8);
                    }
                }
            }

            &:checked + label {
                &::after {
                    transition: inherit;
                }
            }
        }

        &-label {
            border: 1px solid transparent;
            border-radius: rem(30);
            padding: map.get($spacing, s-8) map.get($spacing, s-16);
            transition: all 0.3s ease-in-out;

            @include breakpoint(desktop) {
                padding: map.get($spacing, s-16) map.get($spacing, s-40);
            }

            @include breakpoint(tablet-small) {
                &:hover {
                    background-color: var(--color-neutral-200);
                }
            }
        }
    }
}

body:has(.product-series-list.has-button--style-cold) #js-product-series-comparison-modal .primary-button .cta-button {
	--color-bg: var(--color-cold-500);
	--color-bg-hover: var(--color-cold-300);
	--color-text: var(--color-neutral-200);
	--color-text-hover: var(--color-text);
}

body:has(.product-series-list.has-button--style-warm) #js-product-series-comparison-modal .primary-button .cta-button {
	--color-bg: var(--color-warm-500);
	--color-bg-hover: var(--color-warm-300);
	--color-text: var(--color-neutral-200);
	--color-text-hover: var(--color-text);
}

body:has(.product-series-list.has-button--style-cold) #js-product-series-comparison-modal .secondary-button .cta-button {
	--color-bg: var(--color-neutral-200);
	--color-text: var(--color-neutral-600);
	--color-text-hover: var(--color-cold-500);
}

body:has(.product-series-list.has-button--style-warm) #js-product-series-comparison-modal .secondary-button .cta-button {
	--color-bg: var(--color-neutral-300);
	--color-text: var(--color-neutral-600);
	--color-text-hover: var(--color-cold-500);
}