@use 'sass:map';

.cmp-duo-banner {
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet-small) {
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-72);
    }

    @include breakpoint(desktop) {
        padding-bottom: map.get($spacing, s-96);
        padding-top: map.get($spacing, s-96);
    }

    &__container {
        @include breakpoint(tablet-small) {
            column-gap: map.get($spacing, s-24);
            display: flex;
        }

        @include breakpoint(desktop) {
            column-gap: map.get($spacing, s-32);
        }
    }

    &__banner {
        @include breakpoint(tablet-small) {
            width: 50%;
        }

        &-container {
            @include card-clickable-link-containment-block;
        }

        &--first {
            margin-bottom: map.get($spacing, s-48);
        }

        &--second {
            @include breakpoint(tablet-small) {
                margin-top: map.get($spacing, s-120);
            }

            @include breakpoint(desktop) {
                margin-top: map.get($spacing, s-240);
            }
        }

        &__content {
            @include breakpoint(desktop) {
                max-width: 80%;
            }
        }
    }

    .cmp-image {
        --aspect-ratio: #{map.get($aspect-ratios, '16-9')};

        margin-bottom: map.get($spacing, s-16);
        overflow: hidden;

        @include breakpoint(tablet-small) {
            --aspect-ratio: #{map.get($aspect-ratios, '3-4')};
        }
    }

    .cmp-title {
        &__text {
            color: var(--color-cold-600);
            font-weight: map.get($font-weights, light);
            margin-bottom: map.get($spacing, s-16);
            margin-top: map.get($spacing, s-16);

            @include font-size-per-breakpoint(h3);
        }
    }

    .cmp-text {
        color: var(--color-neutral-500);
        margin-bottom: map.get($spacing, s-16);
        margin-top: map.get($spacing, s-16);

        @include font-size-per-breakpoint(body-m);

        p {
            margin: 0;
        }
    }

    .cta-button {
        margin-bottom: map.get($spacing, s-16);
        margin-top: map.get($spacing, s-8);

        @include card-clickable-link-pseudo-element;
    }

    &, // Default link color.
    &--link-color-cold {
        .has-button-link .cta-button {
            --color-text: var(--color-cold-500);
            --color-text-hover: var(--color-cold-300);
        }
    }

    &--link-color-warm {
        .has-button-link .cta-button {
            --color-text: var(--color-warm-500);
            --color-text-hover: var(--color-warm-300);
        }
    }

    &--animation-ready {
        .cmp-duo-banner__banner {
            @include breakpoint(tablet-small) {
                opacity: 0;
                transition: 0.5s transform ease-in, 1s opacity linear;
            }

            &--first {
                @include breakpoint(tablet-small) {
                    transform: translateX(-72px);
                }
            }

            &--second {
                @include breakpoint(tablet-small) {
                    transform: translateX(72px);
                }
            }
        }
    }

    &--animation {
        .cmp-duo-banner__banner {
            @include breakpoint(tablet-small) {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }
}
