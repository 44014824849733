.navigation-segments__language-dropdown {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 159px;
    opacity: 0;
    transform: translateY(-100px);
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
    box-shadow: 0px 20px 52px 0px rgba(0,0,0,0.1);
    padding-top: 12px;
    padding-bottom: 15px;
    padding-left: 18px;
    padding-right: 34px;
    border-radius: 0 0 10px 10px;

    &--open {
        display: block;
        opacity: 1;
        transform: translateY(0);

        + .navigation-segments__link-language .navigation-segments__chevron-down::after {
            transform: rotate(270deg);
        }
    }
}

.language-dropdown {

    &__list {
        list-style: none;
    }

    &__list-item {
        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__link {
        padding-left: 0;
        padding-right: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: normal;

        &--active {
            font-weight: 600;
        }
    }

    &__close {
        position: absolute;
        right: 18px;
        top: 16px;
        width: rem(16);
        height: rem(16);
    
        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: rem(16);
            mask-image: var(--svg-close);
            width: rem(16);
            background-color: var(--color-icon);
            cursor: pointer;
        }
    }

}