@use 'sass:map';

.cmp-intro-block {
    padding-bottom: map.get($spacing, s-24);
    padding-top: map.get($spacing, s-48);

    @at-root :not([class*='background--color-']) > & {
        @include background-color(#{var(--color-neutral-300)});
    }

    @include breakpoint(tablet) {
        display: grid;
        gap: 0 rem(16);
        grid-template-columns: repeat(10, 1fr);
        padding-bottom: map.get($spacing, s-48);
    }

    @include breakpoint(desktop) {
        padding-top: map.get($spacing, s-96);
    }

    &__title {
        margin-bottom: map.get($spacing, s-8);

        @include breakpoint(tablet) {
            grid-column: 1 / span 5;
            margin-bottom: map.get($spacing, s-24);
        }

        @include breakpoint(desktop) {
            grid-column: 1 / span 4;
            margin-bottom: map.get($spacing, s-16);
        }

        .cmp-title__text {
            font-weight: map.get($font-weights, light);

            @include font-size-per-breakpoint(h2);
        }
    }

    &__description {
        color: var(--color-neutral-500);

        @include font-size-per-breakpoint(body-l);

        @include breakpoint(tablet) {
            grid-column: 1 / span 10;
        }

        @include breakpoint(desktop) {
            grid-column: 1 / span 7;
        }
    }

    &__button {
        margin-top: map.get($spacing, s-24);

        @include breakpoint(tablet) {
            grid-column: 1 / span 10;
            margin-top: map.get($spacing, s-24);
        }

        @include breakpoint(desktop) {
            display: flex;
            grid-column: 8 / span 3;
            justify-content: flex-end;
            margin-bottom: auto;
            margin-top: 0;
        }
    }

    // Default color
    .has-button {
        .cta-button {
            --color-bg: var(--color-cold-500);
            --color-bg-hover: var(--color-cold-300);
            --color-text: var(--color-neutral-200);
            --color-text-hover: var(--color-text);
        }

        &.has-button--style-warm {
            .cta-button {
                --color-bg: var(--color-warm-500);
                --color-bg-hover: var(--color-warm-300);
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
    }

    &--description-floated {
        .cmp-intro-block {
            &__title {
                @include breakpoint(tablet) {
                    grid-column: 1 / span 3;
                    grid-row: 1 / span 2;
                    margin-bottom: 0;
                    padding-top: map.get($spacing, s-8);
                }

                @include breakpoint(desktop) {
                    grid-column: 1 / span 4;
                    margin-bottom: 0;
                }
            }

            &__description {
                @include breakpoint(tablet) {
                    grid-column: 5 / span 6;
                }

                @include breakpoint(desktop) {
                    grid-column: 6 / span 5;
                }

                & ~ .cmp-intro-block__button {
                    margin-top: map.get($spacing, s-24);
                }
            }

            &__button {
                @include breakpoint(tablet) {
                    display: flex;
                    grid-column: 6 / span 5;
                    justify-content: flex-end;
                }

                @include breakpoint(desktop) {
                    grid-column: 5 / span 6;
                }
            }
        }
    }
}
