@use 'sass:map';

.doormat {
    @include background-color(var(--color-warm-600));

    padding-bottom: map.get($spacing, s-32);
    padding-top: map.get($spacing, s-48);
    position: relative;

    @include breakpoint(tablet-small) {
        padding-bottom: map.get($spacing, s-24);
    }

    // container-max on desktop only
    @include breakpoint(desktop) {
        --container-max-width: #{rem(1440)};
        --container-padding: #{map.get($spacing, s-48)};

        padding-bottom: map.get($spacing, s-32);
    }

    &::after {
        border-bottom: 1px solid var(--color-neutral-200);
        content: '';
        display: block;
        opacity: 0.1;
    }

    .logo--daikin {
        height: rem(22);
        margin-bottom: map.get($spacing, s-32);
        width: rem(100);

        @include breakpoint(tablet-small) {
            height: rem(38);
            margin-bottom: map.get($spacing, s-48);
            width: rem(175);
        }

        use {
            fill: var(--color-neutral-200);
        }
    }
}

.doormat-block {
    & > .cmp-container {
        @include breakpoint(tablet) {
            display: flex;
            gap: map.get($spacing, s-12);
            justify-content: space-evenly;
        }
    }

    &__column {
        @include breakpoint(tablet) {
            width: 25%;
        }

        .title-screenreader-only {
            white-space: nowrap;
        }
    }

    &__title {
        color: var(--color-neutral-200);

        &--hide-mobile {
            display: none;

            @include breakpoint(tablet) {
                display: block;
            }
        }
    }

    .cmp-title__text,
    .dropdown__button__text {
        @include font-size-per-breakpoint(h4);

        @include breakpoint(tablet) {
            font-size: rem(32);
        }
    }

    &__button {
        &--show-mobile {
            min-width: 50%;
            padding-left: 0;

            @include breakpoint(tablet) {
                display: none !important;
                min-width: 33.33%;
            }
        }
    }

    .dropdown__button {
        --color-text: var(--color-neutral-200);
        --color-text-hover: var(--color-text);
        --color-icon: var(--color-text);
        --color-icon-hover: var(--color-text-hover);

        padding-left: 0;

        &::after {
            margin-left: auto;
        }
    }

    &__links {
        margin-bottom: map.get($spacing, s-24);

        @include breakpoint(tablet) {
            margin-bottom: map.get($spacing, s-48);
            margin-top: map.get($spacing, s-24);
        }
    }

    .cta-button {
        @include font-size-per-breakpoint(h5);

        color: var(--color-neutral-200);
        font-weight: map.get($font-weights, normal);
        line-height: 2.5;
        text-decoration: none;

        @include breakpoint(tablet) {
            line-height: 2.4;
        }

        @include focus {
            border-radius: 2px;
            outline: solid 2px var(--color-neutral-200);
            outline-offset: 6px;
        }

        &:hover {
            text-decoration: underline;
            text-decoration-thickness: 1.5px;
            text-underline-offset: 3px;
        }
    }
}
