@use 'sass:map';

.cmp-dealer-contact-info {
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet) {
        display: grid;
        gap: map.get($spacing, s-16);
        grid-template-columns: repeat(10, 1fr);
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-72);
    }

    &__map {
        height: rem(326);
        margin-bottom: map.get($spacing, s-32);
        width: 100%;

        @include breakpoint(tablet) {
            aspect-ratio: map.get($aspect-ratios, '1-1');
            grid-column: 1 / span 5;
            height: auto;
            margin-bottom: 0;
        }
    }

    &__content {
        @include breakpoint(tablet) {
            display: flex;
            flex-direction: column;
            grid-column: 7 / span 4;
        }
    }

    &__title {
        margin-bottom: map.get($spacing, s-24);
    }

    &__address {
        color: var(--color-neutral-500);
        margin-bottom: map.get($spacing, s-12);

        @include font-size-per-breakpoint(body-m);
    }

    &__contact {
        border-top: 1px solid var(--color-neutral-400);
        margin-top: map.get($spacing, s-48);
        padding-top: map.get($spacing, s-8);

        @include breakpoint(tablet) {
            margin-top: auto;
        }

        @include breakpoint(desktop) {
            padding-top: map.get($spacing, s-16);
        }
    }

    &__contact-intro {
        color: var(--color-neutral-500);
        margin-bottom: map.get($spacing, s-12);

        @include font-size-per-breakpoint(body-m);
    }

    &__contact-option-link {
        appearance: none;
        background: transparent;
        border: 1px solid transparent;
        color: var(--color-neutral-500);
        cursor: pointer;
        display: inline-block;
        font-weight: map.get($font-weights, normal);
        padding: 0;
        text-decoration: underline;
        vertical-align: middle;

        @include font-size-per-breakpoint(body-m);

        &:hover {
            color: var(--color-neutral-500);
            cursor: pointer;
            text-decoration: none;
        }

        &::after {
            background-color: var(--color-warm-500);
            content: '';
            display: inline-block;
            height: rem(24);
            margin-left: map.get($spacing, s-8);
            margin-top: rem(-2);
            mask-image: var(--svg-chevron);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            transform: rotate(90deg);
            vertical-align: middle;
            width: rem(24);
        }

        @include focus {
            outline: 2px solid var(--color-cold-600);
            outline-offset: 6px;
        }
    }

    &__contact-options {
        margin-top: map.get($spacing, s-8);
    }

    &__contact-option {
        display: inline-block;
        margin-right: map.get($spacing, s-16);

        @include breakpoint(desktop) {
            display: block;
            margin-bottom: map.get($spacing, s-8);
            margin-right: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            @include font-size-per-breakpoint(body-m);

            color: var(--color-neutral-500);
            display: inline-block;
            font-weight: map.get($font-weights, normal);
            line-height: map.get($spacing, s-48);
            min-height: map.get($spacing, s-48);
            padding-left: map.get($spacing, s-48);
            position: relative;
            text-decoration: none;

            @include breakpoint(desktop) {
                min-height: auto;
            }

            &:hover {
                text-decoration: underline;
            }

            &::after,
            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                vertical-align: middle;
            }

            &::after {
                background-color: var(--color-warm-500);
                height: map.get($spacing, s-24);
                left: map.get($spacing, s-12);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                top: map.get($spacing, s-12);
                width: map.get($spacing, s-24);
            }

            &::before {
                background-color: var(--color-neutral-400);
                border-radius: 50%;
                color: var(--color-warm-500);
                height: map.get($spacing, s-48);
                left: 0;
                top: 0;
                vertical-align: middle;
                width: map.get($spacing, s-48);
                z-index: -1;
            }
        }

        span {
            display: none;
            padding-left: map.get($spacing, s-12);

            @include breakpoint(desktop) {
                display: block;
            }
        }

        &--mail {
            a::after {
                mask-image: var(--svg-email);
            }
        }

        &--phone {
            a::after {
                mask-image: var(--svg-phone);
            }
        }

        &--website {
            a::after {
                mask-image: var(--svg-external-link);
            }
        }
    }
}
