/*
 * Usage:
 *
 * .cmp-image__link {
 *     @include focus() {
 *         border-radius: 2px;
 *         outline: solid 2px #000;
 *     }
 * }
 */
@mixin focus() {
    &:focus-visible {
        @content;
    }

    /* Fallback for browsers without :focus-visible support */
    @supports not selector(:focus-visible) {
        &:focus {
            @content;
        }
    }
}
