@use 'sass:math';

/*
 * Usage:
 *
 * font-size: rem(30);
 */
@function rem($pixels, $context: 16) {
    @return #{math.div($pixels, $context)}rem;
}
