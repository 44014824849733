@use 'sass:map';

.cmp-multi-image {
    display: grid;
    gap: map.get($spacing, s-16);
    grid-template-areas:
            'image1 image2 image3'
            'caption caption caption';
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet-small) {
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-72);
    }

    @include breakpoint(tablet) {
        grid-template-areas:
                'image1 image2 image3'
                'caption caption empty';
    }

    @include breakpoint(desktop) {
        grid-template-areas:
                'image1 image2 image3'
                'caption empty empty';
        padding-bottom: map.get($spacing, s-96);
        padding-top: map.get($spacing, s-96);
    }

    .cmp-image {
        --aspect-ratio: #{map.get($aspect-ratios, '3-4')};

        margin-bottom: 0;
    }

    &__image {
        grid-area: image2;

        &:first-of-type {
            grid-area: image1;
        }

        &:last-of-type {
            grid-area: image3;
        }
    }

    &__caption {
        @include font-size-per-breakpoint(body-m);

        grid-area: caption;
    }

    &--style-duo {
        &.cmp-multi-image {
            grid-template-areas:
                    'image1'
                    'image2'
                    'caption';
            grid-template-columns: 1fr;

            @include breakpoint(tablet-small) {
                grid-template: auto 1fr / repeat(2, 1fr);
                grid-template-areas:
                        'image1 image2'
                        'caption image2';
            }
        }

        .cmp-image {
            --aspect-ratio: #{map.get($aspect-ratios, '16-9')};

            @include breakpoint(tablet-small) {
                --aspect-ratio: #{map.get($aspect-ratios, '3-4')};
            }
        }

        .cmp-multi-image {
            &__image {
                &:last-of-type {
                    grid-area: image2;

                    @include breakpoint(tablet-small) {
                        margin-top: map.get($spacing, s-120);
                    }

                    @include breakpoint(desktop) {
                        margin-top: map.get($spacing, s-240);
                    }
                }
            }

            &__caption {
                @include breakpoint(tablet-small) {
                    grid-area: caption;
                }
            }
        }
    }
}
