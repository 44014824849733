@use 'sass:map';

.cmp-mono-banner {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-72);
    }

    @include breakpoint(desktop) {
        padding-bottom: map.get($spacing, s-96);
        padding-top: map.get($spacing, s-96);
    }

    @at-root :not([class*='background--color-']) > & {
        @include background-color(#{var(--color-neutral-300)});
    }

    .cmp-image {
        --aspect-ratio: #{map.get($aspect-ratios, '16-9')};
    }

    &__content {
        margin-top: map.get($spacing, s-24);

        @include breakpoint(tablet) {
            display: flex;
            gap: map.get($spacing, s-16);
        }

        @include breakpoint(desktop) {
            justify-content: space-between;
        }
    }

    &__title {
        @include breakpoint(tablet) {
            width: 40%;
        }
    }

    .cmp-title__text {
        @include font-size-per-breakpoint(h3);

        color: var(--color-cold-600);
        font-weight: map.get($font-weights, normal);
        margin-bottom: map.get($spacing, s-16);

        @include breakpoint(tablet) {
            --line-height: 1.35;
        }

        @include breakpoint(desktop) {
            --line-height: 1.2;
        }
    }

    &__description {
        @include breakpoint(tablet) {
            width: 60%;
        }

        @include breakpoint(desktop) {
            width: 50%;
        }
    }

    .cmp-text {
        @include font-size-per-breakpoint(body-m);

        color: var(--color-neutral-500);
        line-height: 1.5;
        margin-bottom: map.get($spacing, s-24);

        @include breakpoint(desktop) {
            line-height: 1.4;
        }
    }

    // Default link color.
    .has-button-link {
        .cta-button {
            --color-text: var(--color-cold-500);
            --color-text-hover: var(--color-cold-300);
        }

        &.has-button-link--style-warm {
            .cta-button {
                --color-text: var(--color-warm-500);
                --color-text-hover: var(--color-warm-300);
            }
        }
    }

    .cmp-button {
        @include font-size-per-breakpoint(button);

        margin-top: map.get($spacing, s-8);

        @include breakpoint(tablet) {
            margin-left: -#{map.get($spacing, s-8)};
        }

        @include breakpoint(desktop) {
            margin-top: 0;
        }
    }

    &--image-on-bottom & {
        display: flex;
        flex-direction: column;

        .cmp-image {
            margin-bottom: 0;
        }

        &__content {
            margin-bottom: map.get($spacing, s-24);
            margin-top: 0;

            @include breakpoint(tablet) {
                margin-bottom: map.get($spacing, s-32);
            }

            @include breakpoint(desktop) {
                margin-bottom: map.get($spacing, s-48);
            }
        }
    }
}
