/* stylelint-disable selector-no-vendor-prefix */

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
header,
nav,
article,
section,
aside,
figure,
figcaption,
footer,
dl,
dd,
dt {
    margin: 0;
    padding: 0;
}

html {
    box-sizing: border-box;

    // Hide persistent scrollbar in IE (10/11) and Edge
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
    text-rendering: optimizelegibility;
}

ul,
ol {
    list-style: none;
}

img {
    border: none;
    display: block;
    max-width: 100%;
}

// Firefox input fix
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

// Normalise form field line-height in webkit browsers
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
    line-height: normal;
}

* {
    &,
    &::before,
    &::after {
        box-sizing: border-box;
    }
}
