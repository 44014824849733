@use 'sass:map';
@import '../utilities/mixins/typography-mixins';

:root {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

html {
    font-family: sans-serif;
    font-size: 100%;
    text-rendering: optimizelegibility;
}

body {
    font-family: map.get($font-families, default);
    font-size: 100%;
    font-weight: normal;
    letter-spacing: var(--font-letter-spacing);
    line-height: 1.5;
}

b,
strong {
    font-weight: map.get($font-weights, bold);
}

small {
    @include font-size-per-breakpoint(body-s);
}

h1,
.heading1 {
    font-weight: map.get($font-weights, light);

    @include font-size-per-breakpoint(h1);
}

h2,
.heading2 {
    font-weight: map.get($font-weights, light);

    @include font-size-per-breakpoint(h2);
}

h3,
.heading3 {
    font-weight: map.get($font-weights, light);

    @include font-size-per-breakpoint(h3);
}

h4,
.heading4 {
    font-weight: map.get($font-weights, normal);

    @include font-size-per-breakpoint(h4);
}

h5,
.heading5 {
    font-weight: map.get($font-weights, semibold);

    @include font-size-per-breakpoint(h5);
}

h6,
.heading6 {
    font-weight: map.get($font-weights, semibold);

    @include font-size-per-breakpoint(h6);
}
