@use 'sass:map';

.tippy-box[data-theme~='b2c-warm'] {
    background-color: var(--color-warm-600);
    color: var(--color-neutral-200);
    padding: map.get($spacing, s-16) map.get($spacing, s-8);
}

.tippy-box[data-theme~='b2c-cold'] {
    background-color: var(--color-cold-600);
    color: var(--color-neutral-200);
    padding: map.get($spacing, s-16) map.get($spacing, s-8);
}
