.background {
    &--color-grey,
    &--color-white,
    &--color-warm,
    &--color-cold {
        @include background-color(transparent);
    }

    &--color-grey {
        --background-color: var(--color-neutral-300);
    }

    &--color-white {
        --background-color: var(--color-neutral-200);
    }

    &--color-warm {
        --background-color: var(--color-warm-600);
    }

    &--color-cold {
        --background-color: var(--color-cold-600);
    }
}
