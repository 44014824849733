@use 'sass:map';

.rating-stars {
    --rating-stars-icon-color-active: var(--color-warm-500);
    --rating-stars-icon-color-inactive: var(--color-warm-200);
    --rating-stars-icon-height: #{rem(22)};
    --rating-stars-icon-width: #{rem(24)};

    align-items: center;
    display: flex;

    &__star {
        height: var(--rating-stars-icon-height);
        position: relative;
        width: var(--rating-stars-icon-width);

        &::after,
        &::before {
            background-color: var(--rating-stars-icon-color-inactive);
            content: '';
            display: block;
            height: var(--rating-stars-icon-height);
            mask-image: var(--svg-rating-stars-star);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            width: var(--rating-stars-icon-width);
        }

        &::after {
            background-color: var(--rating-stars-icon-color-active);
            display: none;
        }

        &--full {
            &::after {
                display: block;
            }
        }

        &--half {
            &::after {
                display: block;
                mask-image: var(--svg-rating-stars-half-star);
            }
        }
    }

    &__text {
        @include font-size-per-breakpoint(body-l);

        color: var(--color-neutral-500);
        padding-left: map.get($spacing, s-8);
    }
}
