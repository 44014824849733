@use 'sass:map';

@mixin container {
    --container-margin: auto;
    --container-max-width: auto;
    --container-padding: 0;
    --container-width: 100%;

    margin-left: var(--container-margin);
    margin-right: var(--container-margin);
    max-width: var(--container-max-width);
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
    width: var(--container-width);
}

@mixin container-bleed {
    @include container;

    --container-margin: calc(50% - 50vw);
    --container-width: auto;

    @include breakpoint(desktop-large) {
        --container-margin: calc(50% - #{rem(720)});
    }
}

@mixin container-content {
    @include container;

    --container-padding: #{map.get($spacing, s-24)};

    @include breakpoint(tablet) {
        --container-padding: #{map.get($spacing, s-96)};
    }

    @include breakpoint(desktop) {
        --container-max-width: #{rem(1260)};
        --container-padding: #{map.get($spacing, s-72)};
    }
}

@mixin container-max {
    @include container;

    --container-padding: #{map.get($spacing, s-24)};

    @include breakpoint(desktop) {
        --container-max-width: #{rem(1440)};
        --container-padding: #{map.get($spacing, s-48)};
    }
}
