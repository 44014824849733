@use 'sass:map';
@use 'sass:math';

.cmp-embed {
    overflow: hidden;

    iframe {
        background-color: transparent;
        border: 0;
        width: 100%;

        &[src*='youtu.be'],
        &[src*='youtube.com'],
        &[src*='vimeo.com'] {
            aspect-ratio: map.get($aspect-ratios, '16-9');
            height: auto;
        }
    }
}

.trustpilot-horizontal{
    padding-top: 48px;
    @include breakpoint(tablet-small) {
        padding-top: 72px;
    }
    @include breakpoint(desktop) {
        padding-top: 96px;
    }
}

.trustpilot-carousel{
    padding: 0 0 28px 0;
    @include breakpoint(tablet-small) {
        padding: 24px 0 32px 0;
    }
    @include breakpoint(desktop) {
        padding: 34px 0 72px 0;
    }
}

@media screen and (min-width:1px) {
    .trustpilot-horizontal{
        padding-top: 48px;
    }
    .trustpilot-carousel{
        padding: 0 0 28px 0;
    }
}
  
@media screen and (min-width:577px) {
    .trustpilot-horizontal{
        padding-top: 72px;
    }
    .trustpilot-carousel{
        padding: 24px 0 32px 0;
    }
}
  
@media screen and (min-width:1195px) {
    .trustpilot-horizontal{
        padding-top: 96px;
    }
    .trustpilot-carousel{
        padding: 34px 0 72px 0;
    }
}