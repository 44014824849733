@use 'sass:map';

html {
    --main-navigation-height: #{rem(72)};

    scroll-padding-top: var(--main-navigation-height);

    @include breakpoint(tablet) {
        --main-navigation-height: #{rem(120)};
    }

    @include breakpoint(desktop) {
        --main-navigation-height: #{rem(149)};
    }
}

body {
    margin-top: var(--main-navigation-height);
}

.headroom {
    transition: transform 0.2s linear;
    will-change: transform;

    &--pinned {
        transform: translateY(0%);
    }

    &--unpinned {
        transform: translateY(-100%);
    }
}

.cmp-container.show {
    opacity: .8;
}

.navigation-bar {
    background-color: var(--color-background);
    box-shadow: 0 0 20px rgba(#000, 0.1);
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: var(--zindex-navigation);

    &__icon {
        --color-icon: var(--color-cold-500);

        &::after {
            background-color: var(--color-icon);
            content: '';
            display: inline-block;
            height: rem(24);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            vertical-align: middle;
            width: rem(24);
        }
    }

    &--compact {
        display: flex;
        justify-content: center;
    }

    @include breakpoint(tablet) {
        &--menu-overflow {
            .navigation-topmenu__menu {
                visibility: hidden;
            }
            .navigation-topmenu__item--hamburger {
                display: flex !important;
            }
            .navigation-topmenu__container-right {
                // flex: 1 !important;
            }
            .navigation-segments {
                display: none;
            }
            .navigation-mobile__menu {
                width: 30%;
            }
        }

        &:not(&--menu-overflow) {
            .navigation-topmenu__menu {
                // overflow: auto;
            }
        
        }
    }
}

@include breakpoint(desktop) {
    .navigation-topmenu__content .navigation-topmenu__item {
        font-size: 16px;
    }

    .nav-small-cta {
        --font-size: 1rem !important;
        --line-height: 1.5rem !important;
        padding: 0.5rem 1rem !important;
        font-weight: 600 !important;
    }
}

.navigation-segments {
    display: none;

    @include breakpoint(tablet) {
        display: block;
    }

    &__content {
        display: flex;
        justify-content: flex-end;
        position: relative;

        &::after {
            background-color: var(--color-neutral-400);
            bottom: 0;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
        }
    }

    &__menu {
        display: flex;
        justify-content: flex-end;
    }

    &__link {
        color: var(--color-neutral-500);
        display: block;
        font-weight: map.get($font-weights, semibold);
        padding: map.get($spacing, s-12);
        position: relative;
        text-decoration: none;

        @include font-size-per-breakpoint(body-s);

        &-segment {
            &--active {
                color: var(--color-cold-600);
            }

            &--active,
            &:hover {
                &::after {
                    background-color: var(--color-cold-500);
                    bottom: 0;
                    content: '';
                    height: 1px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    z-index: 1;
                }
            }
        }

        &-language {
            color: var(--color-cold-600);
        }
    }

    &__chevron-down {
        padding-left: map.get($spacing, s-8);

        &::after {
            height: rem(16);
            mask-image: var(--svg-chevron);
            transform: rotate(90deg);
            width: rem(16);
        }
    }
}

.navigation-topmenu {
    &__text {
        display: none;
    }

    &__content {
        align-items: center;
        display: flex;

        @include font-size-per-breakpoint(body-m);
    }



    &__menu {
        display: none;
        flex: 1;
        flex-wrap: wrap;
        justify-content: flex-start;
        order: 1;

        @include breakpoint(tablet) {
            display: flex;
            order: 2;
            overflow: hidden;
        }


        &-items {
            align-items: center;
            display: flex;
            white-space: nowrap;
        }
    }

    &__brand {
        display: flex;
        justify-content: center;
        order: 2;
        padding: map.get($spacing, s-24) 0;
        width: rem(100);

        @include breakpoint(tablet) {
            order: 1;
            margin-right: map.get($spacing, s-24);
        }

        @include breakpoint(desktop) {
            padding: map.get($spacing, s-32) 0;
            width: rem(175);
            margin-right: map.get($spacing, s-48);
        }

        .logo--daikin {
            height: rem(22);
            min-width: rem(100);

            @include breakpoint(desktop) {
                height: rem(38);
                min-width: rem(175);
            }
        }
    }

    &__container-right {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: flex-end;
        order: 3;

        @include breakpoint(tablet) {
            flex: unset;
        }

    }

    &__item { //search.html
        background-color: transparent;
        border: none;
        color: var(--color-neutral-600);
        cursor: pointer;
        font-weight: map.get($font-weights, normal);
        padding: map.get($spacing, s-16) map.get($spacing, s-12);
        text-decoration: none;

        &--hamburger.navigation-topmenu__button-container {
            padding-left: 0;
            padding-right: 0;

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    &__chevron-down {
        &::after {
            mask-image: var(--svg-chevron);
            transform: rotate(90deg);
        }
    }

    &__button {
        background-color: var(--color-background);
        border-style: none;
        cursor: pointer;
        padding: 0;

        @include focus {
            border-radius: 2px;
            outline: 2px solid var(--color-cold-600);
            outline-offset: 6px;
        }

        &--search {
            

            @include breakpoint(tablet) {
                padding: map.get($spacing, s-8) 0;
                display: inline-block;
            }

            @include breakpoint(desktop) {
                display: inline-block;
            }

            &--active,
            &:hover {
                color: var(--color-cold-500);
            }
        }

        &--hamburger {
            background-color: var(--color-neutral-300);
            border-radius: 30px;
            height: rem(40);
            margin-bottom: auto;
            margin-top: auto;
            text-align: center;
            width: rem(40);

            @include focus {
                border-radius: 30px;
            }

            &[aria-expanded='true'] {
                .navigation-topmenu__hamburger-icon {
                    display: none;

                    &--close {
                        display: inline;
                    }
                }
            }
        }
    }

    &__search-icon {
        &::after {
            mask-image: var(--svg-search);
        }
    }

    &__hamburger-icon {
        &::after {
            mask-image: var(--svg-hamburger);
        }

        &--close {
            display: none;

            &::after {
                mask-image: var(--svg-close);
            }
        }
    }

    &__button-container { //search.html
        display: flex;
        align-items: center;

        .cta-button {
        
            font-weight: map.get($font-weights, semibold);
            padding: map.get($spacing, s-8) map.get($spacing, s-16);

            @include breakpoint(desktop) {
                padding: map.get($spacing, s-16) map.get($spacing, s-32);
            }
        }
    }
    .navigation-topmenu__menu-items li:first-child a {
        padding-left: 0;
    }
}


body:has(.overlay-search--visible)::after {
    background-color: rgba(0,0,0,0.7);
    content: "";
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    width: 100%;
    z-index: 1;
}

.overlay-search {
    display: flex;
    flex-direction: column;
    display: none;



    &--visible {
        display: block;
        position: absolute;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,0.7);
    }

    .rows {
        background-color: var(--color-neutral-200);
        position: fixed;
        z-index: var(--zindex-modal);
        left: 0;
        width: 100%;
    }

    .navigation__search-bar {


        &--inner {
            grid-column: 3 / span 8;
            justify-content: center;
            align-items: center;
            display: flex;
            grid-row: 1 / span 1;

            input {
                flex: 1;
            }
        }

        .divider {
            grid-column: 1 / span 12;
            grid-row: 2 / span 1;
            width: calc(100% - 96px);
            margin: 1rem 48px 1rem 48px;
            background-color: var(--color-neutral-400);
            height: 1px;
        }

        .autocomplete-items {
            grid-column: 3 / span 8;
            grid-row: 3 / span 1;
        }

        .autocomplete-items-background {
            grid-column: 1 / span 12;
            grid-row: 3 / span 1;
            background-color: #fff;
        }

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: auto calc(2rem + 1px) auto;



        padding: map.get($spacing, s-72) 0 map.get($spacing, s-32) 0;

        .navigation__text--search {
            display: none;
        }

        .navigation-bar__icon {
            padding-right: map.get($spacing, s-16);

            :after {
                background-color: var(--color-cold-600);
            }
        }

        input {
            width: 40%;
            background: transparent;
            border: none;
            outline: none;
            padding: map.get($spacing, s-8) map.get($spacing, s-16);
        }

        input[type="search"]::-webkit-search-cancel-button {
            display: none;
        }

        .clear-button {
            border-radius: map.get($spacing, s-32);
            border: none;
            background: var(--03-neutral-300, #F4F4F5);
            cursor: pointer;

            font-size: map.get($font-sizes, button-desktop);
            font-weight: map.get($font-weights, semibold);
            padding: map.get($spacing, s-16) map.get($spacing, s-24);

            &:hover {
                background: var(--03-neutral-200, #E9E9EA);
            }
        }

        .close-button {
            width: rem(24);
            height: rem(24);
            mask-image: var(--svg-close);
            background-color: var(--color-cold-500);
            margin-left: map.get($spacing, s-16);
            cursor: pointer;

            &:hover {
                background-color: var(--color-neutral-400);
            }
        }

        .navigation__search-icon {
            border: none;
            mask-image: var(--svg-search);
            background: transparent;
            padding: 0;
            height: rem(40);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            width: rem(24);

            @include focus {
                border-radius: 2px;
                outline: 2px solid var(--color-cold-600);
                outline-offset: 6px;
            }

            &.show:after {
                background-color: var(--color-cold-600);
            }
        }

        .autocomplete {                
            position: relative;
            display: inline-block;
        }

        .autocomplete-items {

        
            
            // position: absolute;
            background-color: #fff;
            border-bottom: none;
            border-top: none;
            z-index: 99;
            /*position the autocomplete items to be the same width as the container:*/
            top: 100%;
            left: 0;
            right: 0;
        }
        
        .autocomplete-items div {
            padding: 10px;
            cursor: pointer;
            background-color: #fff;
        }
        
        .autocomplete-items div:hover {
            /*when hovering an item:*/
            background-color: #e9e9e9;
        }
        
        .autocomplete-active {
            /*when navigating through the items using the arrow keys:*/
            background-color: DodgerBlue !important;
            color: #ffffff;
        }
    }

}

.navigation-topmenu__hamburger-icon:after {
	height: 2.5rem !important;
}
.navigation-topmenu__hamburger-icon--close:after {
	height: 2.5rem !important;
}

.navigation-topmenu__mirror {
    position: fixed;
    visibility: hidden;
    z-index: -1;
    top: 0;
}