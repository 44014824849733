@use 'sass:map';

.cookie-notice--preferences {
    .cookie-notice {
        &__pagetitle {
            margin-bottom: map.get($spacing, s-16);
        }

        &__item {
            display: flex;
            gap: map.get($spacing, s-16);

            &__content {
                &--dropdown {
                    width: 100%;

                    @include breakpoint(tablet) {
                        display: block;
                    }

                    .dropdown__button {
                        &__text {
                            font-weight: map.get($font-weights, normal);
                            list-style-type: none;
                        }

                        &__icon {
                            font-size: var(--font-size);
                        }
                    }

                    .cookie-notice__item__checkbox {
                        list-style-type: none;
                        padding-left: map.get($spacing, s-12);
                    }
                }

                &--inline {
                    @include breakpoint(tablet) {
                        display: none;
                    }
                }
            }
        }

        &__controls {
            .has-button {
                min-width: rem(150);
            }
        }
    }
}

.cookie-notice-preferences-notification-message {
    animation: fadeInOut 4s cubic-bezier(0, 1, 0.565, 1) both;
    bottom: 0;
    font-weight: map.get($font-weights, bold);
    left: 0;
    line-height: map.get($spacing, s-24);
    max-width: map.get($breakpoints, tablet-min-width);
    padding: map.get($spacing, s-24) map.get($spacing, s-32);
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: var(--zindex-modal);

    @include font-size-per-breakpoint(body-l);

    @include breakpoint(tablet) {
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
    }
}
