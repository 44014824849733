@media (prefers-reduced-motion: reduce) {
    *,
    *::after,
    *::before {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        scroll-behavior: auto !important;
        transition-duration: 0.01ms !important;
    }
}

.animation {
    &--slide-in-left {
        animation: slideInFromRightToLeft 0.35s cubic-bezier(0.25, 1, 0.3, 1) both;
    }

    &--slide-in-right {
        animation: slideInFromLeftToRight 0.35s cubic-bezier(0.25, 1, 0.3, 1) both;
    }

    &--fade-in {
        animation: fadeIn 0.35s cubic-bezier(0.25, 1, 0.3, 1) both;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slideInFromLeftToRight {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideInFromRightToLeft {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

