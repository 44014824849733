@use 'sass:map';

.product-series-gallery {
    background-color: var(--color-cold-100);
    border: 1px solid var(--color-neutral-300);

    .cmp-image {
        --aspect-ratio: #{map.get($aspect-ratios, '1-1')};

        margin: 0;
    }

    .swiper-wrapper {
        padding-bottom: 0;
        padding-top: 0;
    }

    &__nav {
        align-items: center;
        bottom: rem(32);
        display: flex;
        height: 20px;
        justify-content: center;
        position: absolute;
        width: 100%;
        z-index: 5;
    }

    &__pagination {
        bottom: 10px;
        display: flex;
        text-align: center;
        transform: translate3d(0, 0, 0);
        transition: 300ms opacity;
        z-index: 10;

        &--hidden,
        &--locked {
            display: none;
        }

        /* Bullets */

        &-indicator {
            background-color: var(--color-neutral-200);
            cursor: pointer;
            display: inline-block;
            height: rem(3);
            margin: 0 8px;
            max-width: rem(58);
            min-width: rem(32);
            position: relative;
            width: calc(100% / 5);

            @include breakpoint(tablet) {
                width: rem(47);
            }

            @include breakpoint(desktop) {
                max-width: rem(90);
            }

            // Enlarge clickable area
            &::before {
                content: '';
                height: rem(32);
                left: 0;
                position: absolute;
                transform: translateY(-44%);
                width: 100%;
            }

            &:hover {
                background: var(--color-neutral-700);
            }

            @include focus {
                outline: 2px solid var(--color-cold-500);
                outline-offset: 6px;
            }

            // Hide pagination if there is only one item
            &:only-child {
                display: none !important;
            }

            &--active {
                background: var(--color-neutral-700);
            }
        }
    }

    &__control {
        align-items: center;
        appearance: none;
        background: none;
        border: 1px solid transparent;
        color: var(--color-cold-600);
        cursor: pointer;
        display: none;

        @include breakpoint(tablet) {
            display: block;
        }

        // TODO: check with design
        &:hover {
            color: var(--color-cold-300);
        }

        @include focus {
            outline: 2px solid var(--color-cold-500);
            outline-offset: 6px;
        }

        &[aria-disabled='true'] {
            display: none;
        }

        &__icon {
            display: block;
            position: relative;

            &::before {
                background: currentcolor;
                content: '';
                display: inline-block;
                height: 26px;
                mask-image: var(--svg-arrow);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: relative;
                transition: inherit;
                vertical-align: middle;
                width: 26px;

                @media (forced-colors: active) {
                    color: ButtonText;
                    forced-color-adjust: none;
                }
            }
        }

        &--prev {
            .product-series-gallery__control__icon {
                transform: rotate(180deg);
            }
        }
    }

    &--4by3 {
        aspect-ratio: map.get($aspect-ratios, '4-3');

        .cmp-image {
            --aspect-ratio: #{map.get($aspect-ratios, '4-3')};
        }
    }
}
