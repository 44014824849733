@use 'sass:map';

.result-list {
    padding-top: map.get($spacing, s-16);
    margin-top: map.get($spacing, s-32);
    margin-bottom: 3rem;

    @include breakpoint(tablet-small) {
        margin-top: map.get($spacing, s-48);
        margin-bottom: 4.5rem;
    }

    &__product {
        display: grid;

        .result-list__item {
            grid-auto-rows: auto auto auto 1fr;
        }

        .result-list__category {
            grid-auto-rows: auto 1fr;
        }
    }

    &__content {
        display: none;

        .result-list__item {
            grid-auto-rows: auto auto 1fr auto;
        }
    }

    &__item {
        display: grid;
        @include card-clickable-link-containment-block;
    }

    &__category {
        background: var(--03_Neutral-300, #F4F4F5);
    }

    &__info {
        display: grid;
        grid-auto-rows: 1fr auto;
        padding: 0px 16px 16px 16px;

        @include breakpoint(tablet-small) {
            padding: 0px 24px 24px 24px;
            grid-auto-rows: auto 1fr auto;
        }
    }

    &__image {
    
    }

    .result-title__text {
        font-size: 16px;
        font-weight: map.get($font-weights, normal);
        margin-bottom: map.get($spacing, s-8);
        line-height: 24px;
        cursor: pointer;

        @include breakpoint(tablet-small) {
            font-size: 20px;
        }

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-16);
        }
    }

    &__heading {
        display: none;
        cursor: pointer;

        @include breakpoint(tablet-small) {
            display: block;
            margin-bottom: 16px;
            text-transform: uppercase;
            color: var(--03_Neutral-500, #97979F);
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.32px;
        }

        @include breakpoint(desktop) {
            margin-bottom: 24px;
        }
    }

    &__heading-content {
        margin-bottom: 16px;
        text-transform: uppercase;
        color: var(--03_Neutral-500, #97979F);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.32px;
        cursor: pointer;

        @include breakpoint(desktop) {
            margin-bottom: 24px;
        }
    }

    &__heading-article {
        margin-bottom: 0;
    }

    &__arrow {
        display: inline-block;
        margin-top: 8px;
        height: 24px;
        bottom: 0%;
        cursor: pointer;

        @include breakpoint(tablet-small) {
            height: 32px;
        }

        &__text {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            /* 150% */
            letter-spacing: -0.32px;
            margin-left: 8px;
            position: relative;
            bottom: 10px;
        }

        &__warm {
            color: var(--color-warm-500);

            &:hover {
                color: var(--color-warm-300);
                text-decoration: none;
            }

            & svg {
                fill: currentColor;
            }
        }

        &__cold {
            color: var(--color-cold-500);

            &:hover {
                color: var(--color-cold-300);
                text-decoration: none;
            }

            & svg {
                fill: currentColor;
            }
        }

    }

    &__color {
        height: 24px;

        &__circle {
            display: inline-block;
            width: map.get($spacing, s-24);
            height: map.get($spacing, s-24);
            border-radius: 30px;
            border: 1px solid var(--03_Neutral-400, #E5E5E5);
        }

        &__text {
            color: var(--03_Neutral-600, #3B3B40);
            display: inline-block;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            /* 150% */
            letter-spacing: -0.56px;
            position: relative;
            bottom: 7px;
            margin-left: 8px;
        }
    }

    &__description {
        color: var(--color-neutral-500);
        margin-top: 0px;
        margin-bottom: map.get($spacing, s-8);
        font-size: 12px;
        cursor: pointer;

        @include breakpoint(tablet-small) {
            font-size: 16px;
        }

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-16);
        }
    }

    &--rendition-small {
        column-gap: map.get($spacing, s-16);
        grid-template-columns: repeat(2, 1fr);
        row-gap: map.get($spacing, s-32);

        @include breakpoint(tablet-small) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include breakpoint(tablet) {
            row-gap: 54px;
        }

        @include breakpoint(desktop) {
            column-gap: map.get($spacing, s-24);
            row-gap: 80px;
            grid-template-columns: repeat(4, 1fr);
        }

        .cmp-image {
            --aspect-ratio: #{map.get($aspect-ratios, '3-4')};
        }
    }

    &__no-results {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        margin: 3rem 0;

        @include breakpoint(tablet-small) {
            font-size: 20px;
            margin: 3rem 0 4.5rem 0;
        }

        @include breakpoint(desktop) {
            margin: 4.5rem 0;
        }

        color: var(--color-neutral-500, #97979F);

        &__title {
            color: var(--color-cold-600, #30303F);
        }

        & ul {
            list-style: inside;

            & li {
                margin-left: 10px;
            }
        }
    }
}


.search-faqs--short .cmp-accordion__item:nth-child(n + 4) {
    display: none !important;
}

.general-search-faq-foldout {

    .cmp-accordion__item {
        cursor: pointer;
    }

    .cmp-accordion__header,
    .cmp-accordion__button,
    .cmp-accordion__panel {
        pointer-events: none;
    }

    .cmp-accordion__icon.open {
        mask-image: var(--svg-minus);
    }

    .faq-showmore {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: #97979F;
        cursor: pointer;
        margin-top: 2rem;
        display: flex;
        align-items: center;

        &__text {
            text-decoration: underline;
        }

        &__icon {
            background-color: #F07300;
            mask-image: var(--svg-chevron);
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-left: 8px;
            transform: rotate(90deg);
            transition: transform 0.3s;
        }

        &__text--less {
            display: none;
        }

        &--less {
            .faq-showmore__icon {
                transform: rotate(-90deg);
            }
            .faq-showmore__text {
                display: none;
            }
            .faq-showmore__text--less {
                display: inline-block;
            }
        }

    }

}