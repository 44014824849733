@use 'sass:map';

.cmp-outro-block {
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-24);
    position: relative;

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-32);
    }

    @include breakpoint(desktop) {
        padding-bottom: map.get($spacing, s-96);
        padding-top: map.get($spacing, s-48);
    }

    &::before {
        background-color: var(--color-neutral-400);
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        @include breakpoint(tablet) {
            max-width: rem(540);
        }

        @include breakpoint(desktop) {
            max-width: rem(660);
        }
    }

    &__container {
        @include breakpoint(tablet) {
            max-width: rem(430);
        }
    }

    &__description {
        color: var(--color-neutral-500);

        @include font-size-per-breakpoint(body-m);
    }

    &__title {
        margin-bottom: map.get($spacing, s-16);

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-24);
        }

        .cmp-title__text {
            font-weight: map.get($font-weights, normal);

            @include font-size-per-breakpoint(h4);
        }
    }


    &__button {
        margin-top: map.get($spacing, s-24);

        @include breakpoint(tablet) {
            margin-top: map.get($spacing, s-32);
        }
    }

    // Default color
    .has-button {
        .cta-button {
            --color-bg: var(--color-cold-500);
            --color-bg-hover: var(--color-cold-300);
            --color-text: var(--color-neutral-200);
            --color-text-hover: var(--color-text);
        }

        &.has-button--style-warm {
            .cta-button {
                --color-bg: var(--color-warm-500);
                --color-bg-hover: var(--color-warm-300);
            }
        }
    }

    // Decrease whitespace between outro block and its preceding component for certain components.
    .content-carousel + .outro-block &,
    .mono-banner + .outro-block & {
        margin-top: -#{map.get($spacing, s-16)}; // 32px

        @include breakpoint(tablet) {
            margin-top: -#{map.get($spacing, s-24)}; // 48px
        }

        @include breakpoint(desktop) {
            margin-top: -#{map.get($spacing, s-48)}; // 48px
        }
    }

    .manual-list + .outro-block & {
        margin-top: -#{map.get($spacing, s-16)}; // 32px

        @include breakpoint(tablet) {
            margin-top: -#{map.get($spacing, s-8)}; // 48px
        }
    }
}
