@import '../utilities/mixins/aem-grid';

body {
    font-family: sans-serif;
    overflow-x: hidden;
}

$max-col: 12;

// Default breakpoint
.aem-Grid {
    @include generate-grid(default, $max-col);
}

// Phone breakpoint
@include breakpoint(max-width, 768px) {
    .aem-Grid {
        @include generate-grid(phone, $max-col);
    }
}
