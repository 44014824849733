@use 'sass:map';

/*
 * AEM Style System
 *   Policy: ui.content/src/main/content/jcr_root/conf/b2c/settings/wcm/policies/b2c/components/b2c/image/layouts
 *
 * BEM
 *   MODIFIER
 *       cmp-image--position-center-bottom || cmp-image--position-center-center ||  cmp-image--position-center-top
 *       cmp-image--position-left-bottom || cmp-image--position-left-center ||  cmp-image--position-left-top
 *       cmp-image--position-right-bottom || cmp-image--position-right-center ||  cmp-image--position-right-top
 *     BLOCK cmp-image
 *       ELEMENT cmp-image__image
 *       ELEMENT cmp-image__link
 *       ELEMENT cmp-image__title
 */
.cmp-image {
    --aspect-ratio: auto;

    margin-bottom: map.get($spacing, s-16);

    &__image {
        aspect-ratio: var(--aspect-ratio);
        display: block;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__link {
        display: block;

        @include focus {
            border-radius: 2px;
            outline: solid 2px var(--color-link-outline);
        }
    }

    &__title {
        color: var(--color-cold-600);
        display: block;
        margin-top: map.get($spacing, s-16);

        @include font-size-per-breakpoint(body-m);

        @include breakpoint(tablet) {
            max-width: 50%;
        }
    }

    &--position {
        $object-positions: (
            'center-bottom': center bottom,
            'center-center': center center,
            'center-top': center top,
            'left-bottom': left bottom,
            'left-center': left center,
            'left-top': left top,
            'right-bottom': right bottom,
            'right-center': right center,
            'right-top': right top,
        );

        @each $class-part, $object-position in $object-positions {
            &-#{$class-part} {
                .cmp-image {
                    &__image {
                        object-position: $object-position;
                    }
                }
            }
        }
    }

    &--has-gradient {
        .cmp-image {
            position: relative;

            &::after {
                background: linear-gradient(#0003 10%, #e5e5e51a 40%, #0000 50%);
                content: '';
                inset: 0;
                position: absolute;
            }
        }
    }
}
