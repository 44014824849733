@use 'sass:map';

.cmp-wide-content-tabs {
    padding-bottom: map.get($spacing, s-32);

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-48);
    }

    &__title {
        margin-bottom: map.get($spacing, s-32);
        padding-top: map.get($spacing, s-72);
    }

    &__tab {
        padding-top: map.get($spacing, s-32);

        @include breakpoint(tablet-small) {
            padding-top: map.get($spacing, s-48);
        }

        .cmp-image {
            margin-bottom: 0;
        }
    }

    &__tab-content {
        margin-bottom: map.get($spacing, s-24);
        max-width: rem(512);

        @include breakpoint(tablet) {
            margin-bottom: map.get($spacing, s-48);
        }

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-72);
        }

        .cmp-title__text {
            margin-bottom: map.get($spacing, s-8);

            @include font-size-per-breakpoint(body-xl);

            @include breakpoint(tablet-small) {
                margin-bottom: map.get($spacing, s-12);
            }

            @include breakpoint(desktop) {
                margin-bottom: map.get($spacing, s-16);
            }
        }
    }

    .cmp-tabs {
        .cmp-text {
            color: var(--color-neutral-500);

            @include font-size-per-breakpoint(body-m);
        }

        .cmp-button {
            margin-top: map.get($spacing, s-8);
            padding-left: 0;
        }
    }

    .cta-button {
        --color-text: var(--color-cold-500);
        --color-text-hover: var(--color-cold-300);
    }

    &--link-color-warm {
        .cmp-tabs {
            --tab-border-color-active: var(--color-warm-500);
            --tab-border-color-hover: var(--color-warm-300);
            --tab-swipe-icon-color: var(--color-warm-500);
            --tab-swipe-icon-color-hover: var(--color-warm-300);
        }

        .cta-button {
            --color-text: var(--color-warm-500);
            --color-text-hover: var(--color-warm-300);
        }
    }

    .background--color-grey & {
        .cmp-tabs {
            --tab-swipe-background-color: var(--color-neutral-300);
        }
    }
}
