@use 'sass:map';

.cmp-quote {
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-24);

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-48);
    }

    @include breakpoint(desktop) {
        padding-bottom: map.get($spacing, s-96);
        padding-top: map.get($spacing, s-72);
    }

    &__text {
        font-weight: map.get($font-weights, light);
        quotes: '“' '”' '‘' '’';

        @include font-size-per-breakpoint(h2);

        &::after {
            content: close-quote;
        }

        &::before {
            content: open-quote;
        }
    }

    &__source {
        margin-top: map.get($spacing, s-16);

        @include breakpoint(tablet-small) {
            margin-top: map.get($spacing, s-24);
        }
    }
}
