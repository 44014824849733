/*
* ADOBE CONFIDENTIAL
*
* Copyright 2020 Adobe Systems Incorporated
* All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
*/

/* stylelint-disable selector-class-pattern */

/* grid component */
.aem-Grid {
    display: block;
    width: 100%;
}

.aem-Grid::before,
.aem-Grid::after {
    content: ' ';
    display: table;
}

.aem-Grid::after {
    clear: both;
}

/* placeholder for new components */
.aem-Grid-newComponent {
    clear: both;
    margin: 0;
}

/* column of a grid */
.aem-GridColumn {
    box-sizing: border-box;
    clear: both;
}

/* force showing hidden */
.aem-GridShowHidden > .aem-Grid > .aem-GridColumn {
    display: block !important;
}

/* force showing hidden components in unhide mode */
.aem-GridShowHidden > .cmp-container > .aem-Grid > .aem-GridColumn {
    display: block !important;
}
