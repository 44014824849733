@use 'sass:map';

.compare-flyout {
    background-color: var(--color-neutral-200);
    bottom: 0;
    box-shadow: 0 20px 52px rgba(0, 0, 0, 40%);
    display: none;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: var(--zindex-compare-flyout);

    &[data-is-active] {
        animation: slideInUp 0.3s 0.1s both;
        display: block;
    }

    &[data-closing] {
        animation: slideOutDown 0.3s both;
    }

    &__header {
        border-bottom: 1px solid var(--color-neutral-400);
        display: flex;
        margin: 0 calc(var(--container-padding) * -1);
        padding: map.get($spacing, s-24) var(--container-padding);
        position: relative;

        @include breakpoint(desktop) {
            display: none;
        }

        button {
            appearance: none;
            background-color: var(--color-neutral-200);
            border: none;
            cursor: pointer;
            padding: 0;

            &::before {
                border-left: 1px solid var(--color-neutral-400);
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                width: rem(75);

                @include breakpoint(tablet) {
                    border-right: 1px solid var(--color-neutral-400);
                    right: var(--container-padding);
                }
            }

            &::after {
                background: var(--color-cold-500);
                content: '';
                display: inline-block;
                height: rem(24);
                mask-image: var(--svg-chevron);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: absolute;
                right: rem(24);
                top: 50%;
                transform: translateY(-50%) rotate(90deg);
                transform-origin: center center;
                transition: transform 0.15s linear;
                vertical-align: middle;
                width: rem(24);

                @include breakpoint(tablet) {
                    right: calc(var(--container-padding) + rem(24));
                }

                @media (forced-colors: active) {
                    color: ButtonText;
                    forced-color-adjust: none;
                }
            }

            &:hover {
                &::after {
                    transform: translateY(-50%) rotate(-90deg);
                }
            }

            @include focus {
                &::before {
                    outline: 3px solid var(--color-neutral-700);
                    outline-offset: 3px;
                }

                &::after {
                    transform: translateY(-50%) rotate(-90deg);
                }
            }

            &[aria-expanded='true'] {
                &:hover {
                    &::after {
                        transform: translateY(-50%) rotate(90deg);
                    }
                }

                @include focus {
                    &::before {
                        outline: 3px solid var(--color-neutral-700);
                        outline-offset: 3px;
                    }

                    &::after {
                        transform: translateY(-50%) rotate(90deg);
                    }
                }

                &::after {
                    transform: translateY(-50%) rotate(-90deg);
                }
            }
        }
    }

    &__body {
        display: none;

        &.js-is-open {
            display: block;
        }

        @include breakpoint(desktop) {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }

    &__cta {
        margin-bottom: map.get($spacing, s-48);
        margin-top: map.get($spacing, s-32);

        @include breakpoint(tablet-small) {
            max-width: rem(365);
        }

        @include breakpoint(desktop) {
            margin-bottom: 0;
            margin-top: 0;
        }

        .cta-button {
            --color-bg: var(--color-cold-500);
            --color-bg-hover: var(--color-cold-300);
            --color-text: var(--color-neutral-200);
            --color-text-hover: var(--color-text);

            transition: background-color 0.3s linear, color 0.15s linear;
            width: 100%;
        }
    }

    &__title {
        display: none;

        @include breakpoint(desktop) {
            display: block;
        }
    }

    &__slots {
        @include breakpoint(desktop) {
            display: flex;
            flex: 0 0 auto;
        }
    }

    &__slot-visual {
        background-color: var(--color-neutral-300);
        height: rem(56);
        margin-right: map.get($spacing, s-16);
        overflow: hidden;
        width: rem(56);

        @include breakpoint(desktop) {
            flex: 0 0 rem(90);
            height: rem(90);
            margin-right: map.get($spacing, s-24);
            width: rem(90);
        }

        img {
            height: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }
    }

    &__slot {
        align-items: center;
        display: flex;
        margin-top: map.get($spacing, s-16);

        @include breakpoint(desktop) {
            flex: 0 0 rem(318);
            margin-top: 0;
            padding: map.get($spacing, s-24);
            width: rem(318);
        }


        &-title {
            @include breakpoint(desktop) {
                margin-right: map.get($spacing, s-24);
            }
        }

        &-remove {
            appearance: none;
            background: transparent;
            border: 1px solid transparent;
            cursor: pointer;
            height: rem(32);
            width: rem(32);

            @include breakpoint(desktop) {
                margin-left: auto;
            }

            &::after {
                background: currentcolor;
                content: '';
                display: inline-block;
                height: rem(24);
                mask-image: var(--svg-close);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: relative;
                transition: inherit;
                vertical-align: middle;
                width: rem(24);

                @media (forced-colors: active) {
                    color: ButtonText;
                    forced-color-adjust: none;
                }
            }
        }

        & + & {
            border-left: 1px solid #e5e5e5;
        }
    }

    @media (prefers-reduced-motion: no-preference) {
        .compare-flyout {
            // animation: fadeIn 0.3s 0.1s both, slideInUp 0.3s 0.1s both;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideInUp {
    0% {
        display: none;
        opacity: 0;
        transform: translateY(100%);
    }

    1% {
        display: block;
        opacity: 1;
        transform: translateY(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutDown {
    0% {
        display: block;
        opacity: 1;
        transform: translateY(0);
    }

    99% {
        opacity: 1;
        transform: translateY(100%);
    }

    100% {
        display: none;
        opacity: 0;
        transform: translateY(100%);
    }
}
