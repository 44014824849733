@use 'sass:map';

.cmp-button-standalone {

    .cmp-button {
        margin-bottom: map.get($spacing, s-16);
        margin-top: map.get($spacing, s-16);
    
        @include breakpoint(tablet) {
            margin-bottom: map.get($spacing, s-24);
            margin-top: map.get($spacing, s-24);
        }
    
        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-32);
            margin-top: map.get($spacing, s-32);
        }
    }

    & + & {
        .cmp-button {
            margin-top: 0;
        }
    }

}