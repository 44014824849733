@mixin image-layout($type, $position) {
    .cmp-image--#{$type}-#{$position} {
        img {
            object-position: $position;
        }
    }
}

.cmp-list {
    list-style: none;

    .cmp-teaser {
        &__image {
            .cmp-image {
                height: 150px;
                width: 150px;
            }
        }
    }

    &__item {
        display: inline-block;
    }

    &__item-link {
    }

    &__item-title {
    }

    &__item-date {
    }

    &--image-portrait {
        .cmp-image {
            height: 300px;
            width: 200px;
        }

        @include image-layout(portrait, top);
    }

    &--image-landscape {
        .cmp-image {
            height: 180px;
            width: 320px;
        }

        @include image-layout(landscape, top);
    }

    &--image-square {
        .cmp-list {
            &__item {
                height: 250px;
                width: 250px;
            }
        }

        .cmp-image {
            height: 250px;
            width: 250px;
        }

        @include image-layout(square, top);
        @include image-layout(square, left);
        @include image-layout(square, bottom);
        @include image-layout(square, right);
        @include image-layout(square, center);
    }
}
