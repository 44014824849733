@use 'sass:map';

.cmp-sticky-nav__wrapper {
    --sticky-nav-height: 76px;
    height: var(--sticky-nav-height);
    position: fixed;
    bottom: env(safe-area-inset-bottom, 0);
    left: 0;
    right: 0;
    z-index: var(--zindex-sticky-navigation);
    background-color: #ffffff;
    box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(100%);
    transition: transform 0.3s 0.1s;

    &--show {
        transform: translateY(0);
    }

    @include breakpoint(desktop) {
        --sticky-nav-height: 104px;
    }

    .container-max {
        height: 100%;
    }

    .has-button-link--style-warm {
        .cta-button {
            --color-text: var(--color-warm-500);
            --color-text-hover: var(--color-warm-300);
        }
    }

    .cmp-sticky-nav {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        height: 100%;
        overflow: hidden;

        .sticky-nav__item {
            .cmp-to-parent-link, .cmp-title {
                padding: 0;
                margin: 0;
            }
        }

        .sticky-nav__to-parent-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-right: 1px solid #e9e9e9;

            a {
                height: var(--sticky-nav-height);;
            }

            .cmp-to-parent-link__link,
            .cmp-product-series-header__backlink {
                display: flex;
                flex-basis: auto;
                width: var(--sticky-nav-height);
                align-items: center; 
                justify-content: center;
                

                .cmp-to-parent-link__text {
                    display: none;
                }

                .cmp-to-parent-link__link {
                  
                }
            }

            &--warm {
                .cmp-to-parent-link__link::before {
                    --color-icon: var(--color-warm-500);
                }
            }
        }

        .sticky-nav__title {
            display: none;
            flex-direction: row;
            align-items: center;
            // flex: 1;
            padding-left: 48px;
            
            .cmp-title__text,
            .cmp-product-series-header__name {
                margin-top: 0;
                --font-size: 1.25rem;
                --line-height: 1.5rem;
                font-weight: 600;

                @include breakpoint(desktop) {
                    --font-size: 1.25rem;
                    --line-height: 1.5rem;
                }

            }

            @include breakpoint(desktop) {
                display: flex;
            }
        }

        .sticky-nav__secondary-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-basis: auto;
            padding-left: 32px;
            padding-right: 40px;
            display: none;

            .cta-button {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                margin: 0;
                --color-text: var(--color-cold-500);
                --color-text-hover: var(--color-cold-300);
            }

            &.has-button-link--style-warm {
                .cta-button {
                    --color-text: var(--color-warm-500);
                    --color-text-hover: var(--color-warm-300);
                    --color-bg: transparent; 
                    --color-bg-hover: transparent;
                }
            }

            @include breakpoint(desktop) {
                display: flex;
            }
        }

        .sticky-nav__primary-button {
            display: flex;
            flex-direction: row;
            justify-content: end;
            align-items: center;
            flex-basis: auto;
            text-align: end;

            .cta-button {
                margin: 0;
                --color-bg: var(--color-cold-500);
                --color-bg-hover: var(--color-cold-300);
                --color-text: var(--color-neutral-200);
                --color-text-hover: var(--color-text);
            }

            @media screen and (width < 1195px) {
                // flex: 1;
            }
        }

        .primary-button.has-button--style-warm {
            .cta-button {
                --color-bg: var(--color-warm-500);
                --color-bg-hover: var(--color-warm-300);
                --color-text: var(--color-neutral-200);
                --color-text-hover: var(--color-text);
            }
        }

    }
    
}


body:has(.compare-flyout[data-is-active]) {

    .cmp-sticky-nav__wrapper {
        transform: translateY(100%);
    }
    
}

/*
.cmp-sticky-nav__wrapper--product-series-header .sticky-nav__secondary-button:has(+ .sticky-nav__primary-button)  {
    & + .sticky-nav__primary-button {
        flex: 0;
    }
    flex: 1;
    a {
        text-align: right;
        display: block !important;
    }
}
    */


// Mobile = To parent link + Primary button
.sticky-nav__title {

}
.sticky-nav__secondary-button {

}
.sticky-nav__primary-button {
    flex: 1;
    overflow-x: hidden;
    padding-left: 32px;
    .cmp-button {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        .cmp-button__text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}



@include breakpoint(tablet) {
    .sticky-nav__title {

    }
    .sticky-nav__secondary-button {

    }
    .sticky-nav__primary-button {

    }
}

@include breakpoint(desktop) {
    .sticky-nav__title {
        flex: 1;
        .cmp-title__text,
        .cmp-product-series-header__name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .sticky-nav__secondary-button {
        flex: 0;
        .cmp-button {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            .cmp-button__text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .sticky-nav__primary-button {
        flex: 0;
    }
}