// Generic swiper styles
.swiper {
    list-style: none;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 0;
    position: relative;
    z-index: 1; /* Fix of Webkit flickering */
}

.swiper-wrapper {
    box-sizing: content-box;
    display: flex;
    height: 100%;
    padding-bottom: 5px;
    padding-top: 5px;
    position: relative;
    transition-property: transform;
    width: 100%;
    will-change: transform;
    z-index: 1;
}

.swiper-android .swiper-slide,
.swiper-wrapper {
    transform: translate3d(0, 0, 0);
}

.swiper-pointer-events {
    touch-action: pan-y;

    &.swiper-vertical {
        touch-action: pan-x;
    }
}

.swiper-slide {
    flex-shrink: 0;
    height: 100%;
    position: relative;
    transition-property: transform;
    width: 100%;
}

.swiper-slide-invisible-blank {
    visibility: hidden;
}

.swiper-fade {
    .swiper-slide {
        pointer-events: none;
        transition-property: opacity;

        .swiper-slide {
            pointer-events: none;
        }
    }

    .swiper-slide-active {
        &,
        .swiper-slide-active {
            pointer-events: auto;
        }
    }
}
