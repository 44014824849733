@use 'sass:map';

$navigation-mobile-intermediate-breakpoint: rem(500);


body {
    &.js-mobile-menu-open {
        position: fixed;
        width: 100%;
    }
}

.navigation-mobile {
    --container-padding: #{map.get($spacing, s-24)};

    background-color: var(--color-background);
    height: calc(100vh - var(--main-navigation-height));
    inset: 0;
    margin-top: var(--main-navigation-height);
    padding-left: var(--container-padding);
    padding-right: var(--container-padding);
    position: fixed;

    &__content {
        border-top: 1px solid var(--color-neutral-400);
        height: calc(100dvh - var(--main-navigation-height));
        overflow-x: hidden;
        overflow-y: scroll;
        padding-top: map.get($spacing, s-48);
        width: calc(100vw - var(--container-padding) * 2);
    }

    &__menu {  
        margin-bottom: 22px;
        
        @include breakpoint(min-width, #{$navigation-mobile-intermediate-breakpoint}) {
            max-width: 50%;
            margin-bottom: 40px;
        }

        &:target {
            display: flex;
            flex-direction: column;
            position: relative;
        }

        &:not(:target) {
            display: none;
        }
    }

    &__list {
        order: 2;
        padding-top: map.get($spacing, s-32);
        &__site{
            border-top: 1px solid var(--color-neutral-400);
            margin-top: 22px;
            @include breakpoint(min-width, #{$navigation-mobile-intermediate-breakpoint}) {
                margin-top: 40px;
            }
        }
        
    }
    &__sublist{
        li.navigation-submenu__item--highlighted:last-of-type a{
            margin-top: 2rem;
            padding-top: 2rem;
            margin-right: 1.5rem;
			font-weight: map.get($font-weights, semibold);
			font-size: 1.25rem;
            line-height: 1.5rem;
            border-top: 1px solid var(--color-neutral-400);
            &::before {
                background-color: var(--color-neutral-700);
                content: '';
                display: inline-block;
                // height: rem(24);
                margin-right: map.get($spacing, s-12);
                mask-image: var(--svg-arrow);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                vertical-align: top;
                width: rem(24);
            }
            span{
                padding: 1rem 0;
            }
		}
    }

    &__item{
        margin-bottom: map.get($spacing, s-32);

        &--segments {
            margin-top: auto;

            @include breakpoint(min-width, #{$navigation-mobile-intermediate-breakpoint}) {
                margin-top: map.get($spacing, s-72);
            }

            .navigation-mobile__link {
                border-top: 1px solid var(--color-neutral-400);
                color: var(--color-cold-500);
                padding-top: map.get($spacing, s-32);

                &:hover {
                    color: var(--color-neutral-700);
                }
            }
        }
    }

    &__button {
        background-color: var(--color-background);
        border-style: none;
        cursor: pointer;
        padding: 0;

        @include focus {
            border-radius: 2px;
            outline: 2px solid var(--color-cold-600);
            outline-offset: 6px;
        }

        .navigation-mobile__text {
            color: var(--color-neutral-700);
            font-weight: map.get($font-weights, normal);
            text-decoration: none;

            @include font-size-per-breakpoint(h4);
        }

        &--search {
            padding: map.get($spacing, s-8) map.get($spacing, s-12);
            display: inline-block;

            &--active,
            &:hover {
                color: var(--color-cold-500);
            }
        }
    }

    &__search-icon {
        &::after {
            mask-image: var(--svg-search);
        }
    }

    &__back-link {
       order: 1;

        &--segments {
            margin-top: auto;

            @include breakpoint(min-width, #{$navigation-mobile-intermediate-breakpoint}) {
                margin-top: map.get($spacing, s-72);
            }

            .navigation-mobile__link {
                border-top: 1px solid var(--color-neutral-400);
                color: var(--color-cold-500);
                padding-top: map.get($spacing, s-32);

                &:hover {
                    color: var(--color-neutral-700);
                }
            }
        }
    }

    &__link {
        color: var(--color-neutral-700);
        display: flex;
        font-weight: map.get($font-weights, normal);
        padding: map.get($spacing, s-8) map.get($spacing, s-12);
        text-decoration: none;

        @include font-size-per-breakpoint(h4);

        @include breakpoint(desktop) {
            --font-size: 1.25rem !important;
            --line-height: 1.5rem !important;
        }

        &:hover {
            color: var(--color-cold-500);
        }

        @include focus {
            margin-left: map.get($spacing, s-4);
            margin-right: map.get($spacing, s-4);
        }

        &--arrow::before,
        &--chevron::after {
            background-color: var(--color-cold-500);
            content: '';
            display: inline-block;
            height: rem(24);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            vertical-align: top;
            width: rem(24);
        }

        &--arrow::before {
            margin-right: map.get($spacing, s-12);
            mask-image: var(--svg-arrow);
            transform: rotate(180deg);
        }

        &--chevron::after {
            margin-left: auto;
            mask-image: var(--svg-chevron);
        }

        &--active {
            font-weight: map.get($font-weights, bold);
        }
    }
}
