@use 'sass:map';

$home-hero-mobile-intermediate-breakpoint: rem(450);


.home-hero.cmp-home-hero__commercial {

    .cmp-home-hero {
        @include breakpoint(mobile) {
            aspect-ratio: auto;
        }
        @include breakpoint(tablet) {
            --home-hero-aspect-ratio: #{map.get($aspect-ratios, '21-9')};
        }

    }

    .cmp-image__image {
        object-position: center center;
        object-fit: cover;
    }

    .cmp-title__text {
        font-weight: map.get($font-weights, pluslight);
    }

}


.cmp-home-hero {
    --home-hero-aspect-ratio: none;
    color: var(--color-neutral-200);

    .description.text {
        font-weight: map.get($font-weights, pluslight);
    }

    @include breakpoint(tablet) {
        --home-hero-aspect-ratio: #{map.get($aspect-ratios, '16-9')};
    }

    &__container {
        display: grid;
    }

    &__content,
    &__image {
        grid-column-start: 1;
        grid-row-start: 1;

        @include breakpoint(tablet) {
            aspect-ratio: var(--home-hero-aspect-ratio);
        }
    }

    &__content {
        margin-left: map.get($spacing, s-8);
        margin-right: map.get($spacing, s-8);
        z-index: 1;

        @include breakpoint(tablet-small) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__image {
        min-height: rem(500);

        * {
            height: 100%;
        }

        @include breakpoint(tablet) {
            min-height: initial;

            * {
                height: auto;
            }
        }
    }

    &__video {
        grid-column-start: 1;
        grid-row-start: 1;
        line-height: 0;
        z-index: 1;
        display: none;

        video {
            width: 100%;
            aspect-ratio: var(--home-hero-aspect-ratio);
            object-fit: cover;
        }

        @include breakpoint(tablet) {
            display:block;
        }
    }

    .cmp-image {
        --aspect-ratio: var(--home-hero-aspect-ratio);

        height: 100%;
        margin-bottom: 0;
    }

    .cmp-title__text {
        margin-top: map.get($spacing, s-32);

        @include breakpoint(tablet-small) {
            margin-top: map.get($spacing, s-72);
        }

        @include breakpoint(tablet) {
            width: 50%;
        }

        @include breakpoint(desktop) {
            width: 60%;
        }
    }

    .cmp-text {
        @include font-size-per-breakpoint(h3);

        --line-height: 1.5;

        margin-top: map.get($spacing, s-12);

        @include breakpoint(tablet) {
            margin-top: map.get($spacing, s-8);
            width: 50%;
            --line-height: 44px;
        }

        @include breakpoint(desktop) {
            --font-size: 1.75rem;
        }
    }

    &__button + &__button {
        margin-top: map.get($spacing, s-16);
    }

    &__button {
        display: block;
        
        margin-top: map.get($spacing, s-24);
        width: 100%;

        @include breakpoint(min-width, #{$home-hero-mobile-intermediate-breakpoint}) {
            display: inline-block;
            margin-right: map.get($spacing, s-16);
            width: auto;

            &:last-child {
                margin-right: 0;
            }
        }

        @include breakpoint(desktop) {
            margin-right: map.get($spacing, s-24);
        }

        .cta-button {
            width: 100%;
        }

        &--primary {
            .cta-button {
                --color-bg: var(--color-cold-500);
                --color-bg-hover: var(--color-cold-300);
                --color-text: var(--color-neutral-200);
                --color-text-hover: var(--color-text);
            }

            &.has-button--style-warm {
                .cta-button {
                    --color-bg: var(--color-warm-500);
                    --color-bg-hover: var(--color-warm-300);
                    --color-text: var(--color-neutral-200);
                    --color-text-hover: var(--color-text);
                }
            }
        }

        &--secondary {
            .cta-button {
                --color-bg: var(--color-neutral-200);
                --color-text: var(--color-neutral-600);
                --color-text-hover: var(--color-cold-500);
            }

            &.has-button--style-grey {
                .cta-button {
                    --color-bg: var(--color-neutral-300);
                    --color-text: var(--color-neutral-600);
                    --color-text-hover: var(--color-cold-500);
                }
            }
        }
    }
}
