@use 'sass:map';

.cmp-wide-content-carousel {
    padding-bottom: map.get($spacing, s-32);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-48);
        padding-top: map.get($spacing, s-72);
    }

    @include breakpoint(desktop) {
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-96);
    }

    .carousel__wrapper {
        margin-top: map.get($spacing, s-16);

        @include breakpoint(tablet) {
            margin-top: map.get($spacing, s-48);
        }
    }

    .swiper-slide {
        width: rem(270);

        @include breakpoint(tablet) {
            width: rem(310);
        }

        @include breakpoint(desktop) {
            width: rem(430);
        }
    }

    .carousel-card {
        &:hover {
            .carousel-card__title {
                color: var(--text-color);
            }
        }

        &__visual {
            @include breakpoint(desktop) {
                margin-bottom: map.get($spacing, s-32);
            }
        }

        &__title {
            --color-icon: var(--color-cold-500);

            font-weight: map.get($font-weights, normal);
            margin-bottom: map.get($spacing, s-24);

            @include font-size-per-breakpoint(h4);

            &::after {
                background-color: var(--color-icon);
                content: '';
                display: inline-block;
                height: rem(26);
                margin-left: map.get($spacing, s-12);
                mask-image: var(--svg-arrow);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                vertical-align: middle;
                width: rem(26);
            }
        }

        &__text {
            @include font-size-per-breakpoint(body-m);

            color: var(--color-neutral-500);
        }
    }

    .carousel--style-warm & {
        &:hover {
            .carousel-card__title {
                --text-color: var(--color-warm-500);
            }
        }

        .carousel-card__title {
            --color-icon: var(--color-warm-500);
        }
    }

    .carousel--style-cold & {
        &:hover {
            .carousel-card__title {
                --text-color: var(--color-cold-500);
            }
        }

        .carousel-card__title {
            --color-icon: var(--color-cold-500);
        }
    }
}
