@use 'sass:map';

.cmp-dealer-detail {
    padding-bottom: map.get($spacing, s-24);
    padding-top: map.get($spacing, s-24);

    @include breakpoint(tablet-small) {
        padding-bottom: map.get($spacing, s-48);
        padding-top: map.get($spacing, s-48);
    }

    @include breakpoint(desktop) {
        display: grid;
        gap: 0 map.get($spacing, s-16);
        grid-template-columns: repeat(10, 1fr);
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-72);
    }

    &__title {
        grid-column: 1 / span 8;
        margin-bottom: map.get($spacing, s-24);

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-48);
        }
    }

    &__title-prefix {
        display: block;
    }

    &__intro {
        grid-column: 1 / span 8;

        @include font-size-per-breakpoint(body-m);
    }
}
