@use 'sass:map';

.cmp-mono-image-wide {
    margin-bottom: map.get($spacing, s-48);
    margin-top: map.get($spacing, s-48);

    .cmp-image {
        --aspect-ratio: #{map.get($aspect-ratios, '16-9')};
    }
}
