@use 'sass:map';

:root {
    --max-reading-width: #{rem(888)};
}

.container-bleed {
    @include container-bleed;
}

.container-content {
    @include container-content;
}

.container-max {
    @include container-max;
}
