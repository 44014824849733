@use 'sass:map';

.cmp-product-series-comparison {
    color: var(--color-neutral-500);
    display: block;
    padding-bottom: map.get($spacing, s-24);

    @include font-size-per-breakpoint(body-m);

    &__section {
        &--summary {
            @include font-size-per-breakpoint(h3);

            .cmp-product-series-comparison__value {
                font-weight: map.get($font-weights, light);
            }
        }
    }

    &__section-title {
        color: var(--color-cold-600);
        font-weight: map.get($font-weights, normal);
        margin: 0;
        padding: map.get($spacing, s-16) 0;
        text-transform: uppercase;

        @include font-size-per-breakpoint(h5);

        @include breakpoint(tablet) {
            padding-bottom: map.get($spacing, s-24);
            padding-top: map.get($spacing, s-24);
        }
    }

    &__product-title {
        color: var(--color-cold-600);
        font-weight: map.get($font-weights, normal);
        padding: 0 0 map.get($spacing, s-24);

        &:first-child {
            padding-right: rem(6);
        }

        &:last-child {
            padding-left: rem(6);
        }
    }
    &__subsection-color-title {
        color: var(--color-cold-600);
        font-weight: map.get($font-weights, normal);
        margin: 0;

        @include font-size-per-breakpoint(body-m);
    }

    &__subsection-title {
        color: var(--color-cold-600);
        font-weight: map.get($font-weights, normal);
        margin: 0 0 map.get($spacing, s-8);

        @include font-size-per-breakpoint(body-m);

        @include breakpoint(tablet) {
            margin-bottom: map.get($spacing, s-16);
        }
    }

    &__row-ellipsible-trigger {
        --svg-collapsible-icon-image: var(--svg-minus);

        background: none;
        border: none;
        cursor: pointer;
        padding-left: map.get($spacing, s-24);
        position: relative;

        &::before {
            background-color: var(--color-cold-500);
            content: '';
            height: rem(16);
            left: 0;
            margin-top: rem(-6);
            mask-image: var(--svg-collapsible-icon-image);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            top: 50%;
            transition: mask-image 0.2s ease-in;
            width: rem(16);
        }
    }

    &__row {
        display: grid;
        grid-template-columns: 50% 50%;

        &:not(.background--color-grey) + .cmp-product-series-comparison__row {
            border-top: 1px solid var(--color-neutral-300);
        }

        &--ellipsed {
            .cmp-product-series-comparison {
                &__row-ellipsible-trigger {
                    --svg-collapsible-icon-image: var(--svg-plus);
                }

                &__value {
                    &,
                    & > * {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    & > *:not(:first-child) {
                        display: none;
                    }
                }
            }
        }
    }

    &__cell {
        padding: map.get($spacing, s-16) 0;

        @include breakpoint(tablet) {
            padding-bottom: map.get($spacing, s-24);
            padding-top: map.get($spacing, s-24);
        }

        &:first-child {
            border-right: 1px solid var(--color-neutral-300);
            padding-right: rem(6);
        }

        &:last-child {
            border-left: 1px solid var(--color-neutral-300);
            padding-left: rem(6);
        }

        &--fullspan {
            grid-column: 1 / -1;

            &:first-child {
                border: none;
                padding-right: 0;
            }

            &:last-child {
                border: none;
                padding-left: 0;
            }
        }
    }
    &__color{
        display: block;
        @include breakpoint(tablet-small) {
            display: flex;
        }
    }

    &__value {
        @include breakpoint(tablet) {
            margin-left: map.get($spacing, s-96);
        }

        @include breakpoint(breakpoint) {
            margin-left: map.get($spacing, s-120);
        }
    }
    &__swatches{
        margin-left: 0;
        margin-top: 16px;
        @include breakpoint(tablet-small) {
            margin-left: auto;
            margin-right: 16px;
            margin-top: 0;
        }
    }
    &__description{
        margin-left: 0px;
    }
    &__buttons{
        display: grid;
        row-gap: 16px;
        justify-content: center;
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        @include breakpoint(tablet-small) {
            column-gap: 16px;
            display: inline-flex;
            flex-direction: row;
            margin-bottom: 1.5rem;
        }
        @include breakpoint(tablet) {
            margin-top: 3rem;
            margin-bottom: 2rem;
        }
    }
    &__button-individual{
        margin: auto;
        @include breakpoint(tablet-small) {
            margin: 0;
        }
    }

    .product-series-gallery {
        background-color: transparent;
        margin-bottom: map.get($spacing, s-16);
        margin-top: -(map.get($spacing, s-16));

        @include breakpoint(tablet) {
            margin-top: -(map.get($spacing, s-24));
        }

        .cmp-image {
            --aspect-ratio: #{map.get($aspect-ratios, '4-3')};
        }
    }
}

@media print {
    .cmp-product-series-comparison {
        &__row-ellipsible-trigger {
            padding-left: 0;

            &::before {
                display: none;
            }
        }

        &__row {
            &--ellipsed {
                .cmp-product-series-comparison {
                    &__value {
                        &,
                        & > * {
                            overflow: initial;
                            text-overflow: initial;
                            white-space: initial;
                        }

                        & > *:not(:first-child) {
                            display: initial;
                        }
                    }
                }
            }
        }

        .product-series-gallery {
            border: none;
            overflow: hidden;

            &__nav,
            &__pagination {
                display: none;
            }

            .cmp-image {
                max-height: rem(210);
                max-width: rem(280);
            }
        }
    }
}
