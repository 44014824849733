@use 'sass:map';

.has-button {
    .cta-button {
        --button-padding: #{map.get($spacing, s-12) map.get($spacing, s-24)};
        --color-bg: var(--color-neutral-300);
        --color-bg-hover: var(--color-bg);
        --color-focus-outline: var(--color-cold-600);
        --color-text: var(--color-neutral-600);
        --color-text-hover: var(--color-cold-500);
        --line-height: #{map.get($spacing, s-24)};

        @include breakpoint(desktop) {
            --button-padding: #{map.get($spacing, s-16) map.get($spacing, s-32)};
        }

        background-color: var(--color-bg);
        border-radius: rem(30);
        border-style: none;
        color: var(--color-text);
        cursor: pointer;
        display: inline-block;
        font-weight: map.get($font-weights, semibold);
        line-height: var(--line-height);
        padding: var(--button-padding);
        text-align: center;
        text-decoration: none;

        @include font-size-per-breakpoint(button);

        &__icon {
            display: none;
        }

        &:hover {
            background-color: var(--color-bg-hover);
            color: var(--color-text-hover);
        }

        @include focus {
            outline: 2px solid var(--color-focus-outline);
            outline-offset: 6px;
        }

        &:disabled,
        &[disabled] {
            --color-bg: var(--color-neutral-400);
            --color-bg-hover: var(--color-bg);
            --color-text: var(--color-neutral-500);
            --color-text-hover: var(--color-text);

            cursor: not-allowed;
        }
    }

    &--style {
        &-cold {
            .cta-button {
                --color-bg: var(--color-cold-500);
                --color-bg-hover: var(--color-cold-300);
                --color-text: var(--color-neutral-200);
                --color-text-hover: var(--color-text);
            }
        }

        &-dia {
            .cta-button {
                --color-bg: rgba(255, 255, 255, 10%);
                --color-bg-hover: var(--color-bg);
                --color-focus-outline: var(--color-neutral-200);
                --color-text: var(--color-neutral-200);
                --color-text-hover: var(--color-text);
            }
        }

        &-white {
            .cta-button {
                --color-bg: var(--color-neutral-200);
                --color-text: var(--color-neutral-600);
                --color-text-hover: var(--color-cold-500);
            }
        }

        &-grey {
            .cta-button {
                --color-bg: var(--color-neutral-300);
                --color-text: var(--color-neutral-600);
                --color-text-hover: var(--color-cold-500);
            }
        }

        &-warm {
            .cta-button {
                --color-bg: var(--color-warm-500);
                --color-bg-hover: var(--color-warm-300);
                --color-text: var(--color-neutral-200);
                --color-text-hover: var(--color-text);
            }
        }
    }
}

.button__caption {
    container-type: inline-size;
    container-name: caption;
    margin-top: 8px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: -0.035em;
    color: #3B3B40;
    text-align: center;

    &--multiline {
        display: block;
        text-align: left;
    }
 
    @include breakpoint(tablet) {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        letter-spacing: -0.035em;
    }

    @include breakpoint(desktop) {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: -0.035em;
    }

    @container caption (min-height: 25px) {
        .button__caption__inner {
            color: red !important;
        }
    }
}

.button__wrapper {
    display: table;

    .button__caption {
        display: table-caption;
        caption-side: bottom;
    }
}

// only for mobile
@media screen and (max-width: 767px) {
    .button__caption {
        margin-top: 0;
    }
}

.cmp-standard-header__content .cmp-standard-header__button.primary-button {
    display: table;
}

@media screen and (max-width: 767px) {
    .cmp-standard-header__content:has(.button__caption) {
        .cmp-standard-header__button--primary {
            width: 100%;
        }
        .cmp-standard-header__button .cta-button {
            display: block;
            max-width: none;
        }
        .button__caption {
            max-width: none !important;
        }
    }
}

.cmp-standard-header__content .button__caption {
    display: table-caption;
    caption-side: bottom;
}

@media screen and (max-width: 767px) {
    .cmp-standard-header__content:has(.button__caption) .cmp-standard-header__button.primary-button .cta-button {
        margin-bottom: 8px; 
    }
}

@include breakpoint(desktop) {
    .cmp-standard-header:has(.button__caption):not(:has(.cmp-image)) {
        .cmp-text,
        .secondary-button {
            margin-bottom: var(--captionHeight) !important;
        }
    }
}