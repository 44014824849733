@mixin card-clickable-link-pseudo-element($pseudo-element-after: true) {
    $pseudo-element: '::before';

    @if $pseudo-element-after {
        $pseudo-element: '::after';
    }

    &#{$pseudo-element} {
        content: '';

        @include position-absolute;
    }
}

@mixin card-clickable-link-containment-block {
    position: relative;
}
