@use 'sass:map';

.cmp-product-series-header {
    --product-series-header-icon-color: var(--color-cold-500);
    --color-swatch-indicator-color: var(--color-cold-500);

    color: var(--color-neutral-500);
    padding: map.get($spacing, s-24) 0 map.get($spacing, s-48);

    @include breakpoint(tablet) {
        padding: map.get($spacing, s-32) 0 map.get($spacing, s-48);
    }

    @include breakpoint(tablet) {
        padding: map.get($spacing, s-48) 0 map.get($spacing, s-72);
    }

    &__content {
        @include font-size-per-breakpoint(body-m);

        @include breakpoint(tablet) {
            display: grid;
            gap: map.get($spacing, s-16);
            grid-template-columns: repeat(12, [col-start] 1fr);
            grid-template-rows: auto 1fr;
        }
    }

    &__content-collection-1,
    &__content-collection-2 {
        @include breakpoint(tablet) {
            align-items: start;
            grid-column: col-start 8 / span 5;
        }
    }

    &__backlink {
        color: var(--color-neutral-700);
        font-size: rem(16);
        font-weight: map.get($font-weights, normal);
        text-decoration: none;

        @include focus {
            outline: 2px solid var(--color-cold-600);
            outline-offset: 6px;
        }

        &-icon {
            path {
                fill: var(--product-series-header-icon-color, var(--color-cold-500));
            }
        }

        &-text {
            margin-left: map.get($spacing, s-12);
        }
    }

    &__name {
        color: var(--color-cold-600);
        margin-top: map.get($spacing, s-24);
    }

    &__set {
        display: inline-block;
        margin-right: map.get($spacing, s-16);
        margin-top: map.get($spacing, s-4);
    }

    &__included-link {
        color: var(--color-neutral-500);
        font-weight: map.get($font-weights, normal);
        margin-top: map.get($spacing, s-4);
        text-decoration: underline;
    }

    &__media {
        aspect-ratio: 1/1;
        margin-top: map.get($spacing, s-24);
        position: relative;

        @include breakpoint(tablet) {
            grid-column: col-start 1 / span 7;
            grid-row: 1 / span 2;
            margin-top: 0;
            padding-right: map.get($spacing, s-24);
        }
    }

    &__color-switch {
        border-top: 2px solid var(--color-neutral-400);
        margin-top: map.get($spacing, s-16);
        padding: map.get($spacing, s-24) 0;
    }

    &__description {
        border-top: 2px solid var(--color-neutral-400);
        padding-top: map.get($spacing, s-24);
    }

    &__benefits {
        margin-bottom: map.get($spacing, s-16);
        margin-top: map.get($spacing, s-16);

        &__trigger {
            appearance: none;
            background: transparent;
            border: 1px solid transparent;
            color: var(--color-neutral-500);
            cursor: pointer;
            display: inline-block;
            text-decoration: underline;
            vertical-align: middle;

            &:hover {
                color: var(--color-neutral-500);
                cursor: pointer;
                text-decoration: none;
            }

            &::after {
                background-color: var(--product-series-header-icon-color);
                content: '';
                display: inline-block;
                height: rem(24);
                margin-left: map.get($spacing, s-8);
                margin-top: rem(-2);
                mask-image: var(--svg-chevron);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                transform: rotate(90deg);
                vertical-align: middle;
                width: rem(24);
            }

            @include focus {
                outline: 2px solid var(--color-cold-600);
                outline-offset: 6px;
            }
        }
    }

    &__benefit {
        display: flex;
        gap: map.get($spacing, s-16);
        margin-bottom: map.get($spacing, s-16);

        &:last-child {
            margin-bottom: 0;

            @include breakpoint(tablet) {
                margin-bottom: map.get($spacing, s-16);
            }
        }

        &-icon {
            color: var(--product-series-header-icon-color);
            flex: 0 0 rem(24);
        }

        &-txt {
            flex: 0 1 auto;
        }

    }

    &__price-info {
        border-bottom: 2px solid var(--color-neutral-400);
        border-top: 2px solid var(--color-neutral-400);

        &-item {
            padding: map.get($spacing, s-16) 0;

            & + & {
                border-top: 2px solid var(--color-neutral-400);
            }

            @include breakpoint(desktop) {
                display: flex;
            }
        }

        &-value {
            @include breakpoint(desktop) {
                margin-left: auto;
            }
        }

        &-tooltip {
            appearance: none;
            background: transparent;
            border: 1px solid transparent;
            display: inline-block;
            height: rem(32);
            margin-left: map.get($spacing, s-24);
            vertical-align: middle;
            width: rem(32);

            &::after {
                background-color: var(--color-neutral-500);
                content: '';
                display: inline-block;
                height: rem(20);
                margin-top: rem(-2);
                mask-image: var(--svg-info);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                width: rem(20);
            }
        }
    }

    &__cta-zone {
        --threshold: 30rem;

        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-top: map.get($spacing, s-32);

        > * {
            flex-basis: calc((var(--threshold) - 100%) * 999);
            flex-grow: 1;

            @include breakpoint(tablet) {
                flex-basis: auto;
            }
        }

        .cmp-button {
            width: 100%;
        }
    }

    .primary-button.has-button .cta-button {
        --color-bg: var(--color-cold-500);
        --color-bg-hover: var(--color-cold-300);
        --color-text: var(--color-neutral-200);
        --color-text-hover: var(--color-text);
    }

    &__dealer-count {
        align-items: center;
        display: flex;
        flex-basis: 100%;
        margin-top: map.get($spacing, s-12);

        @include font-size-per-breakpoint(body-s);

        &::before {
            background-color: var(--color-neutral-500);
            content: '';
            display: inline-block;
            height: rem(22);
            margin-right: map.get($spacing, s-12);
            mask-image: var(--svg-location-marker);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            vertical-align: middle;
            width: rem(17);
        }
    }

    &__product-finder-cta {
        flex-basis: 100%;
        margin-top: map.get($spacing, s-12);

        @include font-size-per-breakpoint(body-s);

        @include breakpoint(tablet) {
            margin-top: map.get($spacing, s-24);
        }

        &__link {
            color: var(--color-neutral-500);
            font-weight: map.get($font-weights, normal);

            &:hover {
                color: var(--color-neutral-500);
                text-decoration: none;
            }

            &::after {
                background-color: var(--product-series-header-icon-color);
                content: '';
                display: inline-block;
                height: rem(16);
                margin-left: map.get($spacing, s-4);
                margin-top: rem(-2);
                mask-image: var(--svg-chevron);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                vertical-align: middle;
                width: rem(16);
            }

            @include focus {
                outline: 2px solid var(--color-cold-600);
                outline-offset: 6px;
            }
        }
    }
}

.product-series-header {
    &--warm {
        .cmp-product-series-header {
            --product-series-header-icon-color: var(--color-warm-500);
            --color-swatch-indicator-color: var(--color-warm-500);
        }

        .product-series-gallery {
            background-color: var(--color-warm-100);
        }

        .primary-button.has-button .cta-button {
            --color-bg: var(--color-warm-500);
            --color-bg-hover: var(--color-warm-300);
            --color-text: var(--color-neutral-200);
            --color-text-hover: var(--color-text);
        }
    }

    &--cold {
        .cmp-product-series-header {
            --product-series-header-icon-color: var(--color-cold-500);
            --color-swatch-indicator-color: var(--color-cold-500);
        }

        .product-series-gallery {
            background-color: var(--color-cold-100);
        }

        .primary-button.has-button .cta-button {
            --color-bg: var(--color-cold-500);
            --color-bg-hover: var(--color-cold-300);
            --color-text: var(--color-neutral-200);
            --color-text-hover: var(--color-text);
        }
    }
}