@use 'sass:map';

.cmp-total-building-solutions-showroom {
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-72);
    }

    @include breakpoint(desktop) {
        padding-bottom: map.get($spacing, s-96);
        padding-top: map.get($spacing, s-96);
    }

    &__header {
        margin-bottom: map.get($spacing, s-48);

        @include breakpoint(desktop) {
            width: 50%;
        }

        .title {
            color: var(--color-cold-600);
			font-weight: map.get($font-weights, light);
    	    margin-bottom: map.get($spacing, s-24);

            @include font-size-per-breakpoint(h2);
        }

        .description.text  {
			font-weight: map.get($font-weights, normal);
			color: var(--color-neutral-500);
			
            @include font-size-per-breakpoint(body-m);
        }

    }

    &__grid {
        display: grid;
        
        grid-column-gap: map.get($spacing, s-24);
        
        grid-template-columns: 1fr;
        grid-auto-rows: auto;

        @include breakpoint(tablet-small) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint(desktop) {
            grid-template-columns: repeat(3, 1fr);
        }

    }

    .grid-card {
    	display: flex;
    	flex-direction: column;
    }        

    .grid-card__image {
        aspect-ratio: map.get($aspect-ratios, '4-3');
        margin-bottom: map.get($spacing, s-24);
        object-fit: cover;
        width: 100%;
    }

    .grid-card__title {
        font-weight: map.get($font-weights, normal);
        margin-bottom: map.get($spacing, s-16);
        color: var(--color-cold-600);

        @include font-size-per-breakpoint(body-xl);
    }

    .grid-card__description {
        font-weight: map.get($font-weights, normal);
        margin-bottom: map.get($spacing, s-16);
        color: var(--color-neutral-500);

        @include font-size-per-breakpoint(body-m);
    }

    .grid-card__select {
        border-top: 1px solid #eee;
        padding-top: map.get($spacing, s-16);
        padding-bottom: map.get($spacing, s-16);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .grid-card__label {
        font-weight: map.get($font-weights, normal);
        color: var(--color-cold-600);

        @include font-size-per-breakpoint(body-m);
    }

    .grid-card__input {
        background: none;
    }

    .grid-card__links {
        border-top: 1px solid #eee;
        display: flex;
        gap: map.get($spacing, s-8);
        padding-top: map.get($spacing, s-24);
        justify-content: stretch;

        @include breakpoint(desktop) {
            justify-content: flex-start;
        }

        .button {
            flex-basis: 50%;
            flex-grow: 1;

            a {
                display: block;
                width: 100%;
            }

            @include breakpoint(desktop) {
                display: inline-block;
            }
        }
    }

    .grid-card__link {
        padding: map.get($spacing, s-12) map.get($spacing, s-18);
               
        .cta-button__text {
            font-weight: map.get($font-weights, normal);

            @include font-size-per-breakpoint(body-m);
        }
    }

    .grid-card__link--dialog {
        margin-right: map.get($spacing, s-8);
    }

    /* Every card needs 5 rows, 5 * number of card rows */
    $MAX_ROWS_SUPPORTED: 5;
    $MAX_ROWS: $MAX_ROWS_SUPPORTED * 5;
    $MAX_COLS_SUPPORTED: 3;

    @include breakpoint(tablet-small) {
        @for $i from 1 through $MAX_COLS_SUPPORTED {
            .grid-cell__col-#{$i}--tablet {
                grid-column: #{$i} / span 1;
            }
        }
        @for $i from 1 through $MAX_ROWS {
            .grid-cell__row-#{$i}--tablet {
                grid-row: #{$i} / span 1;
            }    
        }
    }

    @include breakpoint(desktop) {
        @for $i from 1 through $MAX_COLS_SUPPORTED {
            .grid-cell__col-#{$i}--desktop {
                grid-column: #{$i} / span 1;
            }
        }
        
        @for $i from 1 through $MAX_ROWS {
            .grid-cell__row-#{$i}--desktop {
                grid-row: #{$i} / span 1;
            }
        }
    }

    .grid-cell--last {
        margin-bottom: map.get($spacing, s-72);
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: var(--zindex-modal);
    }

    .modal--dialog {
        grid-area: wrapper;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: stretch;
        justify-content: stretch;

        @include breakpoint(tablet) {
            display: grid;
            grid-template-areas: ". . wrapper wrapper wrapper wrapper wrapper wrapper wrapper wrapper . .";
            grid-template-columns: repeat(12, 1fr);
            padding-left: 48px;
            padding-right: 48px;
            gap: 1.5rem;
            align-items: normal;
            justify-content: normal;
        }

        @include breakpoint(desktop-xl) {
            padding-left: 500px;
            padding-right: 500px;
        }
    }

    .modal--widget {
        background-color: var(--color-neutral-200);
    }

    .modal__wrapper {
        grid-area: wrapper;
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        /* Used in mobile and tablet, when parent is flex */
        flex: 1;
        
        /* Collision with other modal__wrapper */
        background-color: transparent;
        margin: 0;

        @include breakpoint(tablet) {
            justify-content: center;            
        }
    }

    .modal__container {
            
        height: 100%;
        @include breakpoint(tablet) {
            height: auto;            
            padding: map.get($spacing, s-48);
        }
        
        padding-top: map.get($spacing, s-72);
        padding-left: map.get($spacing, s-24);
        padding-right: map.get($spacing, s-24);
        background-color: var(--color-cold-600);
        padding-bottom: map.get($spacing, s-72);
        
        position: relative;

        h4 {
            margin-bottom: 1rem;
            color: var(--color-neutral-200);
            font-weight: map.get($font-weights, light);
            margin-bottom: map.get($spacing, s-24);

            @include breakpoint(desktop) {
                margin-bottom: map.get($spacing, s-16);
                letter-spacing: -0.035em;
            }

            @include font-size-per-breakpoint(h2);
        }

        p {
            color: var(--color-neutral-200);
            margin-bottom: 2.25rem;
            font-weight: map.get($font-weights, normal);

            @include breakpoint(desktop) {
                margin-bottom: 4.125rem;
            }

            @include font-size-per-breakpoint(body-m);
        }
    }

    .modal__button--close {
        position: absolute;
        top: map.get($spacing, s-16);
        right: 24px;
        height: 40px;
        width: 40px;
        cursor: pointer;
        background-color: var(--color-neutral-700);
        border-radius: 50%;
        border: none;
        color: #2c7a9f;

        @include breakpoint(desktop) {
            top: map.get($spacing, s-48);
            right: map.get($spacing, s-48);
            height: map.get($spacing, s-48);
            width: map.get($spacing, s-48);
        }

        &::after {
            content: '✕';
            color: var(--color-cold-500);
        }
    }

    .modal__title {

    }

    .modal__description {

    }

    .modal__link {
        color: var(--color-neutral-200);
        font-weight: map.get($font-weights, semibold);
        display: block;
        text-decoration: none;

        @include font-size-per-breakpoint(body-m);

        &:before {
            content: '';
            height: 26px;
            mask-image: var(--svg-arrow);
            vertical-align: middle;
            width: 26px;
            background: currentColor;
            transition: inherit;
            display: inline-block;
            position: relative;
            mask-position: 50%;
            mask-size: contain;
            mask-repeat: no-repeat;
        }

        &:last-child {
            margin-top: 2.5rem;

            @include breakpoint(desktop) {
                margin-top: map.get($spacing, s-48);
                margin-bottom: map.get($spacing, s-48);
            }
        }
    }

    custom-select {

        &::part(wrapper) {
            display: block;
            position: relative;
            height: auto;
        }

        &::part(button) {
            --cs-button--font-family: "myriad-pro";
            --cs-button--line-height: 24px;
            --cs-button--font-size: 16px;    
            --cs-button-icon--height: 24px;
            --cs-button-icon--width: 24px;
            --cs-button-icon--right: 8px;

            background: transparent;
            border: 1px solid #DCE3E7;
            border-radius: 4px;
            cursor: pointer;
            color: #000;
            display: block;
            padding: 12px 56px 12px 16px;
            height: 3rem;
            overflow: hidden;
            text-overflow: ellipsis;    
            white-space: nowrap;
            width: 100%;
        }

        &::part(button)::after {
            display: inline-block;
            position: absolute;
            mask-image: var(--svg-chevron);
            content: "";
            background: #0097E0;
            height: 24px;
            width: 24px;
            right: map.get($spacing, s-16);
            top: map.get($spacing, s-12);
            rotate: 90deg;
        }

        &::part(options) {
            border: 1px solid #7F7F72;
            position: absolute;
            top: 2px;
            right: 0;
            min-width: 284px;
            font-size: 16px;
            font-family: "myriad-pro";
            line-height: 24px;
            border-top: 0px;
            margin-top: -2px;
            background-color: #898483;
            z-index: var(--zindex-navigation);
            padding: 8px 14px 8px 14px;
            border: 1px solid #898483;
            border-radius: 8px;
        }

        &::part(label) {
            color: #A09C9F;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            font-family: "myriad-pro";
            letter-spacing: -0.02em;
            margin-bottom: 4px;
        }

        &[open]::part(button) {
            border-bottom-color: transparent;
        }

        &:not([open])::part(options) {
            display: none;
        }

        &[open]::part(options) {
            display: block;
        }

    }

    .custom-select__option {
        cursor: pointer;
        color: #F0F0F0;
        overflow: hidden;
        margin-bottom: 4px;
        position: relative;

        @include font-size-per-breakpoint(h6);

        &::last-child {
            margin-bottom: 0px;
        }
    }

    .custom-select__option--selected {
        padding-left: 22px;

        &::before {
            position: absolute;
            mask-image: var(--svg-check);
            content: "";
            background-color: var(--color-neutral-200);
            height: 20px;
            width: 20px;
            left: -1px;
            top: 0;
        }
    }

}