/* stylelint-disable scss/dollar-variable-empty-line-before */

@use 'sass:map';
@import '../utilities/mixins/distance-mixins';

$font-families: (
    default: ('myriad-pro','Open Sans', sans-serif)
);

$font-sizes: (
    h1-mobile:       rem(32),
    h1-tablet:       rem(36),
    h1-desktop:      rem(60),
    h2-mobile:       rem(32),
    h2-tablet:       rem(36),
    h2-desktop:      rem(48),
    h3-mobile:       rem(24),
    h3-tablet:       rem(24),
    h3-desktop:      rem(36),
    h4-mobile:       rem(20),
    h4-tablet:       rem(20),
    h4-desktop:      rem(32),
    h5-mobile:       rem(16),
    h5-tablet:       rem(20),
    h5-desktop:      rem(20),
    h6-mobile:       rem(16),
    h6-tablet:       rem(16),
    h6-desktop:      rem(16),
    body-xl-mobile:  rem(20),
    body-xl-tablet:  rem(24),
    body-xl-desktop: rem(28),
    body-l-mobile:   rem(20),
    body-l-tablet:   rem(20),
    body-l-desktop:  rem(24),
    body-m-mobile:   rem(16),
    body-m-tablet:   rem(16),
    body-m-desktop:  rem(20),
    body-s-mobile:   rem(12),
    body-s-tablet:   rem(16),
    body-s-desktop:  rem(16),
    body-xs-mobile:  rem(12),
    body-xs-tablet:  rem(12),
    body-xs-desktop: rem(12),
    button-mobile:   rem(16),
    button-tablet:   rem(16),
    button-desktop:  rem(20),
);

$font-weights: (
    pluslight:200,
    light:    300,
    normal:   400,
    semibold: 600,
    bold:     700,
);

$letter-spacings: (
    default: -0.035rem,
    h5:      -0.02rem,
    h6:      -0.02rem,
);

$line-heights: (
    h1-mobile:       rem(40),
    h1-tablet:       rem(44),
    h1-desktop:      rem(72),
    h2-mobile:       rem(40),
    h2-tablet:       rem(44),
    h2-desktop:      rem(56),
    h3-mobile:       rem(32),
    h3-tablet:       rem(32),
    h3-desktop:      rem(44),
    h4-mobile:       rem(24),
    h4-tablet:       rem(24),
    h4-desktop:      rem(40),
    h5-mobile:       rem(24),
    h5-tablet:       rem(24),
    h5-desktop:      rem(24),
    h6-mobile:       rem(24),
    h6-tablet:       rem(24),
    h6-desktop:      rem(24),
    body-xl-mobile:  rem(32),
    body-xl-tablet:  rem(32),
    body-xl-desktop: rem(36),
    body-l-mobile:   rem(28),
    body-l-tablet:   rem(28),
    body-l-desktop:  rem(32),
    body-m-mobile:   rem(24),
    body-m-tablet:   rem(24),
    body-m-desktop:  rem(28),
    body-s-mobile:   rem(24),
    body-s-tablet:   rem(24),
    body-s-desktop:  rem(24),
    body-xs-mobile:  rem(18),
    body-xs-tablet:  rem(18),
    body-xs-desktop: rem(18),
    button-mobile:   rem(24),
    button-tablet:   rem(24),
    button-desktop:  rem(24),
);

:root {
    --font-family: #{map.get($font-families, default)};
    --font-letter-spacing: #{map.get($letter-spacings, default)};
}
