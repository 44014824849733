@mixin background-color($color) {
    --background-color: #{$color};

    position: relative;

    &::before {
        background: var(--background-color);
        content: '';
        height: 100%;
        left: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: 100vw;
        position: absolute;
        right: 50%;
        top: 0;
        width: 100vw;
        z-index: -1;
    }
}
