@use 'sass:map';

$bottom-image-cta-mobile-intermediate-breakpoint: rem(450);

.cmp-bottom-image-cta {
    color: var(--color-neutral-200);
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @at-root :not([class*='background--color-']) > & {
        @include background-color(var(--color-cold-600));
    }

    @include breakpoint(min-width, #{$bottom-image-cta-mobile-intermediate-breakpoint}) {
        column-gap: map.get($spacing, s-24);
        display: flex;
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-72);
    }

    @include breakpoint(desktop) {
        column-gap: map.get($spacing, s-120);
    }

    &__image {
        @include breakpoint(min-width, #{$bottom-image-cta-mobile-intermediate-breakpoint}) {
            width: 30%;
        }

        @include breakpoint(tablet-small) {
            width: 50%;
        }

        .cmp-image {
            --aspect-ratio: #{map.get($aspect-ratios, '1-1')};

            margin-bottom: 0;
        }
    }

    &__content {
        align-self: center;
        margin-top: map.get($spacing, s-32);

        @include breakpoint(min-width, #{$bottom-image-cta-mobile-intermediate-breakpoint}) {
            margin-top: 0;
            width: 70%;
        }

        @include breakpoint(tablet-small) {
            width: 50%;
        }

        .cta-button {
            --color-bg: var(--color-cold-500);
            --color-bg-hover: var(--color-cold-300);
            --color-focus-outline: var(--color-neutral-200);
            --color-text: var(--color-neutral-200);
            --color-text-hover: var(--color-text);

            display: block;

            @include breakpoint(tablet-small) {
                display: inline-block;
            }
        }
    }

    .cmp-title {
        &__text {
            @include font-size-per-breakpoint(h2);

            margin-bottom: map.get($spacing, s-16);
        }
    }

    .cmp-text {
        @include font-size-per-breakpoint(body-m);

        line-height: 1.5;
        margin-bottom: map.get($spacing, s-32);
    }

    &--style-cold {
        .cmp-bottom-image-cta__content {
            .cta-button {
                --color-bg: var(--color-cold-500);
                --color-bg-hover: var(--color-cold-300);
            }
        }
    }

    &--style-warm {
        .cmp-bottom-image-cta__content {
            .cta-button {
                --color-bg: var(--color-warm-500);
                --color-bg-hover: var(--color-warm-300);
            }
        }
    }
}
