@use 'sass:map';

.cmp-dealer-header {
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-72);
    }

    @include breakpoint(desktop) {
        padding-bottom: map.get($spacing, s-96);
        padding-top: map.get($spacing, s-72);
    }

    &__header {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: map.get($spacing, s-16);
        margin-bottom: map.get($spacing, s-16);

        @include breakpoint(tablet) {
            align-items: baseline;
            flex-direction: row;
        }

        @include breakpoint(desktop) {
            column-gap: map.get($spacing, s-24);
        }
    }

    &__to-parent-link {
        margin-bottom: map.get($spacing, s-16);

        @include breakpoint(tablet) {
            margin-bottom: map.get($spacing, s-8);
            width: 100%;
        }

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-16);
        }
    }

    &__rating {
        @include breakpoint(tablet) {
            margin-bottom: map.get($spacing, s-4);
        }
    }

    &__tags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: rem(772);

        &-tag {
            color: var(--color-neutral-500);
            display: inline-flex;
            margin-bottom: map.get($spacing, s-8);
            padding-right: rem(18);
            position: relative;

            @include font-size-per-breakpoint(body-m);

            &::after {
                background-color: var(--color-cold-500);
                content: '';
                height: rem(2);
                position: absolute;
                right: rem(8);
                top: calc(50% - #{rem(1)});
                width: rem(2);
            }

            &:last-child {
                padding-right: 0;

                &::after {
                    display: none;
                }
            }
        }
    }

    &__buttons {
        display: flex;
        gap: map.get($spacing, s-24);
        margin-top: map.get($spacing, s-24);

        @include breakpoint(desktop) {
            margin-top: map.get($spacing, s-32);
        }
    }
}
