@use 'sass:map';
@use 'sass:math';
@import 'breakpoint-mixins';

@mixin font-size-per-breakpoint($font-size-variable-basename) {
    $font-size-mobile: map.get($font-sizes, #{$font-size-variable-basename}-mobile);
    $font-size-tablet: map.get($font-sizes, #{$font-size-variable-basename}-tablet);
    $font-size-desktop: map.get($font-sizes, #{$font-size-variable-basename}-desktop);
    $line-height-mobile: map.get($line-heights, #{$font-size-variable-basename}-mobile);
    $line-height-tablet: map.get($line-heights, #{$font-size-variable-basename}-tablet);
    $line-height-desktop: map.get($line-heights, #{$font-size-variable-basename}-desktop);

    @if map.has-key($letter-spacings, #{$font-size-variable-basename}) {
        --font-letter-spacing: #{map.get($letter-spacings, #{$font-size-variable-basename})};
    }

    --font-size: #{$font-size-mobile};
    --line-height: #{$line-height-mobile};

    font-size: var(--font-size);
    letter-spacing: var(--font-letter-spacing);
    line-height: var(--line-height);

    @include breakpoint(tablet) {
        --font-size: #{$font-size-tablet};
        --line-height: #{$line-height-tablet};
    }

    @include breakpoint(desktop) {
        --font-size: #{$font-size-desktop};
        --line-height: #{$line-height-desktop};
    }
}
