@use 'sass:map';

.cmp-foldout {
    --foldout-padding: #{map.get($spacing, s-32)};

    margin-left: auto;
    margin-right: auto;
    max-width: var(--max-reading-width);
    padding-bottom: var(--foldout-padding);
    padding-top: var(--foldout-padding);

    @at-root :not([class*='background--color-']) > & {
        @include background-color(#{var(--color-neutral-300)});
    }

    @include breakpoint(tablet) {
        --foldout-padding: #{map.get($spacing, s-72)};
    }

    @include breakpoint(desktop) {
        --foldout-padding: #{map.get($spacing, s-96)};
    }

    &__description,
    &__title {
        margin-bottom: map.get($spacing, s-8);

        @include breakpoint(tablet) {
            margin-bottom: map.get($spacing, s-24);
        }

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-32);
        }
    }

    &__description {
        color: var(--color-neutral-500);

        @include font-size-per-breakpoint(body-m);
    }

    &,
    &--style-cold {
        .cmp-accordion {
            --accordion-icon-color: var(--color-cold-500);
        }
    }

    &--style-warm {
        .cmp-accordion {
            --accordion-icon-color: var(--color-warm-500);
        }
    }
}
