@use 'sass:map';

.form-input--checkbox {
    opacity: 0;

    @include visually-hidden;

    & + label {
        --checkbox-mark-color: currentcolor;
        --checkbox-mark-container-border-size: 2px;
        --checkbox-mark-container-size: 24px;
        --checkbox-mark-offset-x: 0px; /* stylelint-disable-line length-zero-no-unit */
        --checkbox-mark-offset-y: 0px; /* stylelint-disable-line length-zero-no-unit */
        --checkbox-mark-size: 16px;

        cursor: pointer;
        display: flex;
        position: relative;

        &::before {
            border: var(--checkbox-mark-container-border-size) solid var(--checkbox-mark-color);
            border-radius: 2px;
            content: '';
            height: var(--checkbox-mark-container-size);
            width: var(--checkbox-mark-container-size);
        }
    }

    &:checked + label {
        &::after {
            background-color: var(--checkbox-mark-color);
            content: '';
            display: inline-block;
            height: var(--checkbox-mark-size);
            left: calc(var(--checkbox-mark-offset-x) + var(--checkbox-mark-container-size) - var(--checkbox-mark-size) - 2 * var(--checkbox-mark-container-border-size)); /* stylelint-disable-line max-line-length */
            mask-image: var(--svg-check);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            top: calc(var(--checkbox-mark-offset-y) + var(--checkbox-mark-container-size) - var(--checkbox-mark-size) - 2 * var(--checkbox-mark-container-border-size)); /* stylelint-disable-line max-line-length */
            vertical-align: middle;
            width: var(--checkbox-mark-size);

            @media (forced-colors: active) {
                color: buttontext;
                forced-color-adjust: none;
            }
        }
    }

    &[disabled] + label {
        cursor: not-allowed;
        opacity: 0.5;
    }

    @include focus {
        & + label {
            outline: 2px solid var(--color-cold-600);
            outline-offset: 4px;
        }
    }
}
