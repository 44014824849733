@use 'sass:map';

.cmp-picture-slider {
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-72);
    }

    @include breakpoint(desktop) {
        padding-bottom: map.get($spacing, s-96);
        padding-top: map.get($spacing, s-96);
    }

    .swiper-slide {
        align-self: stretch;
        height: unset;
        width: 100%;

        img {
            aspect-ratio: map.get($aspect-ratios, '16-9');
            display: block;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    &__title {
        display: grid;
        gap: map.get($spacing, s-16);
        grid-template-columns: repeat(10, 1fr);

        .cmp-title {
            grid-column: 1 / -1;

            @include breakpoint(tablet-small) {
                grid-column: 1 / span 7;
            }

            @include breakpoint(desktop) {
                grid-column: 1 / span 5;
            }
        }
    }

    &__content {
        margin-top: map.get($spacing, s-24);

        @include breakpoint(tablet) {
            margin-top: map.get($spacing, s-32);
        }

        @include breakpoint(desktop) {
            margin-top: map.get($spacing, s-48);
        }
    }

    &__tab {
        padding-bottom: map.get($spacing, s-16);
        padding-top: map.get($spacing, s-24);

        @include breakpoint(tablet-small) {
            padding-top: map.get($spacing, s-48);
        }
    }

    &__tab-content {
        .carousel {
            &__intro {
                @include font-size-per-breakpoint(body-m);
            }

            &__wrapper {
                margin-top: map.get($spacing, s-24);

                @include breakpoint(tablet) {
                    margin-top: map.get($spacing, s-48);
                }
            }
        }
    }

    .carousel__intro {
        .cmp-text {
            margin-top: 0;
        }
    }

    &--style-warm {
        .cmp-tabs {
            --tab-border-color-active: var(--color-warm-500);
            --tab-border-color-hover: var(--color-warm-300);
            --tab-swipe-icon-color: var(--color-warm-500);
            --tab-swipe-icon-color-hover: var(--color-warm-300);
        }
    }

    .background--color-grey & {
        .cmp-tabs {
            --tab-swipe-background-color: var(--color-neutral-300);
        }
    }
}
