@use 'sass:map';

.modal {
    display: flex;
    z-index: var(--zindex-modal);

    &,
    &__overlay {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
    }

    &__overlay {
        background-color: rgba(43, 46, 56, 90%);
    }

    &[aria-hidden='true'] {
        display: none;
    }

    &__wrapper {
        background-color: white;
        margin: auto;
        position: relative;
    }

    &--default {
        margin: 0 auto;
        max-width: rem(1440);
        overflow: hidden;
        padding: 0;

        @include breakpoint(tablet) {
            column-gap: map.get($spacing, s-16);
            display: grid;
            grid-template-columns: repeat(12, [col-start] 1fr);
            padding: 0 0 0 map.get($spacing, s-24);
        }

        @include breakpoint(desktop) {
            padding-left: map.get($spacing, s-48);
        }

        .modal {
            &__wrapper {
                display: grid;
                gap: map.get($spacing, s-24) map.get($spacing, s-16);
                grid-template-columns: repeat(6, [col-start] 1fr);
                grid-template-rows: min-content auto;
                margin: initial;
                overflow-x: hidden;
                overflow-y: auto;
                padding: map.get($spacing, s-16) map.get($spacing, s-24);
                width: 100%;

                @include breakpoint(tablet) {
                    gap: map.get($spacing, s-32) map.get($spacing, s-16);
                    grid-column: col-start 2 / span 11;
                    grid-template-columns: repeat(11, [col-start] 1fr);
                    justify-self: end;
                    max-width: rem(755);
                    padding: map.get($spacing, s-40) map.get($spacing, s-24);
                }

                @include breakpoint(desktop) {
                    gap: map.get($spacing, s-48) map.get($spacing, s-24);
                    grid-column: col-start 5 / span 8;
                    grid-template-columns: repeat(8, [col-start] 1fr);
                    max-width: none;
                    padding-right: map.get($spacing, s-48);
                    padding-top: map.get($spacing, s-48);
                }
            }

            &__header {
                column-gap: map.get($spacing, s-16);
                display: grid;
                grid-column: col-start 1 / span 6;
                grid-row: 1;
                grid-template-columns: repeat(6, [col-start] 1fr);

                @include breakpoint(tablet) {
                    grid-column: col-start 2 / span 9;
                    grid-template-columns: repeat(11, [col-start] 1fr);
                }

                @include breakpoint(desktop) {
                    grid-column: col-start 2 / span 9;
                    grid-template-columns: repeat(8, [col-start] 1fr);
                }
            }

            &__title {
                grid-column: col-start 1 / span 6;
                grid-row: 2;

                @include breakpoint(tablet) {
                    grid-column: col-start 1 / span 9;
                    grid-row: 1;
                }

                @include breakpoint(desktop) {
                    grid-column: col-start 1 / span 7;
                }
            }

            &__content {
                grid-column: col-start 1 / span 6;
                grid-row: 2;
                padding-bottom: map.get($spacing, s-20);
                position: relative;
                z-index: 3;

                @include breakpoint(tablet) {
                    grid-column: col-start 2 / span 9;
                }

                @include breakpoint(desktop) {
                    grid-column: col-start 2 / span 6;
                    padding-bottom: map.get($spacing, s-48);
                }
            }

            &__close {
                --color-focus-outline: var(--color-cold-600);

                background: var(--color-neutral-300);
                border: 1px solid transparent;
                border-radius: 60%;
                color: var(--color-cold-500);
                cursor: pointer;
                grid-column: col-start 6 / span 1;
                grid-row: 1;
                height: map.get($spacing, s-48);
                justify-self: end;
                width: map.get($spacing, s-48);

                @include breakpoint(tablet) {
                    grid-column: col-start 10 / span 2;
                }

                @include breakpoint(desktop) {
                    grid-column: col-start 8 / span 1;
                }

                @include focus {
                    outline: 2px solid var(--color-focus-outline);
                    outline-offset: 6px;
                }

                &::before {
                    background: currentcolor;
                    content: '';
                    display: inline-block;
                    height: map.get($spacing, s-16);
                    mask-image: var(--svg-close);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    position: relative;
                    transition: inherit;
                    vertical-align: middle;
                    width: map.get($spacing, s-16);
                }
            }

            &__overlay {
                background-color: var(--color-neutral-200);

                @include breakpoint(tablet) {
                    background-color: rgba(43, 46, 56, 90%);
                }
            }
        }

        @media (prefers-reduced-motion: no-preference) {
            .modal {
                &__overlay {
                    animation: fadeIn 0.1s both;
                }

                &__wrapper {
                    animation: fadeIn 0.3s 0.1s both, modalSlideInUp 0.3s 0.1s both;

                    @include breakpoint(tablet) {
                        animation: slideInFromRightToLeft 0.3s 0.1s both;
                    }

                    @include breakpoint(desktop-large) {
                        animation: fadeIn 0.3s 0.1s both;
                    }
                }
            }
        }
    }

    &--navigation {
        margin: 0 auto;
        max-width: rem(1440);
        overflow: hidden;
        padding: 0;

        @include breakpoint(tablet) {
            column-gap: map.get($spacing, s-16);
            display: grid;
            grid-template-columns: repeat(12, [col-start] 1fr);
        }

        .modal {
            &__wrapper {
                margin: initial;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 0;
                width: 100%;

                @include breakpoint(tablet) {
                    grid-column: col-start 1 / span 5;
                    max-width: none;
                }

                @include breakpoint(desktop) {
                    grid-column: col-start 1 / span 4;
                }
            }

            &__header {
                align-items: center;
                background-color: var(--color-neutral-200);
                box-shadow: 0 rem(20) rem(52) rgba(0, 0, 0, 4%);
                display: flex;
                flex-direction: row;
                padding: map.get($spacing, s-72) 0 map.get($spacing, s-32) map.get($spacing, s-48);
            }

            &__title {
                font-weight: map.get($font-weights, normal);

                @include font-size-per-breakpoint(h5);
            }

            &__content {
                padding: map.get($spacing, s-32) map.get($spacing, s-48) map.get($spacing, s-24);

                @include breakpoint(tablet) {
                    padding-right: map.get($spacing, s-72);
                }

                @include breakpoint(desktop) {
                    padding-right: map.get($spacing, s-120);
                }
            }

            &__close {
                --color-focus-outline: var(--color-cold-600);

                background: transparent;
                border: none;
                border-left: 1px solid var(--color-neutral-400);
                color: var(--color-cold-500);
                cursor: pointer;
                margin-left: auto;
                padding: 0 map.get($spacing, s-16);

                @include breakpoint(desktop) {
                    padding-left: map.get($spacing, s-32);
                    padding-right: map.get($spacing, s-32);
                }

                @include focus {
                    outline: 2px solid var(--color-focus-outline);
                    outline-offset: 6px;
                }

                &::before {
                    background: currentcolor;
                    content: '';
                    display: inline-block;
                    height: rem(28);
                    mask-image: var(--svg-close);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    position: relative;
                    transition: inherit;
                    vertical-align: middle;
                    width: rem(28);
                }
            }

            &__overlay {
                background-color: var(--color-neutral-200);

                @include breakpoint(tablet) {
                    background-color: rgba(43, 46, 56, 90%);
                }
            }
        }

        @media (prefers-reduced-motion: no-preference) {
            .modal {
                &__overlay {
                    animation: fadeIn 0.1s both;
                }

                &__wrapper {
                    animation: slideInFromLeftToRight 0.3s 0.1s both;

                    @include breakpoint(desktop-large) {
                        animation: fadeIn 0.3s 0.1s both;
                    }
                }
            }
        }
    }

    &--product-series-comparison {
        --modal-header-height: #{rem(75)};

        margin: 0 auto;
        max-width: rem(1440);
        overflow: hidden;
        padding: 0;

        @include breakpoint(tablet) {
            --modal-header-height: #{rem(104)};
        }

        @include breakpoint(desktop) {
            --modal-header-height: #{rem(104)};
        }

        .modal {
            &__wrapper {
                margin: initial;
                overflow-x: hidden;
                overflow-y: auto;
                width: 100%;
            }

            &__header {
                background-color: var(--color-neutral-200);
                box-shadow: 0 rem(20) rem(52) rgba(0, 0, 0, 4%);
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                height: var(--modal-header-height);
                max-width: rem(1440);
                padding-left: map.get($spacing, s-24);
                padding-right: map.get($spacing, s-24);
                position: fixed;
                width: 100%;
                z-index: 4;

                @include breakpoint(desktop) {
                    padding-left: map.get($spacing, s-48);
                    padding-right: map.get($spacing, s-48);
                }
            }

            &__product-titles {
                align-items: center;
                display: grid;
                grid-column: 1 / span 10;
                grid-row: 1;
                grid-template-columns: repeat(10, 1fr);
            }

            &__product-title {
                font-weight: map.get($font-weights, normal);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:first-of-type {
                    grid-column: 1 / span 6;
                }

                &:last-of-type {
                    grid-column: 7 / span 4;
                    padding-left: rem(6);
                }
            }

            &__actions {
                display: flex;
                grid-column: 11 / span 2;
                grid-row: 1;
                justify-content: flex-end;
            }

            &__title {
                margin-bottom: map.get($spacing, s-24);
                margin-top: map.get($spacing, s-48);
                max-width: rem(570);

                @include breakpoint(tablet) {
                    margin-bottom: map.get($spacing, s-48);
                    margin-top: map.get($spacing, s-72);
                }

                @include breakpoint(desktop) {
                    margin-bottom: map.get($spacing, s-72);
                    margin-top: map.get($spacing, s-96);
                }
            }

            &__content {
                grid-column: col-start 1 / span 6;
                grid-row: 2;
                padding: var(--modal-header-height) map.get($spacing, s-24) 0;
                position: relative;
                z-index: 3;

                @include breakpoint(desktop) {
                    padding-left: map.get($spacing, s-48);
                    padding-right: map.get($spacing, s-48);
                }
            }

            &__close,
            &__print {
                --color-focus-outline: var(--color-cold-600);
                --icon-size: #{map.get($spacing, s-16)};

                aspect-ratio: map.get($aspect-ratios, '1-1');
                background: none;
                border: none;
                border-left: 1px solid var(--color-neutral-400);
                color: var(--color-cold-500);
                cursor: pointer;
                height: 100%;
                padding: 0;

                @include focus {
                    outline: 2px solid var(--color-focus-outline);
                    outline-offset: 6px;
                }

                &::before {
                    background: currentcolor;
                    content: '';
                    display: inline-block;
                    height: var(--icon-size);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    position: relative;
                    transition: inherit;
                    vertical-align: middle;
                    width: var(--icon-size);

                    @include breakpoint(tablet) {
                        --icon-size: #{map.get($spacing, s-24)};
                    }
                }
            }

            &__close {
                &::before {
                    mask-image: var(--svg-close);
                }
            }

            &__print {
                display: none;

                @include breakpoint(desktop) {
                    display: block;
                }

                &::before {
                    mask-image: var(--svg-print);
                }
            }

            &__overlay {
                background-color: var(--color-neutral-200);

                @include breakpoint(desktop) {
                    background-color: rgba(43, 46, 56, 90%);
                }
            }
        }
    }

    &--watch-and-learn {
        .modal {
            &__wrapper {
                background-color: var(--color-cold-600);
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                width: 100%;

                @include breakpoint('min-width', '640px') {
                    display: flex;
                }
            }

            &__overlay {
                background-color: var(--color-cold-600);
            }

            &__content {
                @include breakpoint('min-width', '640px') {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    width: 100%;
                }
            }
        }

        &.modal--warm {
            background-color: var(--color-warm-600);

            .modal {
                &__overlay,
                &__wrapper {
                    background-color: var(--color-warm-600);
                }
            }
        }

        &.modal--cold {
            background-color: var(--color-cold-600);

            .modal {
                background-color: var(--color-cold-600);

                &__overlay,
                &__wrapper {
                    background-color: var(--color-cold-600);
                }
            }
        }
    }
}

@keyframes modalSlideInUp {
    from {
        transform: translateY(#{rem(50)});
    }

    to {
        transform: translateX(0);
    }
}

// Print styles to hide everything but the modal.
@media print {
    .js-modal-open {
        body > *:not(.modal--printable) {
            display: none;
        }

        .modal {
            &--product-series-comparison {
                display: block;
                position: relative;
                width: 100%;
            }

            &__header {
                display: none;
            }

            &__title {
                margin-top: 0 !important;
            }

            &__content {
                padding-top: 0;
            }
        }
    }
}
