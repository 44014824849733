/*
* ADOBE CONFIDENTIAL
*
* Copyright 2020 Adobe Systems Incorporated
* All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe Systems Incorporated and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Adobe Systems Incorporated and its
* suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe Systems Incorporated.
*/

@use 'sass:math';

/* Generates all the rules for the grid columns up to the given amount of column */
@mixin generate-columns($breakPoint, $columnTotal, $column: 1) {
    @if $column <= $columnTotal {
        & > .aem-GridColumn.aem-GridColumn--#{$breakPoint}--#{$column} {
            clear: none;
            float: left;
            width: math.div($column * 100%, $columnTotal);
        }

        @include generate-columns($breakPoint, $columnTotal, ($column + 1));
    }
}

/* Generates all the rules for the grid column offset up to the given amount of column */
@mixin generate-offsets($breakPoint, $columnTotal, $column: 0) {
    @if $column <= $columnTotal {
        & > .aem-GridColumn.aem-GridColumn--offset--#{$breakPoint}--#{$column} {
            margin-left: math.div($column * 100%, $columnTotal);
        }

        @include generate-offsets($breakPoint, $columnTotal, ($column + 1));
    }
}

/* Generates all the rules for the grid and columns for the given break point and total of columns */
@mixin generate-grid-columns($breakPoint, $columnTotal) {
    @if $columnTotal > 0 {
        &.aem-Grid--#{$columnTotal} {
            @include generate-columns($breakPoint, $columnTotal);
            @include generate-offsets($breakPoint, $columnTotal);
        }

        &.aem-Grid--#{$breakPoint}--#{$columnTotal} {
            @include generate-columns($breakPoint, $columnTotal);
            @include generate-offsets($breakPoint, $columnTotal);
        }
    }
}

/* Generates all the rules for the grids and columns */
@mixin grid-loop($breakPoint, $columnTotal, $column: 1) {
    @if $column <= $columnTotal {
        @include generate-grid-columns($breakPoint, $column);
        @include grid-loop($breakPoint, $columnTotal, ($column + 1));
    }
}

/* API function to be called to generate a grid config */
@mixin generate-grid($breakPoint, $columnTotal) {
    @include grid-loop($breakPoint, $columnTotal);

    & > .aem-GridColumn.aem-GridColumn--#{$breakPoint}--newline {
        clear: both !important;

        /* newline behavior */
        display: block;
    }

    & > .aem-GridColumn.aem-GridColumn--#{$breakPoint}--none {
        clear: none !important; // Prevent the clear:both effect of another breakpoint new line

        /* none behavior */
        display: block;
        float: left; // Enforce float positioning to maintain column height and position relative to previous columns
    }

    & > .aem-GridColumn.aem-GridColumn--#{$breakPoint}--hide {
        /* hide behavior */
        display: none;
    }
}
