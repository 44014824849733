@use 'sass:map';

.cmp-mono-highlight-banner {
    --bg-color: var(--color-cold-600);
    --text-color: var(--color-neutral-200);
    --aspect-ratio: #{map.get($aspect-ratios, '16-9')};

    aspect-ratio: var(--aspect-ratio);
    background-color: var(--bg-color);
    color: var(--text-color);

    .cmp-image {
        --aspect-ratio: #{map.get($aspect-ratios, '16-9')};

        margin-bottom: 0;
    }

    &__content {
        background-color: var(--bg-color);
        padding: map.get($spacing, s-32) map.get($spacing, s-24) map.get($spacing, s-48);

        .cmp-title__text {
            @include font-size-per-breakpoint(h3);

            font-weight: map.get($font-weights, normal);
            margin: 0;
        }

        .cmp-text {
            @include font-size-per-breakpoint(h5);

            --line-height: 1.5;

            margin-bottom: map.get($spacing, s-8);
            margin-top: map.get($spacing, s-8);

            @include breakpoint(tablet) {
                --line-height: 1.4;
            }
        }
    }

    &__button-container {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        gap: map.get($spacing, s-16);
        justify-content: center;
        margin-top: map.get($spacing, s-24);

        .cta-button {
            display: block;
            text-align: center;
            width: 100%;

            &::before {
                background-color: var(--color-text);
            }

            @include focus {
                outline: 2px solid var(--color-neutral-200);
                outline-offset: 6px;
            }
        }
    }

    &--style-cold {
        .cmp-mono-highlight-banner__content {
            --bg-color: var(--color-cold-600);
        }

        .cta-button {
            --color-bg: var(--color-cold-500);
            --color-bg-hover: var(--color-cold-300);
            --color-text: var(--color-neutral-200);
            --color-text-hover: var(--color-text);
        }
    }

    &--style-warm {
        .cmp-mono-highlight-banner__content {
            --bg-color: var(--color-warm-600);
        }

        .cta-button {
            --color-bg: var(--color-warm-500);
            --color-bg-hover: var(--color-warm-300);
            --color-text: var(--color-neutral-200);
            --color-text-hover: var(--color-text);
        }
    }

    @include breakpoint(tablet) {
        position: relative;

        &__content {
            bottom: 0;
            min-width: rem(533);
            padding: map.get($spacing, s-48);
            position: absolute;
            right: map.get($spacing, s-24);
            width: 66.67%;
        }

        &__button-container {
            flex-direction: row;
            justify-content: flex-start;
            margin-top: map.get($spacing, s-32);
        }
    }

    @include breakpoint(desktop-large) {
        &__content {
            min-width: rem(660);
            right: map.get($spacing, s-48);
            width: 50%;
        }
    }
}
