.search-paging {
    display: none;
    justify-content: center;
    margin-bottom: 3rem;
    @include breakpoint(tablet-small) {
        margin-bottom: 4.5rem;
    }

    button {
        width: 3rem;
        height: 3rem;
        border: 1px solid var(--color-neutral-300);
        border-radius: 60px;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        background-color: var(--color-neutral-300);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        @include breakpoint(tablet) {
            width: 3.5rem;
            height: 3.5rem;
        }

        svg {
            fill: var(--color-accent);
            pointer-events: none;
        }

        &:disabled {
            cursor: unset;
            
            svg {
                fill: var(--color-accent-disabled);
            }
        }

        &.search-button {
            &.active {
                color: var(--color-neutral-200);
                background-color: var(--color-accent);
            }
        }
        
    }
}