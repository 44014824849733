


.cmp-breezometer {

    .gm-style-iw-chr {
        display: none;
    }
    
    .gm-style-iw-d {
        overflow: auto !important;
    }
    .gm-style .gm-style-iw-c {
        padding: 0;
    }
    
    padding-top: 48px;
    padding-bottom: 48px;

    &__selector {
        &-title {
            margin-bottom: 16px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
        }

        &-inputs {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            padding-bottom: 32px;
            column-gap: 8px;
            padding-left: 4px;
            padding-right: 4px;

            input {
                appearance: none;
                opacity: 0;
                position:absolute;
                width:0;
            }
    
            label {
                background-color: var(--color-neutral-300);
                border-radius: 30px;
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                color: var(--color-neutral-500);
                padding: 20px 0;
                text-align: center;
                display: block;
            }

            .selector-inputs-group:has(input:checked) {
                label {
                    outline-color: #0097E0;
                    outline-offset: 2px;
                    outline-style: solid;
                    outline-width: 2px;
                }
            }
        }
    }

    &__description {
        border-top: 1px solid #E5E5E5;
        padding-top: 32px;
        padding-bottom: 32px;
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #97979F;

        &-title {
            margin-bottom: 8px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            color: #97979F;
        }
    }
 
    &__map {
        #breezometer-2-map {
            height: 400px;
        }
    }

    &__legend {
        display: grid;
        grid-template-columns: 24px 1fr auto;
        row-gap: 24px;
        column-gap: 16px;
        padding-top: 48px;
        padding-bottom: 32px;
        border-bottom: 1px solid #E5E5E5;

        .legend {

            &__item {
                grid-column: 1 / 4;
                display: grid;
                grid-template-columns: subgrid;
                
            }

            &__color {
                background: var(--legend-color);
                border-radius: 50%;
                width: 24px;
                height: 24px;

                &--dark-green {
                    --legend-color: #009E3A;
                }
    
                &--green {
                    --legend-color: #84CF33;                
                }
    
                &--yellow {
                    --legend-color: #FFFF00;
                }
    
                &--orange {
                    --legend-color: #FF8C00;
                }
    
                &--red {
                    --legend-color: #FF0000;
                }
    
                &--dark-red {
                    --legend-color: #800000;
                }
            }

            &__label {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #3B3B40;
            }

            &__range {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #97979F;
                text-align: right;
            }
        }
    }

    &__checkbox {
        padding-top: 32px;
        display: flex;
        gap: 8px;

        input {
            width: 24px;
            height: 24px;
            border: 1px solid #e4e4e5;
            appearance: none;
            background-color: transparent;
            order: 1;
        }
        
        label {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #3B3B40;
            order: 2;
        }
    }
}