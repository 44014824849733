:root {
    --color-cold-100: #e5f4fc;
    --color-cold-200: #bfe5f7;
    --color-cold-300: #7fcbef;
    --color-cold-400: #40b1e8;
    --color-cold-500: #0097e0;
    --color-cold-600: #30303f;
    --color-warm-100: #fdf1e5;
    --color-warm-200: #fbdcbf;
    --color-warm-300: #f7b97f;
    --color-warm-400: #f49640;
    --color-warm-500: #f07300;
    --color-warm-600: #3d3333;
    --color-neutral-200: #fff;
    --color-neutral-300: #f4f4f5;
    --color-neutral-400: #e5e5e5;
    --color-neutral-500: #97979f;
    --color-neutral-600: #3b3b40;
    --color-neutral-700: #000;
    --color-validation-error-100: #f7cdce;
    --color-validation-error-600: #de383b;
    --color-validation-success-100: #dce9d0;
    --color-validation-success-600: #75a843;
    --color-background: var(--color-neutral-200);
    --color-link: var(--color-cold-500);
    --color-link-hover: var(--color-cold-400);
    --color-link-outline: var(--color-cold-600);
    --color-accent: var(--color-cold-500);
    --color-accent-disabled: var(--color-cold-200);
}
