@use 'sass:map';
@use 'sass:math';

.cmp-action-list {
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    .cmp-title__text {
        margin-bottom: map.get($spacing, s-24);

        @include breakpoint(tablet) {
            margin-bottom: map.get($spacing, s-48);
        }

        @include breakpoint(desktop) {
            margin-bottom: map.get($spacing, s-72);
        }
    }

    &__items {
        display: grid;
        gap: map.get($spacing, s-16);
        grid-template-columns: 1fr;

        @include breakpoint(tablet) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include breakpoint(desktop) {
            gap: map.get($spacing, s-24);
        }
    }
}

.cmp-action-card {
    --action-card-background-color: var(--color-cold-500);
    --action-card-color: var(--color-neutral-200);
    --action-card-link-color: var(--action-card-background-color);

    &__content {
        background-color: var(--action-card-background-color);
        color: var(--action-card-color);
        padding: map.get($spacing, s-24) map.get($spacing, s-72) map.get($spacing, s-24) map.get($spacing, s-16);

        @include breakpoint(tablet) {
            aspect-ratio: map.get($aspect-ratios, '1-1');
            height: 100%;
            padding-bottom: map.get($spacing, s-16);
            padding-right: map.get($spacing, s-16);
            padding-top: map.get($spacing, s-16);
            width: 100%;
        }

        @include breakpoint(desktop) {
            padding: map.get($spacing, s-32);
        }
    }

    &__link {
        --action-card-background-color: var(--color-neutral-400);
        --action-card-color: var(--color-warm-600);

        display: block;
        font-weight: map.get($font-weights, normal);
        height: 100%;
        position: relative;
        text-decoration: none;

        &::after {
            $arrow-size: 32;

            background-color: var(--action-card-link-color);
            bottom: calc(50% - #{rem(math.div($arrow-size, 2))});
            content: '';
            display: block;
            height: rem($arrow-size);
            mask-image: var(--svg-arrow);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            position: absolute;
            right: map.get($spacing, s-16);
            width: rem($arrow-size);

            @include breakpoint(tablet) {
                bottom: map.get($spacing, s-16);
                left: map.get($spacing, s-16);
                right: auto;
            }

            @include breakpoint(desktop) {
                bottom: map.get($spacing, s-32);
                left: map.get($spacing, s-32);
            }
        }

        .cmp-action-card__content {
            @include breakpoint(tablet) {
                padding-bottom: map.get($spacing, s-48);
            }

            @include breakpoint(desktop) {
                padding-bottom: map.get($spacing, s-72);
            }
        }
    }

    &__pre-title {
        @include font-size-per-breakpoint(h1);

        display: block;
        font-weight: map.get($font-weights, light);
    }

    &__title {
        @include font-size-per-breakpoint(body-xl);

        display: block;

        .cmp-action-card__pre-title + & {
            @include font-size-per-breakpoint(body-l);

            margin-top: map.get($spacing, s-8);
        }
    }

    @at-root .cmp-action-list--link-color-warm & {
        --action-card-background-color: var(--color-warm-500);

        &__content {
            &::after {
                background-color: var(--color-warm-500);
            }
        }
    }
}
