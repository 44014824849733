@use 'sass:map';

.navigation-submenu {
    &__menu {
        display: none;
        flex-direction: column;
        height: 100%;
        position: relative;

        &:target {
            display: flex;
        }
    }

    &__item {
        &--highlighted:not(.navigation-mobile__item):last-of-type a {
            margin-top: 2rem;
            padding-top: 2rem;
            font-weight: map.get($font-weights, semibold);
            font-size: 1.25rem;
            line-height: 1.5rem;
            border-top: 1px solid var(--color-neutral-400);
            align-items: stretch;

            &::before {
                background-color: var(--color-neutral-700);
                content: '';
                display: inline-block;
                // height: rem(24);
                margin-right: map.get($spacing, s-12);
                mask-image: var(--svg-arrow);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                vertical-align: top;
                width: rem(24);
            }
            
            span{
                padding: 1rem 0;
            }
        }
    }

    &__list {
		margin-top: map.get($spacing, s-32);
		order: 2;
	}

    &__back-link {
        order: 1;

        .navigation-submenu__link {
            @include font-size-per-breakpoint(h5);

            margin-bottom: 0;
        }
    }

    &__link {
        align-items: center;
        color: var(--color-neutral-700);
        display: flex;
        font-weight: map.get($font-weights, normal);
        margin-bottom: map.get($spacing, s-16);
        padding: map.get($spacing, s-8) 0;
        text-decoration: none;

        @include font-size-per-breakpoint(h4);

        font-size: 1.25rem;
        @include breakpoint(desktop) {
            font-size: 1.5rem;
        }
        
        &:hover {
            color: var(--color-cold-500);
        }

        &--arrow {
            &::before {
                background-color: var(--color-cold-500);
                content: '';
                display: inline-block;
                height: rem(24);
                margin-right: map.get($spacing, s-12);
                mask-image: var(--svg-arrow);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                transform: rotate(180deg);
                vertical-align: top;
                width: rem(24);
            }
        }

        &--chevron {
            &::after {
                background-color: var(--color-cold-500);
                content: '';
                display: inline-block;
                height: rem(24);
                margin-left: auto;
                mask-image: var(--svg-chevron);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                vertical-align: top;
                width: rem(24);
            }
        }

        &--active {
            font-weight: map.get($font-weights, bold);
        }
    }
}
