@use 'sass:map';

@mixin table-styling {
    table {
        --table-border-color: var(--color-neutral-500);
        --table-text-color: var(--color-neutral-500);

        border: none;
        border-collapse: collapse;
        border-spacing: 0;
        margin: map.get($spacing, s-32) 0;
        max-width: 100%;
        width: 100%;

        th,
        td {
            border: solid 1px var(--table-border-color);
            color: var(--table-text-color);
            padding: map.get($spacing, s-8);
            vertical-align: top;
        }

        thead {
            th {
                border-bottom: 2px solid var(--table-border-color);
                vertical-align: bottom;
            }

            caption + &,
            colgroup + &,
            &:first-child {
                tr:first-child {
                    th,
                    td {
                        border-top: none;
                    }
                }
            }
        }

        tbody {
            + tbody {
                border-top: solid 2px var(--table-border-color);
            }
        }
    }
}
