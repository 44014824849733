@use 'sass:map';

.social-icon {
    background-color: var(--color-neutral-200);
    border-radius: map.get($spacing, s-48);
    display: inline-block;
    height: map.get($spacing, s-48);
    position: relative;
    width: map.get($spacing, s-48);

    @include focus {
        --outline-color: var(--color-neutral-200);

        border-radius: map.get($spacing, s-48);
        outline-offset: 6px;
    }

    &::before {
        background-color: var(--color-cold-600);
        content: '';
        display: inline-block;
        height: rem(24);
        left: 50%;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;
        position: absolute;
        top: 0;
        transform: translate(-50%, 50%);
        vertical-align: top;
        width: rem(24);
    }

    &--facebook::before {
        mask-image: var(--svg-facebook);
    }

    &--twitter::before {
        mask-image: var(--svg-twitter);
    }

    &--instagram::before {
        mask-image: var(--svg-instagram);
    }

    &--linkedin::before {
        mask-image: var(--svg-linkedin);
    }

    &--pinterest::before {
        mask-image: var(--svg-pinterest);
    }

    &--youtube::before {
        mask-image: var(--svg-youtube);
    }

    &--xing::before {
        mask-image: var(--svg-xing);
    }

    &--kununu::before {
        mask-image: var(--svg-kununu);
    }

    &--whatsapp::before {
        mask-image: var(--svg-whatsapp);
    }
}
