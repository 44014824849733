@use 'sass:map';

.has-button-link {
    .cta-button {
        --button-padding: #{map.get($spacing, s-12)};
        --color-focus-outline: var(--color-cold-600);
        --color-text: var(--color-cold-600);
        --color-text-hover: var(--color-cold-500);
        --color-icon: var(--color-text);
        --color-icon-hover: var(--color-text-hover);
        --line-height: #{map.get($spacing, s-24)};

        background-color: transparent;
        border-radius: rem(30);
        border-style: none;
        color: var(--color-text);
        cursor: pointer;
        display: inline-block;
        font-weight: map.get($font-weights, semibold);
        line-height: var(--line-height);
        padding: var(--button-padding);
        text-decoration: none;

        @include font-size-per-breakpoint(button);

        &::before {
            background-color: var(--color-icon);
            content: '';
            display: inline-block;
            height: rem(24);
            mask-image: var(--svg-arrow);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            vertical-align: top;
            width: rem(24);
        }

        &:hover {
            color: var(--color-text-hover);

            &::before {
                background-color: var(--color-icon-hover);
            }
        }

        @include focus {
            border-radius: 2px;
            outline: 2px solid var(--color-focus-outline);
        }

        &:disabled,
        &[disabled] {
            --color-text: var(--color-neutral-500);
            --color-text-hover: var(--color-text);

            cursor: not-allowed;
        }
    }

    &--style {
        &-cold {
            .cta-button {
                --color-text: var(--color-cold-500);
                --color-text-hover: var(--color-cold-300);
            }
        }

        &-dia {
            .cta-button {
                --color-focus-outline: var(--color-neutral-200);
                --color-text: var(--color-cold-500);
                --color-text-hover: var(--color-text);
            }
        }

        &-warm {
            .cta-button {
                --color-text: var(--color-warm-500);
                --color-text-hover: var(--color-warm-300);
            }
        }

        &-white {
            .cta-button {
                --color-text: var(--color-neutral-200);
                --color-text-hover: var(--color-neutral-500);
            }
        }
    }
}
