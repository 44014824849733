@use 'sass:map';

.cmp-bottom-text-cta {
    padding-bottom: map.get($spacing, s-48);
    padding-top: map.get($spacing, s-48);

    @include breakpoint(tablet) {
        padding-bottom: map.get($spacing, s-72);
        padding-top: map.get($spacing, s-72);
    }

    @include breakpoint(desktop) {
        padding-bottom: map.get($spacing, s-96);
        padding-top: map.get($spacing, s-96);
    }

    @at-root :not([class*='background--color-']) > & {
        @include background-color(#{var(--color-neutral-300)});
    }

    &__title {
        @include breakpoint(tablet) {
            max-width: 50%;
        }
    }

    &__button {
        margin-top: map.get($spacing, s-16);

        @include breakpoint(tablet-small) {
            margin-top: map.get($spacing, s-24);
        }
    }

    // Default color
    .cta-button {
        --color-bg: var(--color-cold-500);
        --color-bg-hover: var(--color-cold-300);
        --color-text: var(--color-neutral-200);
        --color-text-hover: var(--color-text);
    }

    .has-button--style-warm {
        .cta-button {
            --color-bg: var(--color-warm-500);
            --color-bg-hover: var(--color-warm-300);
        }
    }
}
