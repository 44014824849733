@use 'sass:map';

body:has(.filter-rail__mobile) {
  overflow: hidden;
}
.open-filters{
  margin: 48px 0;
  display: flex;

  @include breakpoint(tablet-small) {
    display: none;
  }

  &__text {
    bottom: 3px;
    float: none;
    position: relative;
  }

  &__icon {
    margin-left: 8px;
    position: relative;
    float: none;
    bottom: -4px;
    fill: var(--color-accent);
    pointer-events: none;
  }
}
.close-menu{
  display: none;
}
.footer-search-menu{
  display: none;
}

.filter-rail {
  display: none;
  @include breakpoint(tablet-small) {
    margin-top: 48px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
  }
  @include breakpoint(desktop) {
    margin-top: 72px;
  }

  &__mobile{
    display: block;
    position: fixed;
    overflow: scroll;
    inset: 0px;
    background-color: var(--color-neutral-300);
    z-index: 999;
    padding: 1rem;
    margin-bottom: 4.5rem;
    -ms-overflow-style: none;

    .close-menu{
      display: flex;
      height: 5.5rem;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      background-color: var(--color-neutral-300);
      justify-content: end;
      padding-right: 1rem;
      padding-top: 1rem;

      button{
        border: 1px solid var(--color-neutral-400);
        background-color: var(--color-neutral-400);
        width: 3rem;
        height: 3rem;
        border-radius: 60px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          fill: var(--color-accent);
          pointer-events: none;
        }
      }
    }
    .footer-search-menu{
      display: flex;
      flex-wrap: wrap;
      height: 4.5rem;
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: var(--color-neutral-200);;
      justify-content: center;
      align-content: center;
      gap: 1rem;
    }
    
  }
  &__mobile::-webkit-scrollbar {
    display: none;
  }



  .facet-filter {
    width: 100%;
    @include breakpoint(tablet-small) {
      display: inline-block;
      width: fit-content;
      margin-right: 16px;
      margin-bottom: 16px;
      flex-basis: auto;
      order: 1;
    }

    .cta-button {
      background-color: var(--color-neutral-300);
      width: 100%;
      border-radius: 0;
      border-top: 1px solid var(--color-neutral-400);
      padding: 1.5rem 0;
      --font-size: 16px;
      display: flex;
      @include breakpoint(tablet-small) {
        background-color: var(--color-neutral-300);
        width: fit-content;
        border-radius: rem(30);
        border: 0;
        padding: 12px 24px;
      }
    }

    &__text {
      position: relative;
      float: left;
      @include breakpoint(tablet-small) {
        
        float: none;
      }
    }

    &__buttonOpen {
      svg {
        rotate: 180deg;
      }

    }

    &__arrow {
      
      margin-left: 8px;
      position: relative;
      
      fill: var(--color-accent);
      @include breakpoint(tablet-small) {
        
        
      }
    }

    &__button:hover {
      color: var(--color-neutral-600);
      background-color: var(--color-neutral-300);
      @include breakpoint(tablet-small) {
        background-color: var(--color-bg-hover);
        color: var(--color-accent);
      }
    }
  }

  .facet-filter:has(.facet-filter__buttonOpen)+.checkbox-list-wrapper {
    display: block;
  }

  .facet-filter:has(.facet-filter__buttonOpen)~.filter-rail-clear {
    //@include breakpoint(tablet-small) {
    display: block;
    //}
  }

  .checkbox-list-wrapper{
    display: none;
    order: 2;
    width: 100%;
    flex-basis: 100%;
  }

  .checkbox-list {
    
    max-width: 100%;
    
    margin-bottom: 24px;
    background-color: var(--color-neutral-300);

    @include breakpoint(tablet-small) {
      background-color: var(--color-neutral-200);
      margin: 24px 0px;
      padding-top: 24px;
      border-top: 1px solid var(--03_Neutral-400, #E5E5E5);
      border-bottom: 1px solid var(--03_Neutral-400, #E5E5E5);
      flex-basis: 100%;
    }
    @include breakpoint(tablet) {
      display: grid;
      grid-template-rows: repeat(var(--filters-column), auto);
      grid-template-columns: repeat(3, 1fr);
      grid-auto-flow: column;
      column-gap: 1rem;
    }
    @include breakpoint(desktop) {
      max-width: 60%;
    }

    .checkbox-individual {
      margin-bottom: 24px;

      &__input {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      &__label {
        color: var(--03_Neutral-600, #3B3B40);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.56px;
      }

      input:checked+label>svg {
        height: 24px;
        fill: var(--color-accent);
      }

      label:active::after {
        background-color: var(--03_Neutral-400, #E5E5E5);
      }

      label {
        cursor: pointer;
        position: relative;
        display: flex;
        &:before {
          content: "";
          height: 24px;
          width: 24px;
          margin-right: 1rem;
          float: left;
          border: 2px solid var(--03_Neutral-400, #E5E5E5);
          border-radius: 2px;
          transition: 0.15s all ease-out;

        }
        svg {
          stroke-width: 2px;
          height: 0; //Firefox fix
          width: 24px;
          position: absolute;
          //left: -40px;
          //top: -2px;
          stroke-dasharray: 33; //Firefox fix
        }
        .checkbox-individual__text{
          flex: 1;
        }
      }

      
    }

    .radio-individual {
      margin-bottom: 24px;

      &__input {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      &__label {
        color: var(--03_Neutral-600, #3B3B40);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.56px;
      }

      input:checked+label>svg {
        height: 24px;
      }

      label:active::after {
        background-color: var(--03_Neutral-400, #E5E5E5);
      }

      label {
        cursor: pointer;
        position: relative;
        display: flex;

        &::before {
          content: "";
          height: 24px;
          width: 24px;
          margin-right: 1rem;
          float: left;
          border: 2px solid var(--03_Neutral-400, #E5E5E5);
          border-radius: 50%;
          transition: 0.15s all ease-out;

        }
        svg {
          stroke-width: 2px;
          height: 0; //Firefox fix
          width: 24px;
          position: absolute;
          //left: -40px;
          //top: -2px;
          stroke-dasharray: 33; //Firefox fix
        }
        .radio-individual__text{
          flex: 1;
        }
      }
    }

  }

}
.facet-filter:first-child{
  margin-top: 5.5rem;
  @include breakpoint(tablet-small) {
    margin-top: 0;
  }
}
/*.filter-rail div:nth-last-of-type(4){
  background-color: red;
  @include breakpoint(tablet-small) {
    flex-basis: 35%;
  }
}*/