@use 'sass:map';

.cmp-standard-header {
        padding: map.get($spacing, s-48) 0 map.get($spacing, s-48) 0;
        margin: 0;

        &:not(:has(.cmp-standard-header__image)) {
            @include breakpoint(desktop) {
                padding-top: map.get($spacing, s-96); 
                padding-bottom: map.get($spacing, s-96);
            }
        }

        @media screen and (min-width: 577px) and (max-width: 834px) {
            .cmp-standard-header__button {
                display: inline;

                // &:last-child {
                //     margin-left: 1rem;
                // }
            }
        }

        @include breakpoint(tablet-small) {
            padding: map.get($spacing, s-72) 0 map.get($spacing, s-72) 0;
        }

        @include breakpoint(desktop) {
            padding: 0 0 map.get($spacing, s-96) 0;

            &:has(.cmp-standard-header__image) {
                display: grid;
                grid-template-columns: repeat(12, 1fr);

                @include container-bleed;

                .cmp-column {
                    grid-column: 6 / 13;
                }

                .cmp-column__data {
                    margin-right: 60px;
                    grid-column: 2 / 6;
                }

                .cmp-standard-header__content {
                    flex-direction: column;

                    .cmp-standard-header__button {
                        align-self: normal;
                    }
                }
            }            
        }

        .cmp-image {
            margin-bottom: 0;
        }
        
        .cmp-column {
            display: flex;
            flex-direction: column;
            justify-content: center;

            padding: 0;

            @include breakpoint(max-width, 576px) {
                @include container-bleed;
            }

            @include breakpoint(tablet-small) {
                // padding: 0 24px;
            }

            @media screen and (min-width: 835px) and (max-width: 1194px) {
                @include container-bleed;
                padding: 0 24px;
            }

            @include breakpoint(desktop) {
                padding: 0;
            }

            &__data {
                
                //margin: 0 map.get($spacing, s-24);

                

                @include breakpoint(tablet) {
                    //margin: 0 map.get($spacing, s-72);
                    // padding: 0 96px;
                }

                @include breakpoint(desktop) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }

        .cmp-to-parent-link {
            margin-bottom: map.get($spacing, s-32);

            @include breakpoint(tablet) {
                padding-top: map.get($spacing, s-24);
            }

            @include breakpoint(desktop) {
                padding-top: map.get($spacing, s-32);
            }
        }

        .cmp-title {
            margin-bottom: map.get($spacing, s-16);

            @include breakpoint(tablet) {
                padding-top: map.get($spacing, s-8);
            }

            @include breakpoint(desktop) {
                padding-top: map.get($spacing, s-16);
            }

            &__text {
                hyphens: auto;
            }
        }

        .cmp-text {
            color: var(--color-neutral-500);
            margin: 0 0 map.get($spacing, s-24);
            width: 100%;

            @include font-size-per-breakpoint(body-xl);

            @include breakpoint(tablet){
                width: 70%;
            }

            @include breakpoint(desktop) {
                margin-bottom: 0;
                font-size: map.get($font-sizes, body-xl-desktop);
                width: 100%;
            }
        }

        .cta-button {
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            margin-bottom: map.get($spacing, s-16);
            // min-width: 12em;
            width: fit-content;

            @include breakpoint(tablet-small){
                margin-bottom: map.get($spacing, s-16);
            }

            @include breakpoint(tablet){
                margin-bottom: map.get($spacing, s-48);
            }

            @include breakpoint(desktop) {
                margin-bottom: 0;
                width: 100%;
            }
        }

        .cmp-image__image {
            aspect-ratio: 16/9;
            height: 100%;
            object-fit: cover;
            width: 100%;
            display: block;
            
            @include breakpoint(desktop){
                aspect-ratio: 1/1;
            }
        }

        &__content {
            padding-bottom: map.get($spacing, s-16);

            @include breakpoint(desktop) {
                gap: map.get($spacing, s-24);
                padding-bottom: 0;
                display: flex;
                grid-template-columns: repeat(auto-fit, minmax(230px, auto));
            }
        }

        &__description {
            @include breakpoint(desktop) {
                max-width: rem(772);
                font-size: map.get($font-sizes, body-xl-desktop);
            }
        }

        &__button {
            @include breakpoint(tablet) {
                display: inline-block;
                margin-right: map.get($spacing, s-16);

                &:last-child {
                    margin-right: 0;
                }
            }

            @include breakpoint(desktop) {
                align-self: flex-end;
                display: block;
                margin-right: 0;
            }

            &--primary {

                @media screen and (min-width: 10px) and (max-width: 430px) {
                    .cta-button {
                                   max-height: 5rem !important;
                                   width: 100%;
                                //    padding-top: 0rem;
                                   &__text{
                                    padding-top: 0px;
                                   }
                                   
                    }
                }
                
                @media screen and (min-width: 431px) and (max-width: 576px) {
                   
                }
                @media screen and (min-width: 577px) and (max-width: 834px) {
                    .cta-button {
                                   max-height: 3rem;
                                   padding-top: 0rem;
                                   &__text{
                                    padding-top: 0.5em;
                                   }
                                   
                    }
                }
                @media screen and (min-width: 835px) and (max-width: 1194px) {
                    .cta-button {
                                   max-height: 3rem;
                                   padding-top: 0rem;
                                   &__text{
                                    padding-top: 0.5em;
                                }
                    }
                }
                @media screen and (min-width: 1195px) {
                    .cta-button {
                                   max-height: 3.5rem;
                                   padding-top: 0.188rem;
                                   &__text{
                                    padding-top: 0.5em;
                                   }

                    }
                }


                // Default primary button color.
                .cta-button {
                    --color-bg: var(--color-cold-500);
                    --color-bg-hover: var(--color-cold-300);
                    --color-text: var(--color-neutral-200);
                    --color-text-hover: var(--color-text);
                    &__text{
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        white-space: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: center;
                        min-width: 12em;
                        max-width: 20em;
                    }
                    // max-width: 20em;
                    max-height: 4em;
                    min-height: 2.6em;
                 }

                &.has-button--style-warm {
                    .cta-button {
                        --color-bg: var(--color-warm-500);
                        --color-bg-hover: var(--color-warm-300);
                        --color-text: var(--color-neutral-200);
                        --color-text-hover: var(--color-text);
                    }
                }
            }
            &--secondary{
                @media screen and (min-width: 10px) and (max-width: 430px) {
                    .cta-button {
                                    width: 100% !important;
                                    max-height: 5em !important;
                                
                                   
                    }
                }
                @media screen and (min-width: 431px) and (max-width: 834px) {
                    .cta-button {
                                   max-height: 3rem;
                                   padding-top: 0rem;
                                   &__text{
                                    padding-top: 0.5em;
                                   }
                                   
                    }
                }
                @media screen and (min-width: 835px) and (max-width: 1194px) {
                    .cta-button {
                                   max-height: 3rem;
                                   padding-top: 0rem;
                                   &__text{
                                    padding-top: 0.5em;
                                }
                    }
                }
                .cta-button{
                    max-height: 4em;
                    min-height: 2.6em;
                    &__text{
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        white-space: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: center;
                        min-width: 12em;
                        max-width: 20em;
                    }
                    // max-width: 20em;                    
                }
            }
        }

        &__image {
            object-fit: cover;
        }

        &__desktop-image{
            display: none;
            @include breakpoint(desktop) {
                display: block;
            }
        }

        &:not(.cmp-standard-header--has-video) &__desktop-image:has(img) ~ .cmp-standard-header__image{
            display: block;
            @include breakpoint(desktop) {
                display: none;
            }
        }


        &__media {
            height: 100%;
            width: 100%;

            --container-padding: 0;
        }

        &__video {
            display: none;

            @include breakpoint(tablet-small) {
                display: block;
            }

            @include focus {
                outline: 2px solid var(--color-cold-600);
                outline-offset: 6px;
            }

            @include breakpoint(desktop) {
                padding-bottom: 48px;
            }

            video {
                height: auto;
                width: 100%;
            }
        }

        &--has-video {
            .cmp-standard-header__image {
                @include breakpoint(tablet-small) {
                    display: none;
                }
            }
            .cmp-standard-header__desktop-image {
                @include breakpoint(tablet-small) {
                    display: none;
                }
            }
        }
}

.cmp-standard-header:not(:has(.cmp-standard-header__image)) .cmp-standard-header__content {
	line-height: 0;
	font-size: 0;
	padding-bottom: 0;
}


.cmp-standard-header:not(:has(.cmp-standard-header__image)) .cmp-standard-header__description .cmp-text {
	margin-bottom: 0;
}

/*
    Fix for the case:
    - Desktop
    - Legacy version selected
*/

@include breakpoint(desktop) {
    .standard-header:not(.standard-header--with-desktop-image) {

        .cmp-standard-header {
            display: block;
            padding-top: map.get($spacing, s-96);
            padding-bottom: map.get($spacing, s-96);
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .cmp-standard-header__content {
            flex-direction: row !important;
        }
    
        .cmp-standard-header__image {
            margin-top: 72px;
            
        }

        .cmp-standard-header__button {
            align-self: flex-end !important;
            min-height: 2.8em;
        }

        .cmp-column {
            margin-left: var(--container-margin);
            margin-right: var(--container-margin);
            max-width: var(--container-max-width);
            padding-left: 48px;
            padding-right: 48px;
            width: var(--container-width);
        }

        .cmp-column__data {
            margin-right: 0;
        }
    }
}

@media screen and (min-width: 1195px) {
	.standard-header:not(.standard-header--with-desktop-image) .cmp-image img,
    .standard-header:not(.standard-header--with-desktop-image) .cmp-image__image {
		aspect-ratio: 16 / 9;
        margin-top: 48px;
	}
}

@media screen and (min-width: 835px) and (max-width: 1194px) {
    .cmp-standard-header__button--primary:has(.cmp-button + .button__caption) {
        .cmp-button {
            margin-bottom: 0;
        }
        
        .button__caption {
            margin-bottom: 48px;
        }

        & + .cmp-standard-header__button--secondary {
            vertical-align: top;
        }
    }
}

@media screen and (min-width: 577px) and (max-width: 834px) {
    .cmp-standard-header__button--primary:has(.cmp-button + .button__caption) {
        .button__caption {
            margin-bottom: 16px;
            max-width: 400px;
        }
    }
}

@media screen and (max-width: 576px) {
    .cmp-standard-header__button--primary:has(.cmp-button + .button__caption) {
        .button__caption {
            margin-bottom: 16px;
            max-width: 300px;
        }
    }
}